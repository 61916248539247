import { Modal, Button, Form} from "react-bootstrap";
import { RequestCheckPriceSchema } from "../../../utils/validation_schemas";
import { useFormik } from "formik";
import InputMask from "react-input-mask";

export default function ConfirmCheckPriceModal(props) {
  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      telephone: "",
    },
    validationSchema: RequestCheckPriceSchema,
    onSubmit: (values) => {
      props.onConfirm(values);
      props.onHide();
    },
  });

  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Consultar Preço de Serviço</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Para consultar o preço de um serviço, é preciso algumas informações. Se você já é cadastrado, faça login na plataforma e tente novamente. Caso contrário, preencha o formulário abaixo.</p>

          <Form.Group>
            <Form.Label>Nome completo *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome completo"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Telefone *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telefone"
              name="telephone"
              as={InputMask}
              mask="(99)99999-9999"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telephone}
              isValid={formik.touched.telephone && !formik.errors.telephone}
              isInvalid={formik.touched.telephone && formik.errors.telephone}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.telephone}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Consultar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}