import LoadingIndicator from "../LoadingIndicator";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import { getAdmin, updateAdmin } from "../../services/api";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { UpdateAdminSchema } from "../../utils/validation_schemas";
import { Form, Button, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import useNotification from "../../contexts/notification";
import handleChangeCep from "../../utils/validate_cep";

export default function UpdateAdminForm({ states, adminId, onLoaded, onDone }) {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ status: "start" });
  const clearStatus = () => setStatus({ status: "start" });
  const { setMessage } = useNotification();

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      telephone: "",
      neighborhood: "",
      cpf: "",
      street: "",
      complement: "",
      number: "",
      zip_code: "",
      city: "",
      state: "",
      email: "",
    },
    validationSchema: UpdateAdminSchema,
    onSubmit: async (values) => {
      const status = await updateAdmin(values);
      setStatus(status.updated ? { status: "success" } : { status: "error" });
      onDone?.();
      setMessage({
        error: false,
        content: "Administrador atualizado com sucesso.",
      });
      if (!status.updated) {
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        });
      }
    },
  });

  useEffect(() => {
    getAdmin(adminId).then(
      (data) => {
        formik.setValues({
          ...data,
        });

        setLoading(false);
        if (onLoaded) {
          onLoaded();
        }
      },
      () =>
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminId]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Form noValidate onSubmit={formik.handleSubmit} className="pt-2">
        <h6 className="mb-3 text-primary">Dados do administrador</h6>
        <Form.Group as={Row}>
          <Col lg={4}>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
          <Col lg={8}>
            <Form.Label>Sobrenome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Sobrenome"
              name="surname"
              onChange={formik.handleChange}
              value={formik.values.surname}
              isValid={formik.touched.surname && !formik.errors.surname}
              isInvalid={formik.errors.surname}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.surname}
            </Form.Control.Feedback>
          </Col>
          <Col lg={4}>
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telefone"
              name="telephone"
              as={InputMask}
              mask="(99)99999-9999"
              onChange={formik.handleChange}
              value={formik.values.telephone}
              isValid={formik.touched.telephone && !formik.errors.telephone}
              isInvalid={formik.errors.telephone}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.telephone}
            </Form.Control.Feedback>
          </Col>
          <Col lg={8}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={formik.errors.email}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <h6 className="mt-3 mb-3 text-primary">Endereço</h6>
        <Form.Group as={Row}>
          <Col lg={4}>
            <Form.Label>CEP</Form.Label>
            <Form.Control
              as={InputMask}
              mask="99999-999"
              type="text"
              name="zip_code"
              placeholder="CEP"
              onChange={formik.handleChange}
              onBlur={() => handleChangeCep(formik)}
              value={formik.values.zip_code}
              isValid={formik.touched.zip_code && !formik.errors.zip_code}
              isInvalid={
                formik.errors.zip_code && formik.touched.zip_code
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.zip_code && formik.touched.zip_code ? true : false}
            </Form.Control.Feedback>
          </Col>
          <Col lg={4}>
            <Form.Label>Estado</Form.Label>
            <Form.Control
              type="text"
              name="state"
              disabled
              onChange={formik.handleChange}
              value={formik.values.state}
              isValid={formik.touched.state && !formik.errors.state}
              isInvalid={formik.errors.state}
            ></Form.Control>
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.state}
            </Form.Control.Feedback>
          </Col>
          <Col lg={4}>
            <Form.Label>Cidade *</Form.Label>
            <Form.Control
              type="text"
              name="city"
              disabled
              placeholder="Sua cidade"
              value={formik.values.city}
              onChange={formik.handleChange}
              isValid={formik.touched.city && !formik.errors.city}
              isInvalid={formik.errors.city}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.city}
            </Form.Control.Feedback>
          </Col>
          <Col lg={10}>
            <Form.Label>Rua</Form.Label>
            <Form.Control
              type="text"
              placeholder="Rua"
              name="street"
              onChange={formik.handleChange}
              value={formik.values.street}
              isValid={formik.touched.street && !formik.errors.street}
              isInvalid={formik.errors.street}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.street}
            </Form.Control.Feedback>
          </Col>
          <Col lg={2}>
            <Form.Label>Número</Form.Label>
            <Form.Control
              type="text"
              placeholder="Número"
              name="number"
              onChange={formik.handleChange}
              value={formik.values.number}
              isValid={formik.touched.number && !formik.errors.number}
              isInvalid={formik.errors.number}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.number}
            </Form.Control.Feedback>
          </Col>
          <Col lg={4}>
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              type="text"
              placeholder="Neighborhood"
              name="neighborhood"
              onChange={formik.handleChange}
              value={formik.values.neighborhood}
              isValid={formik.touched.neighborhood && !formik.errors.neighborhood}
              isInvalid={formik.errors.neighborhood}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.neighborhood}
            </Form.Control.Feedback>
          </Col>
          <Col lg={8}>
            <Form.Label>Complemento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Complemento"
              name="complement"
              onChange={formik.handleChange}
              value={formik.values.complement}
              isValid={formik.touched.complement && !formik.errors.complement}
              isInvalid={formik.errors.complement}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.complement}
            </Form.Control.Feedback>
          </Col>

        </Form.Group>
        {status.status === "success" && (
          <SuccessAlert msg="Perfil atualizado com sucesso." onClose={clearStatus} />
        )}
        {status.status === "error" && (
          <ErrorAlert msg="Ocorreu um erro, por favor tente novamente mais tarde." onClose={clearStatus} />
        )}
        <Form.Group as={Row}>
          <Col xs="auto">
            <Button variant="primary" type="submit">
              Atualizar
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </>
  );
}
