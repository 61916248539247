import { Link } from "react-router-dom";
import "./styles.scss";
import {
  FaStore,
  FaGratipay,
  FaRegUserCircle
} from "react-icons/fa";

export default function SidebarPanelNavigation({ isAdmin }) {
  const renderNavigation = () => {
    return (
      <nav className="text-secondary">
        <ul>
          <li className="my-2">
            <FaRegUserCircle size={20} />
            <Link className="ml-2 text-secondary" to="/conta">
              Minha Conta
            </Link>
          </li>
          {!isAdmin && (
            <>
              <li className="my-2">
                <FaStore size={20} />
                <Link className="ml-2 text-secondary" to="/compras">
                  Minhas Compras
                </Link>
              </li>
              <li className="my-2">
                <FaGratipay size={20} />
                <Link className="ml-2 text-secondary" to="/fidelidade">
                  Cartões Fidelidade
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    );
  };

  return (
    <div className="sidebar-navi d-none d-sm-nome d-md-none d-lg-block bg-success text-secondary">
      {/* <h5 className="mb-4">Meu Espaço</h5> */}
      {renderNavigation()}
    </div>
  );

}