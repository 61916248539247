import { useEffect, useState } from "react";
import { getPaymentsMethod } from "../services/api";

const usePaymentsMethod = () => {
  const [paymentsMethod, setPaymentsMethod] = useState([]);

  useEffect(() => {
    getPaymentsMethod().then((data) => {
      setPaymentsMethod(data.paymentsMethods);
    });
  }, []);

  return paymentsMethod;
};

export default usePaymentsMethod;
