import useAuth from "../../contexts/auth";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { getImage, getMyLoyaltyCardsClient, loyaltyCardClientPdf, deleteLoyaltyCardClient, verificaCartaoCliente } from "../../services/api";
import { isoDateToDefault } from "../../utils/formatters"
import LoadingIndicator from "../../components/LoadingIndicator";
import SidebarNavigation from "../../components/SidebarNavigation";
import brand from "../../assets/img/logo-vertical.svg";
import { MdOutlinePictureAsPdf } from "react-icons/md";
export default function LoyaltyCards() {
  const { user } = useAuth();
  const [loyaltyCards, setLoyaltyCards] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  // const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (!user) {
      history.push("/");
    }

    verificaCartaoCliente(user.id).then((data) => {
      console.log(data)
      getMyLoyaltyCardsClient().then((data) => {
        setLoyaltyCards(Array.from(data));
        console.log(Array.from(data));
        setIsLoading(false);
      });
    }).catch((err) => {
      console.log(err)
    });
  }, [user, history]);

  async function showPDF(id) {
    const pdf = await loyaltyCardClientPdf(id);
    const file = new Blob(
      [pdf],
      { type: 'application/pdf' }
    );
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    deleteLoyaltyCardClient(id)
  }

  // async function confirmPdf(payload) {
  //   setIsLoading(true)
  //   await loyaltyCardClientPdf(payload).then(() => {
  //     setIsConfirmed(true);
  //   });
  //   setIsLoading(false);
  //   await showPDF(payload.id);
  // }

  return (
    <>
      <Helmet title="Mercadim: Cartões Fidelidade" />
      <Container fluid className="">
        <div>
          <SidebarNavigation />
          <div className="content-panel">
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <>
                <h4 className="text-primary pt-3">Cartões Fidelidades</h4>
                {loyaltyCards.length === 0 && (
                  <p className="text-center text-muted">Nenhum cartão fidelidade encontrado.</p>
                )}
                {loyaltyCards.length > 0 &&
                  <Row className="justify-content-between">
                    {loyaltyCards.map((card) => (
                      <Col md={6} key={card.id}>
                        <Card className="my-3">
                          <Card.Header>
                            <Row noGutters className="justify-content-between">
                              <Col xs="auto">
                                <span style={{ fontSize: "0.9em", textTransform: "uppercase" }}>{card.entrepreneurs.name_establishment}</span>
                              </Col>
                              <Col xs="auto">
                                <span style={{ fontSize: "0.9em", textTransform: "uppercase" }}>{isoDateToDefault(card.date_expiration)} </span>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body>
                            <Row className="align-items-stretch" noGutters style={{ minHeight: "148px" }}>
                              <Col lg={4}>
                                <Image
                                  src={getImage(card.entrepreneurs.logo_store)}
                                  className="border"
                                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                  rounded
                                />
                              </Col>
                              <Col lg={8}>
                             
                              <Col>
                                  <h5 className="text-primary text-center mt-3 mt-md-0">
                                    {card.loyalt_card.description}
                                  </h5>
                                </Col>
                                <Col>
                                  <Row className="row-cols-5">
                                    {[...Array(card.loyalt_card.points)].map((e, i) => (
                                      <Col className="my-2 d-flex justify-content-center" key={i + 1}>
                                        {(i < card.points) && (
                                          <div style={{ border: "1px solid #29a578", borderRadius: "5px", width: "30px", height: "30px", backgroundColor: "#29a578" }}>
                                            <Image
                                              src={brand}
                                              style={{ width: "100%" }}
                                            />
                                          </div>
                                        )}
                                        {(i >= card.points) && (
                                          <div style={{ border: "1px solid #29a578", borderRadius: "5px", width: "30px", height: "30px" }}></div>
                                        )}
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <Row className="align-items-center justify-content-center">
                              <Button
                                variant="info"
                                size="sm"
                                onClick={() => {
                                  showPDF(card.id);
                                }}
                                disabled={card.loyalt_card.points !== card.points}
                              >
                                <MdOutlinePictureAsPdf size="18" /> Comprovante
                              </Button>
                            </Row>
                          </Card.Footer>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                }
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
