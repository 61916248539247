import { Modal, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { reviewProduct } from "../../services/api";

export function ModalCentered({ onSend, ...props }) {
  const [note, setNote] = useState("1");
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    reviewProduct({
      note: Number(note),
      review: review,
      product_id: Number(props.id),
    }).then((_) => {
      props.onHide();
      setLoading(false);
      setNote("1");
      setReview("");
    });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-primary">Avalie este produto</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="mb-3">
            <Form.Check
              inline
              label="Ruim"
              name="group1"
              type="radio"
              value="1"
              checked={note === "1"}
              id={`inline-radio-1`}
              onChange={(e) => setNote(e.target.value)}
            />
            <Form.Check
              inline
              label="Regular"
              name="group1"
              type="radio"
              value="2"
              checked={note === "2"}
              id={`inline-radio-2`}
              onChange={(e) => setNote(e.target.value)}
            />
            <Form.Check
              inline
              label="Bom"
              name="group1"
              type="radio"
              value="3"
              checked={note === "3"}
              id={`inline-radio-3`}
              onChange={(e) => setNote(e.target.value)}
            />
            <Form.Check
              inline
              label="Ótimo"
              name="group1"
              type="radio"
              value="4"
              checked={note === "4"}
              id={`inline-radio-4`}
              onChange={(e) => setNote(e.target.value)}
            />
            <Form.Check
              inline
              label="Excelente"
              name="group1"
              type="radio"
              value="5"
              checked={note === "5"}
              id={`inline-radio-5`}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              name="review"
              value={review}
              placeholder="Escreva sua opinião"
              onChange={(e) => setReview(e.target.value)}
              required
            />
          </Form.Group>
          <Button className="mr-auto" type="submit" disabled={loading}>
            Avaliar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function AvaliationModal() {
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setModalShow(true);
    }, 5000);
  }, []);

  return (
    <>
      <ModalCentered show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
