import { useState } from "react";
import { Toast } from "react-bootstrap";
import { NotificationContext } from "../contexts/notification";
import { useEffect } from "react";

export default function NotificationProvider({ children }) {
  
  const [message, setMessage] = useState(null);
  
  const styles = message && message.error
  ? "m-2 bg-danger text-secondary"
  : "m-2 bg-primary text-secondary";

  useEffect(() => {
    console.log('Init NotificationProvider')
  }, []);

  return (
    <NotificationContext.Provider value={{ setMessage }}>
      {children}
      {message && (
        <Toast
          className={styles}
          show={message != null}
          onClose={() => setMessage(null)}
          delay={3000}
          autohide
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            zIndex: 99999,
          }}
        >
          <Toast.Body>{message.content}</Toast.Body>
        </Toast>
      )}
    </NotificationContext.Provider>
  );
}
