import {
  Nav,
  NavDropdown,
  Container,
  Form,
  Button,
  Modal,
  Col,
  Row,
  ListGroup,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useLocationUser from "../../contexts/location";
import {
  searchGiftVouchersCityCategory,
  searchLocation,
} from "../../services/api";
import "./styles.scss";
import { GoChevronDown } from "react-icons/go";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import LoadingIndicator from "../../components/LoadingIndicator";
import Footer from "../../components/Footer";
import debounce from "lodash.debounce";
import useCategories from "../../hooks/useCategories";
import PaginationComponent from "../../components/Pagination";
import { Helmet } from "react-helmet";
import { textEllipsis } from "../../utils/mappers";
import qs from "qs";
import GiftVoucherCard from "../../components/GiftVoucherCard";

export default function Giftvoucher({ location }) {
  const categories = useCategories();
  const [query, setQuery] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [page, setPage] = useState();
  const [results, setResults] = useState(null);
  const [status, setStatus] = useState("loading");
  const [showModalLocation, setShowModalLocation] = useState(false);
  const { location: locationUser } = useLocationUser();
  const [lastLocation, setLastLocation] = useState(null); // [city, uf
  const { idCategory } = useParams();
  const [lastCategory, setLastCategory] = useState(null);
  const [ascOrDesc, setAscOrDesc] = useState("ASC");

  const q = qs.parse(location.search, { ignoreQueryPrefix: true }).q;

  useEffect(() => {
    const p = sessionStorage.getItem("@app_mercadim:p");
    if (p) {
      setPage(Number(p));
    }
  }, []);

  useEffect(() => {
    if (lastLocation !== locationUser) {
      setLastLocation(locationUser);
      setPage(1);
    }
  }, [lastLocation, locationUser]);

  useEffect(() => {
    if (lastCategory !== idCategory) {
      setLastCategory(idCategory);
      setPage(1);
    }
  }, [lastCategory, idCategory]);

  useEffect(() => {
    if (lastCategory) {
      setPage(1);
      const payload = {
        category_id: lastCategory,
        ...(!showAll && lastLocation && { city: lastLocation.name }),
      };

      searchGiftVouchersCityCategory(payload, page, ascOrDesc).then((data) => {
        setResults(data);
        setStatus("result");
      });
    } else {
      if (q && query.length === 0) {
        const payload = {
          nameVoucher: q,
          ...(!showAll && lastLocation && { city: lastLocation.name }),
        };
        searchGiftVouchersCityCategory(payload, page, ascOrDesc).then(
          (data) => {
            setResults(data);
            setStatus("result");
          }
        );
      } else {
        const dados = {
          nameVoucher: query,
          ...(!showAll && lastLocation && { city: lastLocation.name }),
        };

        searchGiftVouchersCityCategory(dados, page, ascOrDesc).then((data) => {
          setResults(data);
          setStatus("result");
        });
      }
    }
  }, [lastCategory, lastLocation, page, query, ascOrDesc, showAll, q]);

  function renderResults() {
    if (status !== "result") {
      return null;
    }

    if (results.data.length === 0) {
      return (
        <>
          <Container fluid>
            <Row>
              <Col className="my-5">
                <h5 className="text-muted text-center my-5">Sem resultados</h5>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

    return (
      <>
        <Container fluid>
          <Row className="align-items-stretch">
            {results.data.map((giftvoucher) => (
              <Col key={giftvoucher.id} xs={12} sm={6} lg={4} xl={2}>
                <GiftVoucherCard giftvoucher={giftvoucher} />
              </Col>
            ))}
          </Row>
        </Container>
        <div className="my-4">
          <PaginationComponent
            pagination={results}
            onClickPage={(page) => {
              setPage(page);
              if (query.length > 0) {
                const dados = {
                  nameVoucher: query,
                  ...(!showAll && lastLocation && { city: lastLocation.name }),
                };
                searchGiftVouchersCityCategory(dados, page, ascOrDesc).then(
                  (data) => {
                    setResults(data);
                  }
                );
              }
            }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet title="Mercadim: Giftvoucher" />
      <Nav className="justify-content-between bg-secondary py-1" as="ul">
        <div id="nav-dropdown-categorias">
          <NavDropdown title="Categorias" id="nav-dropdown">
            <NavDropdown.Item
              as={Link}
              to="/giftvoucher"
              active={lastCategory === undefined}
            >
              Todas as categorias
            </NavDropdown.Item>
            {categories.map((category) => {
              return (
                <NavDropdown.Item
                  key={category.name}
                  as={Link}
                  to={`/gifvoucher/categoria/${category.id}`}
                  active={category.id === Number(lastCategory)}
                >
                  {textEllipsis(category.name, 4)}
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>
        </div>
        <Nav.Item>
          <Button variant="link" onClick={() => setShowModalLocation(true)}>
            {lastLocation && `${lastLocation.name}, ${lastLocation.uf}`}
            {!lastLocation && `Selecione o local`}
            <GoChevronDown className="pl-1" />
          </Button>
        </Nav.Item>
      </Nav>
      <div className="marketplace-main-wrapper">

        <Container fluid>
          <Form>
            <h5 className="text-primary my-3">
              Busque seus vales presentes favoritos
            </h5>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="q"
                  placeholder="O que está procurando?"
                  // onKeyUp={handleQuery}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Col>
              <Col md="auto">
                <Form.Check
                  custom
                  className="mt-2"
                  label="Exiba vale presentes de todas as cidades"
                  id="check"
                  checked={showAll}
                  onChange={(e) => {
                    setPage(1);
                    setShowAll(e.target.checked);
                  }}
                />
              </Col>
              <Col md="auto">
                <Button
                  variant="link"
                  size="md"
                  title="Ordernar por preço"
                  onClick={() => {
                    if (ascOrDesc === "ASC") {
                      setAscOrDesc("DESC");
                    } else {
                      setAscOrDesc("ASC");
                    }

                    if (query.length > 0) {
                      const dados = {
                        nameVoucher: query,
                        ...(!showAll &&
                          lastLocation && { city: lastLocation.name }),
                      };
                      searchGiftVouchersCityCategory(
                        dados,
                        page,
                        ascOrDesc
                      ).then((data) => {
                        setResults(data);
                      });
                    }
                  }}
                >
                  {ascOrDesc === "ASC" ? <BiSortUp /> : <BiSortDown />}
                </Button>
              </Col>
            </Row>
          </Form>

          <hr />
          {renderResults()}
          {status === "loading" && <LoadingIndicator />}
        </Container>
      </div>

      <LocationModal
        show={showModalLocation}
        onHide={() => setShowModalLocation(false)}
        onSubmit={() => setShowModalLocation(false)}
      />

      <Footer />
    </>
  );
}

const fetchData = async (query, cb) => {
  const res = await searchLocation(query);
  cb(res);
};

const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 500);

function LocationModal({ show, onHide, onSubmit }) {
  const [data, setData] = useState([]);
  const { setLocation } = useLocationUser();
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (selectedLocation) {
      debouncedFetchData(selectedLocation, (res) => {
        setData(res.data);
      });
    }
  }, [selectedLocation]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Selecionar local</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control
            autoComplete="off"
            name="location"
            minLength="3"
            placeholder="Onde você está?"
            onChange={(e) => setSelectedLocation(e.target.value)}
          />
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              className="my-2"
              variant="outline-primary"
              onClick={() => {
                setLocation(null);
                onSubmit();
              }}
            >
              Limpar localidade
            </Button>
          </div>
        </Form.Group>
        <ListGroup className="pb-2" variant="flush">
          {data.map((city) => (
            <ListGroup.Item
              key={city.id}
              action
              onClick={() => {
                setLocation(city);
                onSubmit();
              }}
            >
              {city.name}, {city.uf}
            </ListGroup.Item>
          ))}
        </ListGroup>

        <Button onClick={onSubmit}>Fechar</Button>
      </Modal.Body>
    </Modal>
  );
}
