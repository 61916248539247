import { useFormik } from "formik";
import { useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  ProgressBar,
} from "react-bootstrap";
import { cpfCnpj } from "../../utils/formatters";
import {
  SignupEntrepreneurStep01,
  SignupEntrepreneurStep02,
  SignupEntrepreneurStep03,
  SignupEntrepreneurStep04,
} from "../../utils/validation_schemas";
import InputMask from "react-input-mask";
import usePaymentsMethod from "../../hooks/usePaymentsMethod";
import { createSignupEntrepreneurFormData } from "../../utils/payloads";
import {
  availableCpf,
  availableCpfCnpj,
  availablePhone,
  availableEmailEntrepreneur,
} from "../../services/api";
import useNotification from "../../contexts/notification";
import Adresss from "../Adresss";

export default function SignupEntrepreneurWizard({ onSubmit }) {
  const { setMessage } = useNotification();
  const [currentTab, setCurrentTab] = useState(0);
  const paymentsMethod = usePaymentsMethod();

  const [entrepreneur, setEntrepreneur] = useState({
    type: "entrepreneur",
    name_establishment: "",
    cpf_cnpj: "",
    telephone: "",
    email: "",
    logo_store: undefined,
    delivery_method: "1",
    payment_method: [],
    zip_code: "",
    neighborhood: "",
    street: "",
    complement: "",
    number: "",
    city: "",
    state: "",
    acceptTerms: false,
  });

  const formik = useFormik({
    initialValues: {
      zip_code: entrepreneur.zip_code,
      neighborhood: entrepreneur.neighborhood,
      street: entrepreneur.street,
      complement: entrepreneur.complement,
      number: entrepreneur.number,
    },
    validationSchema: SignupEntrepreneurStep04,
    onSubmit: (values) => {
      setEntrepreneur((ent) => ({ ...ent, ...values }));
      const data = {
        ...entrepreneur,
        ...values,
        state: values.state,
        city: values.city,
      };
      onSubmit(createSignupEntrepreneurFormData(data));
    },
  });

  function next() {
    if (currentTab < 3) {
      setCurrentTab((tab) => tab + 1);
    }
  }

  function prev() {
    if (currentTab > 0) {
      setCurrentTab((tab) => tab - 1);
    }
  }

  const Step01 = () => {
    const formik = useFormik({
      initialValues: {
        name_establishment: entrepreneur.name_establishment,
        cpf_cnpj: entrepreneur.cpf_cnpj,
        telephone: entrepreneur.telephone,
        acceptTerms: entrepreneur.acceptTerms,
      },
      validationSchema: SignupEntrepreneurStep01,
      onSubmit: async (values) => {
        setEntrepreneur((ent) => ({ ...ent, ...values }));
        const cpf_cnpj = values.cpf_cnpj.replace(/\D/g, "");
        if (cpf_cnpj.length < 14) {
          await Promise.all([
            availableCpf(cpf_cnpj),
            availableCpfCnpj(cpf_cnpj),
          ]).then(
            async (res) => {
              if (res[0].status && res[1].status) {
                await availablePhone(values.telephone).then(
                  (res) => {
                    if (res.status) {
                      next();
                    }
                  },
                  (err) => {
                    setMessage({
                      error: true,
                      content: "Esse Telefone não está disponível",
                    });
                  }
                );
              }
            },
            (err) => {
              if (
                !err.response.data.status &&
                err.response.data.status !== undefined
              ) {
                setMessage({
                  error: true,
                  content: "O CPF/CNPJ informado já está em uso.",
                });
              } else {
                setMessage({
                  error: true,
                  content: "O CPF/CNPJ informado não é valido.",
                });
              }
            }
          );
        } else {
          await availableCpfCnpj(cpf_cnpj).then(
            async (res) => {
              if (res.status) {
                await availablePhone(values.telephone).then(
                  (res) => {
                    if (res.status) {
                      next();
                    }
                  },
                  (err) => {
                    setMessage({
                      error: true,
                      content: "Esse Telefone não está disponível",
                    });
                  }
                );
              }
            },
            (err) => {
              setMessage({
                error: true,
                content: "O CPF/CNPJ informado já está em uso.",
              });
            }
          );
        }
      },
    });

    return (
      <Form noValidate onSubmit={formik.handleSubmit} className="my-5">
        <h6 className="mb-3 text-primary">
          <span className="text-secondary bg-primary p-1 rounded mr-1">1</span>
          Dados do negócio
        </h6>
        <Form.Group controlId="formEntData">
          <Row>
            <Col sm={12} md={12} xl={12}>
              <Form.Label>Nome do estabelecimento *</Form.Label>
              <Form.Control
                className="my-1"
                type="text"
                name="name_establishment"
                placeholder="Seu estabelecimento"
                onChange={formik.handleChange}
                value={formik.values.name_establishment}
                isValid={
                  formik.touched.name_establishment &&
                  !formik.errors.name_establishment
                }
                isInvalid={
                  formik.errors.name_establishment &&
                  formik.touched.name_establishment
                    ? true
                    : false
                }
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.name_establishment}
              </Form.Control.Feedback>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <Form.Label>CPF/CNPJ *</Form.Label>
              <Form.Control
                className="my-1"
                type="text"
                name="cpf_cnpj"
                placeholder="CPF ou CPNJ sem pontuação"
                onChange={(e) =>
                  formik.setFieldValue("cpf_cnpj", cpfCnpj(e.target.value))
                }
                value={formik.values.cpf_cnpj}
                isValid={formik.touched.cpf_cnpj && !formik.errors.cpf_cnpj}
                isInvalid={formik.errors.cpf_cnpj && formik.touched.cpf_cnpj}
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.cpf_cnpj}
              </Form.Control.Feedback>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <Form.Label>Telefone *</Form.Label>
              <Form.Control
                className="my-1"
                type="text"
                name="telephone"
                placeholder="Telefone"
                as={InputMask}
                mask="(99)99999-9999"
                onChange={formik.handleChange}
                value={formik.values.telephone}
                isValid={formik.touched.telephone && !formik.errors.telephone}
                isInvalid={
                  formik.errors.telephone && formik.touched.telephone
                    ? true
                    : false
                }
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.telephone}
              </Form.Control.Feedback>
            </Col>
            <Col xs="auto" className="mt-2">
              <Form.Check
                custom
                id="custom-checkbox-terms-entr"
                name="acceptTerms"
                type="checkbox"
                label="Aceito os Termos e Condições*"
                checked={formik.values.acceptTerms}
                onChange={formik.handleChange}
                isValid={
                  formik.touched.acceptTerms && !formik.errors.acceptTerms
                }
                isInvalid={
                  formik.errors.acceptTerms && formik.touched.acceptTerms
                    ? true
                    : false
                }
              />
            </Col>
          </Row>
        </Form.Group>
        <Button variant="primary" type="submit">
          Próximo
        </Button>
        <ProgressBar now={25} label={`Etapa 1 de 4`} className="mt-3" />
      </Form>
    );
  };

  const Step02 = () => {
    const formik = useFormik({
      initialValues: {
        delivery_method: entrepreneur.delivery_method,
        email: entrepreneur.email,
        logo_store: entrepreneur.logo_store,
      },
      validationSchema: SignupEntrepreneurStep02,
      onSubmit: async (values) => {
        setEntrepreneur((ent) => ({ ...ent, ...values }));
        await availableEmailEntrepreneur(values.email).then(
          (res) => {
            if (res.status) {
              next();
            }
          },
          (err) => {
            setMessage({
              error: true,
              content: "Já existe um usuário usando esse e-mail.",
            });
          }
        );
      },
    });

    return (
      <Form onSubmit={formik.handleSubmit} className="my-5">
        <h6 className="mb-3 text-primary">
          <span className="text-secondary bg-primary p-1 rounded mr-1">2</span>
          Mais informações
        </h6>
        <Form.Group controlId="formEntData">
          <Row>
            <Col sm={8} md={8} xl={8}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="my-1"
                type="email"
                name="email"
                placeholder="Seu email"
                onChange={formik.handleChange}
                value={formik.values.email}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={
                  formik.errors.email && formik.touched.email ? true : false
                }
              />
              <Form.Control.Feedback tooltip type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Col>
            <Col sm={4} md={4} xl={4} className="mt-1">
              <Form.Label>Logo da loja*</Form.Label>
              <Form.File
                id="exampleFormControlFile1"
                accept="image/png, image/jpeg"
                name="logo_store"
                lang="pt"
                label={
                  formik.values.logo_store
                    ? formik.values.logo_store.name
                    : "Escolher arquivo"
                }
                onChange={(e) =>
                  formik.setFieldValue("logo_store", e.currentTarget.files[0])
                }
                isInvalid={
                  formik.errors.logo_store && formik.touched.logo_store
                    ? true
                    : false
                }
                feedback={formik.errors.logo_store}
                feedbackTooltip
                custom
              />
            </Col>

            <Col className="my-1">
              <Form.Label>Métodos de Entrega</Form.Label>
              <Row>
                <Col xs="auto">
                  <Form.Check
                    custom
                    id="custom-checkbox-presencial"
                    name="delivery_method"
                    type="radio"
                    checked={formik.values.delivery_method === "1"}
                    value="1"
                    label="Presencial"
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs="auto">
                  <Form.Check
                    custom
                    id="custom-checkbox-delivery"
                    name="delivery_method"
                    type="radio"
                    value="2"
                    checked={formik.values.delivery_method === "2"}
                    label="Delivery"
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs="auto">
                  <Form.Check
                    custom
                    id="custom-checkbox-delivery-presencial"
                    name="delivery_method"
                    type="radio"
                    checked={formik.values.delivery_method === "3"}
                    label="Presencial e Delivery"
                    value="3"
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form.Group>
        <Button
          variant="outline-primary"
          onClick={() => {
            setEntrepreneur((ent) => ({
              ...ent,
              ...formik.values,
            }));
            prev();
          }}
        >
          Anterior
        </Button>
        <Button className="mx-2" variant="primary" type="submit">
          Próximo
        </Button>
        <ProgressBar now={50} label={`Etapa 2 de 4`} className="mt-3" />
      </Form>
    );
  };

  const Step03 = () => {
    const formik = useFormik({
      initialValues: {
        payment_method: entrepreneur.payment_method,
      },
      validationSchema: SignupEntrepreneurStep03,
      onSubmit: (values) => {
        setEntrepreneur((ent) => ({ ...ent, ...values }));

        next();
      },
    });

    return (
      <Form noValidate onSubmit={formik.handleSubmit} className="my-5">
        <h6 className="mb-3 text-primary">
          <span className="text-secondary bg-primary p-1 rounded mr-1">3</span>
          Formas de pagamento
        </h6>
        <Form.Group controlId="formEntData">
          <Row>
            {paymentsMethod.map((f) => {
              return (
                <Col xl={12} className="mt-1" key={f.id}>
                  <Form.Check
                    custom
                    id={`custom-checkbox-${f.id}`}
                    name="payment_method"
                    type="checkbox"
                    isInvalid={
                      formik.errors.payment_method &&
                      formik.touched.payment_method
                        ? true
                        : false
                    }
                    value={f.id}
                    label={f.name}
                    required={formik.values.payment_method.length === 0}
                    checked={formik.values.payment_method.includes(
                      String(f.id)
                    )}
                    onChange={formik.handleChange}
                  />
                </Col>
              );
            })}
            <h6 className="text-danger p-1">{formik.errors.payment_method}</h6>
          </Row>
        </Form.Group>
        <Button
          variant="outline-primary"
          onClick={() => {
            setEntrepreneur((ent) => ({
              ...ent,
              ...formik.values,
            }));
            prev();
          }}
        >
          Anterior
        </Button>
        <Button className="mx-2" variant="primary" type="submit">
          Próximo
        </Button>
        <ProgressBar now={75} label={`Etapa 3 de 4`} className="mt-3" />
      </Form>
    );
  };

  return (
    <Tabs defaultActiveKey={0} activeKey={currentTab} className="border-0">
      <Tab eventKey={0}>
        <Step01 />
      </Tab>
      <Tab eventKey={1}>
        <Step02 />
      </Tab>
      <Tab eventKey={2}>
        <Step03 />
      </Tab>
      <Tab eventKey={3}>
        <Form noValidate onSubmit={formik.handleSubmit} className="my-5">
          <Adresss formik={formik} tab={4} />
          <Button
            variant="outline-primary"
            onClick={() => {
              setEntrepreneur((ent) => ({
                ...ent,
                ...formik.values,
                state: formik.values.state,
                city: formik.values.city,
              }));
              prev();
            }}
          >
            Anterior
          </Button>
          <Button className="mx-2" variant="primary" type="submit">
            Criar conta
          </Button>
          <ProgressBar now={100} label={`Etapa 4 de 4`} className="mt-3" />
        </Form>
      </Tab>
    </Tabs>
  );
}
