import React from "react";
import { Row, Col, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

import "./styles.scss";

export default function ConfirmTerms() {
  const [confirm, setConfirm] = useLocalStorage("@app_mercadim:confirm", null);

  return (
    <Alert
      show={!confirm?.value}
      className="m-0 d-flex bg-secondary flex-sm-column px-4 py-3 justify-content-between alert-dialog"
    >
      <Row>
        <Col xl={10} lg={9} md={9}>
          <h3 className="text-left text-primary fw-bold">
            Você pode ler nossos termos e condições {""}
            <Link className="link" to="/termos" target="_blank">
              aqui
            </Link>
          </h3>
          <p className="m-0 text-left text-primary fw-bold">
            Caso concorde aperte o botão ao lado e continue a utilizar o
            serviço.
          </p>
        </Col>
        <Col
          sm={9}
          xl={2}
          lg={2}
          md={2}
          className="d-flex justify-content-between flex-column 
              flex-md-column flex-lg-column flex-xl-column align-items-start 
              align-items-sm-start align-items-md-end   
              align-items-lg-end align-items-xl-center"
        >
          <div
            className="d-flex align-items-center flex-md-column 
                flex-row-reverse"
          >
            <Button
              onClick={() => {
                setConfirm({ value: true });
              }}
              variant="primary"
              className="m-2"
            >
              Concordo
            </Button>
            <Button as="a" href="about:blank" variant="link" className="p-0">
              Não concordo
            </Button>
          </div>
        </Col>
      </Row>
    </Alert>
  );
}
