import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row, Image, Button, Media } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getImage, getProduct, getProductReviews,createOrderServico, getUser } from "../../../services/api";
import { currency, discountIsValid, valueToRate } from "../../../utils/mappers";
import "./styles.scss";
import useCart from "../../../contexts/cart";
import { FiTruck } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import LoadingIndicator from "../../../components/LoadingIndicator";
import NotFound from "../../NotFound";
import Footer from "../../../components/Footer";
import { ModalCentered } from "../../../components/AvaliationModal";
import useAuth from "../../../contexts/auth";
import { RatingStars } from "../../../components/RatingStars";

import { useHistory } from "react-router-dom";
import useNotification from "../../../contexts/notification";
import ConfirmCheckPriceModal from "./ConfirmCheckPriceModal";

export default function Product() {
  const { id } = useParams();
  const { user } = useAuth();
  const [productData, setProductData] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addToCart, productExists } = useCart();

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const { setMessage } = useNotification();

  const history = useHistory();

  const [showReviewModal, setShowReviewModal] = useState(false);

  function refresh() {
    getProductReviews(id).then(
      (data) => {
        setReviews(data);
        setLoading(false);
      },
      (err) => {
        setReviews([]);
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    getProduct(id).then(
      (data) => {
        setProductData(data);
        getProductReviews(id).then(
          (data) => {
            setReviews(data);
            setLoading(false);
          },
          (err) => {
            setReviews([]);
            setLoading(false);
          }
        );
      },
      (err) => {
        setLoading(false);
      }
    );
  }, [id]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (productData === null) {
    return <NotFound isComponent />;
  }

  const showDiscount = discountIsValid(
    productData.product.discount,
    productData.product.date_expiration
  );

  const userLoggedCheckPrice = async () => {
    setLoading(true);
    const [data] = await getUser(user.id);
    const userInfos = {
      id: user.id,
      name: `${data.name} ${data.surname}`,
      telephone: data.telephone,
    }
    await CheckPrice(userInfos);
    setLoading(false);
  }

  const userNotLoggedCheckPrice = () => {
    setConfirmModalShow(true);
  }

  const CheckPrice = async (userInfos) => {
    setLoading(true);
    const payload = {
      pedido: {
        user: {
          id: userInfos.id || null,
          name: userInfos.name,
          telephone: userInfos.telephone,
        },
        lojas: {
          idLoja: productData.entrepreneur[0].id,
          whatsappLoja: "55" + productData.entrepreneur[0].telephone,
          produtosPedido: [{
            idProduto: productData.product.id,
            quant: 1,
            type: "product",
          }],
        },
      },
    };
    
    await createOrderServico(payload)
      .then((link) => {
        setMessage({
          error: false,
          content: "Pedido realizado com sucesso.",
        });
        window.open(link[0], "_blank");
      })
      .catch((err) => {
        setMessage({
          error: true,
          content: err.response.data.msg,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <ConfirmCheckPriceModal
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        onConfirm={CheckPrice}
      />
      <Container className="py-5">
        <Row>
          <Col className="container_image" lg={6}>
            <Image
              src={getImage(productData.product.image)}
              rounded
              fluid
              className="image_product"
            />
          </Col>
          <Col>
            <h2>{`${productData.product.name} - ${productData.entrepreneur[0].name_establishment}`}</h2>
            <div>
              <RatingStars size="lg" value={Number(reviews.media)} />
              <h6 className="text-primary">
                {reviews.Avaliacoes.length} avaliações
              </h6>
            </div>
            {!productData.product.confidential_price && (
              <>
                <h1 className="text-primary price">
                  {currency(
                    showDiscount ? productData.product.priceDiscount : productData.product.price
                  )}
                </h1>
                {showDiscount && (
                  <h3 className="old-price">
                    {currency(productData.product.price)}
                  </h3>
                )}
                {productData.product.wholesale_price != null && (
                  <div>
                    Atacado:
                    <span className="text-primary">
                      {` ${currency(productData.product.wholesale_price)} a partir de ${productData.product.min_quantity_wholesale} unidade(s).`}
                    </span>
                  </div>
                )}
              </>
            )}
            <div className="my-3">
              <FiTruck size="24" />
              <h5 className="text-primary delivery ml-2">
                {productData.entrepreneur[0].delivery_method[0].name}
              </h5>
            </div>
            <p className="my-3" style={{ whiteSpace: "pre-line" }}>
              {productData.product.description}
            </p>
            <p className="text-primary ml-2">
              Quantidade:{productData.product.amount}
            </p>
            <p>
              <span>Vendido por: </span>
              <Link to={`/loja/${productData.entrepreneur[0].slug}`}>
                {productData.entrepreneur[0].name_establishment}
              </Link>
            </p>
            <Row>
              {!productData.product.confidential_price && (
                <Col xs="auto">
                  <Button
                    size="lg"
                    className="px-3 mt-1"
                    onClick={() => {
                      addToCart(productData);
                      const entr = productData["entrepreneur"][0];
                      if (user) {
                        history.push(
                          `/checkout?ent=${entr.id}`
                        );
                      } else {
                        history.push(
                          `/entrar?next=/checkout&ent=${entr.id}`
                        );
                      }
                    }}
                  >
                    Comprar agora
                  </Button>
                </Col>
              )}
              {!!productData.product.confidential_price && (
                <Col xs="auto">
                  <Button
                    size="lg"
                    className="px-3 mt-1"
                    onClick={ async() =>  {
                      if (!user) {
                        userNotLoggedCheckPrice();
                        return;
                      }
                      await userLoggedCheckPrice();
                    }}
                  >
                    Consultar preço
                  </Button>
                </Col>
              )}
              {!productData.product.confidential_price && (
                <Col xs="auto">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="px-3 mt-1"
                    onClick={() => addToCart(productData)}
                    disabled={productExists(
                      productData.entrepreneur[0].id,
                      productData
                    )}
                  >
                    {productExists(productData.entrepreneur[0].id, productData)
                      ? `Adicionado`
                      : `Adicionar`}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {user && user.type !== "admin" && (
          <Button
            size="sm"
            variant="outline-primary"
            className="mt-5"
            onClick={() => setShowReviewModal(true)}
          >
            Avaliar produto
            <MdEdit />
          </Button>
        )}
        <hr />
        {reviews.Avaliacoes.map((rate, index) => {
          return <UserRate key={index} rate={rate} />;
        })}
        {reviews.Avaliacoes.length === 0 && (
          <p className="text-muted text-center">
            Não há avaliações para este produto
          </p>
        )}
      </Container>
      <ModalCentered
        id={id}
        show={showReviewModal}
        onHide={() => {
          refresh();
          setShowReviewModal(false);
        }}
      />
      <div className="footer">
        <Footer />
      </div>
    </>
  );
}

export function UserRate({ rate }) {
  const renderRating = () => {
    return (
      <div className="d-flex align-items-center">
        <RatingStars size="sm" value={rate.note} />
        <span className="ml-2">{valueToRate(rate.userRate)}</span>
      </div>
    );
  };

  return (
    <Media className="my-2">
      <Media.Body>
        {renderRating()}
        <p className="my-0">
          {rate.user_id !== null
            ? rate.user_id.name
            : rate.entreprenurs_id.name_establishment}
        </p>
        <p className="text-muted">{rate.review}</p>
      </Media.Body>
    </Media>
  );
}
