import axios from "axios";
import { routes } from "../utils/mappers";
import {
  createProductFormData,
  createUpdateEntrepreneurFormData,
} from "../utils/payloads";

const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_MERCADIM_BASE_URL_PRO
    : process.env.REACT_APP_MERCADIM_BASE_URL_DEV;

// const baseURL = "http://mercadim.ifce.edu.br/";

export const api = axios.create({
  baseURL: baseURL + "api",
  headers: {
    Accept: "application/json",
  },
});

// export const apiPDF = axios.create({
//   baseURL: "http://localhost:8000/api",
//   headers: {
//     Accept: "application/pdf"
//   }
// })

export function getImage(image) {
  return baseURL + image;
}

getImage("product.jpg");

export async function getPaymentsMethod() {
  const result = await api.get("/paymentsMethod");

  return result.data;
}

export async function paymentMethod(name) {
  const result = await api.post("/paymentMethod", name);

  return result.data;
}

export async function getPaymentMethod(id) {
  const result = await api.get(`/paymentMethod/${id}`);

  return result.data;
}

export async function updatePaymentMethod(payment) {
  const result = await api.post(`/paymentMethod/update/${payment.id}`, payment);

  return result.data;
}

export function setBearerToken(token) {
  api.defaults.headers.Authorization = `Bearer ${token}`;
  // apiPDF.defaults.headers.Authorization = `Bearer ${token}`;
}

export function clearBearerToken() {
  delete api.defaults.headers.Authorization;
  // delete apiPDF.defaults.headers.Authorization;
}

export async function signupUser(user, type) {
  const response = await api.post(routes.signup[type], user);

  return response.data;
}

export async function loginUser(user, type) {
  const response = await api.post("/login/user", user);

  return response.data;
}

export async function searchGiftVouchersCityCategory(
  data,
  page = 1,
  ascOrDesc = "ASC"
) {
  const result = await api.post("/searchGiftVoucherInCity", data, {
    params: {
      page,
      ascOrDesc,
    },
  });
  return result.data;
}

//Pegando todos os vale presentes
export async function getAllGifts(page = 1) {
  const result = await api.get("/giftVouchers/entrepreneur", {
    params: {
      page,
    },
  });
  return result.data;
}

export async function searchGift(query, page = 1) {
  const result = await api.post(
    "/giftVouchers/entrepreneur/search",
    {
      nameValePresente: query,
    },
    {
      params: {
        page,
      },
    }
  );

  return result.data;
}

//Cadastrando um vale presente
export async function AddGiftVoucher(data) {
  try {
    await api.post("/giftVoucher/entrepreneur", data);
    return { success: true };
  } catch (err) {
    return { success: false };
  }
}

//Pegando um vale presente
export async function getGift(id) {
  const result = await api.get(`/giftVoucher/entrepreneur/${id}`);
  return {
    ...result.data,
    type: "giftVoucher",
  };
}

//Atualizando vale presente
export async function updateGiftVoucher(gift) {
  try {
    await api.post(`/giftVoucher/entrepreneur/update/${gift.id}`, gift);

    return { updated: true };
  } catch (err) {
    return { updated: false };
  }
}

//Deletando um vale presente
export async function deleteGift(id) {
  const result = await api.delete(`/delete/giftVoucher/entrepreneur/${id}`);
  return result.data;
}

// Pegando todos os admins
export async function getAllAdmins(page = 1) {
  const result = await api.get("/admins", { params: { page } });
  return result.data;
}

export async function getAdmin(id) {
  const result = await api.get(`/admin/${id}`);

  return result.data;
}

//Cadastrando uma admin
export async function AddAdmin(data) {
  const result = await api.post("/admin", data);

  return result.data;
}

//Deletando um admin
export async function deleteAdmin(user) {
  try {
    await api.delete(`/admin/delete/${user.id}`);
  } catch (err) {}
}

// Pegando todos os usuários
export async function getAllUsers(page = 1) {
  const result = await api.get("/users", {
    params: { page },
  });

  return result.data;
}

//Pegando um usuário
export async function getUser(id) {
  const result = await api.get(`/user/${id}`);

  return result.data;
}

export async function searchUser(query, page = 1) {
  // name only
  const isCPF = /^\d{11}$/.test(query);

  if (isCPF) {
    try {
      const result = await api.post("/user/cpf", { cpfUser: query });
      return { data: [result.data] };
    } catch (err) {
      return { data: [] };
    }
  } else {
    const result = await api.post("/user/search", { nameUser: query, page });
    return result.data;
  }
}

export async function searchAdmin(query, page = 1) {
  // name only
  const result = await api.post("/admin/search", {
    nameAdmin: query,
    page,
  });

  return result.data;
}

export async function searchEntrepreneur(query, page = 1) {
  // name only
  const isCNPJ = /^\d{14}$/.test(query);
  const isCPF = /^\d{11}$/.test(query);

  if (isCPF || isCNPJ) {
    try {
      const result = await api.post("/entrepreneur/cpfcnpj", {
        cpf_cnpj: query,
      });
      return { data: result.data };
    } catch (err) {
      return { data: [] };
    }
  } else {
    const result = await api.post("/entrepreneur/search", {
      name_establishment: query,
      page,
    });

    return result.data;
  }
}

export async function getGeneralRole() {
  const result = await api.get("/roleGeneral");

  return result.data;
}

export async function logoutUser(role) {
  await api.post(routes.logout[role]);
}

export async function searchPayment(query) {
  const result = await api.post("/paymentMethod/search", {
    namePayment: query,
  });

  return result.data;
}

export async function searchCategory(query) {
  const result = await api.post("/categories/search", {
    nameCategory: query,
  });

  return result.data;
}

export async function searchLocation(query, page = 1) {
  const result = await api.post("/city/search", { nameCity: query, page });
  return result.data;
}

export async function getProductsCity(query) {
  const result = await api.post("/products/city", {
    city: query,
  });
  return result.data[0];
}

export async function updateCategory(category) {
  const result = await api.post(`/categories/update/${category.id}`, category);

  return result.data;
}

export async function getDashboardAdmin() {
  const result = await api.get("/dashboard/admin");

  return result.data;
}

export async function getDashboardEntrepreneur() {
  const result = await api.get("/dashboard/entrepreneur");

  return result.data;
}

export async function getDashboardEntrepreneurFilterByMonthYear(month, year) {
  const result = await api.post("/dashboard/filterByMonthYear", {
    year,
    month,
  });

  return result.data;
}

export async function getDashboardAdminFilterByMonthYear(month, year) {
  const result = await api.post("/dashboard/admin/filterByMonthYear", {
    year,
    month,
  });

  return result.data;
}

export async function getCategory(id) {
  const result = await api.get(`/category/${id}`);

  return result.data;
}

export async function productsCategory(id) {
  const result = await api.get(`/products/category/${id}`);
  return result.data;
}

export async function getProductReviews(id) {
  const result = await api.get(`/listReviews/${id}`);

  return result.data;
}

export async function reviewProduct(review) {
  const result = await api.post("/evaluateTheProduct", review);

  return result.data;
}

//Cadastrando uma usuário
export async function AddUser(data) {
  const result = await api.post("/user", data);

  return result.data;
}

//Atualizando um usuário
export async function updateUser(user) {
  const id = user.id;

  if (user.password.length === 0) {
    delete user.password;
  }

  let status = false;

  await api.post(`/user/update/${id}`, user).then(
    (res) => {
      if (res.data.id) {
        status = true;
      }
    },
    (err) => {
      // const msg = Object.values(err.response.data.erros).map((m) =>
      //   m.toString()
      // );
      status = false;
    }
  );
  return status;
}

export async function updateAdmin(admin) {
  try {
    await api.post(`/admin/update/${admin.id}`, admin);

    return { updated: true };
  } catch (err) {
    return { updated: false };
  }
}

//Deletando um usuário
export async function deleteUser(user) {
  try {
    await api.delete(`/user/delete/${user.id}`);
  } catch (err) {}
}

//Pegando todos os empreendedores
export async function getAllEnterpreneurs(page = 1) {
  const result = await api.get(`/entrepreneurs`, { params: { page } });
  return result.data;
}

//Pegando um empreendedor
export async function getEntrepreneur(id) {
  const result = await api.get(`/entrepreneur/${id}`);

  return result.data;
}

//Cadastrando uma empreendedor
export async function AddEntrepreneur(data) {
  const result = await api.post("/entrepreneur", data);

  return result.data;
}

//Atualizando um empreendedor
export async function updateEntrepreneur(user) {
  try {
    await api.post(
      `/entrepreneur/update/${user.id}`,
      createUpdateEntrepreneurFormData(user)
    );

    return { updated: true };
  } catch (err) {
    return { updated: false };
  }
}

//Deletando um empreendedor
export async function deleteEntrepreneur(user) {
  try {
    await api.delete(`/entrepreneur/delete/${user.id}`);
  } catch (err) {}
}

//Pegando os produtos do empreendedor
export async function getProductsEntrepreneur(page = 1) {
  const result = await api.get("/products/entrepreneur", {
    params: {
      page,
    },
  });
  return result.data;
}

export async function searchProductsEntrepreneur(query, page = 1) {
  const result = await api.post("/products/entrepreneur/search", {
    nameProduto: query,
    page,
  });

  return result.data[0];
}

//Cadastrando um produto do empreendedor
export async function AddProductEntrepreneur(data) {
  try {
    await api.post("/product/entrepreneur", data);
    return { success: true };
  } catch (err) {
    return { success: false };
  }
}

//Pesquisando um produto
export async function searchProduct(query, page = 1) {
  // name only
  const result = await api.post("/products/search", {
    nameProduto: query,
    page,
  });
  return result.data[0];
}
export async function searchProductInCityCategory(
  data,
  page = 1,
  ascOrDesc = "ASC"
) {
  const result = await api.post("/searchProductInCity", data, {
    params: {
      page,
      ascOrDesc,
    },
  });
  return result.data;
}

export async function getHighlightedProducts() {
  const result = await api.get("/products/highlighted");
  return result.data;
}

//Deletando um produto do empreendedor
export async function deleteProduct(prod) {
  try {
    await api.delete(`/delete/product/entrepreneur/${prod.id}`);
  } catch (err) {}
}

//Pegando todas os produtos
export async function getAllProducts(page = 1) {
  const result = await api.get("/products", {
    params: { page },
  });
  return result.data.products;
}

export async function getProduct(id) {
  const result = await api.get(`/product/entrepreneur/${id}`);
  return {
    ...result.data,
    type: "product",
  };
}

export async function updateProduct(product) {
  try {
    await api.post(
      `/product/entrepreneur/update/${product.id}`,
      createProductFormData(product)
    );
    return true;
  } catch (err) {
    return false;
  }
}

//Pegando todas as categorias
export async function getAllCategories() {
  const result = await api.get("/categories");
  return result.data.categories;
}

//Cadastrando uma categoria
export async function category(data) {
  const result = await api.post("/category", data);

  return result.data;
}

//Deletando uma categoria
export async function deleteCategory(cat) {
  try {
    await api.delete(`/categories/delete/${cat.id}`);
  } catch (err) {}
}

//Deletando uma forma de pagamento
export async function deletePayment(pay) {
  try {
    await api.delete(`/paymentMethod/delete/${pay.id}`);
  } catch (err) {}
}

//Pegando todas as localidades
export async function getAllLocations(page = 1) {
  const result = await api.get("/citys", {
    params: {
      page,
    },
  });

  return result.data;
}

//Pegando Informações da Loja
export async function getStore(id, dados, page = 1) {
  const result = await api.post(`/profileStore/${id}`, dados, {
    params: {
      page,
    },
  });
  const data = result.data;

  return data;
}

//Pegando Categorias da Loja
export async function getStoreCategories(id) {
  const result = await api.get(`/profileStoreCategories/${id}`);
  const data = result.data;

  return data;
}
export async function createOrderServico(payload){
  const result = await api.post("/pedidoServico", payload);
  return result.data;
}

//Criando Pedido
export async function createOrder(payload) {
  const result = await api.post("/order", payload);
  return result.data;
}

//Pegando Informações do Pedido
export async function getOrder(id) {
  const result = await api.get(`/order/${id}`);
  return result.data;
}

//Confirmando o Pagamento do Pedido
export async function confirmOrderPayment(payload) {
  const result = await api.post(`/order/payment`, payload);
  return result.data;
}
export async function CancelPayment(payload) {
  const result = await api.post(`/order/paymentCancel`, payload);
  return result.data;
}

//Pegando o PDF Comprovante do Pedido
export async function pdfOrderPayment(id) {
  const result = await api.get(`/pdf/order/${id}`, {
    headers: { Accept: "application/pdf" },
    responseType: "blob",
  });
  return result.data;
}

export async function loyaltyCardClientPdf(id) {
  const result = await api.get(`/createPdfLoyaltyCard/${id}`, {
    headers: { Accept: "application/pdf" },
    responseType: "blob",
  });
  return result.data;
}

export async function deleteLoyaltyCardClient(id) {
  const result = await api.delete(`/loyaltyCardClient/user/delete/${id}`);
  return result.data;
}

// Todos os Cartões Fidelidades (Empreendedor)
export async function getMyLoyaltyCards() {
  const result = await api.get(`/loyaltyCards/entrepreneur`);
  return result.data.data;
}

// Pegar um Cartão Fidelidade (Empreendedor)
export async function getLoyaltyCard(id) {
  const result = await api.get(`/loyaltyCard/entrepreneur/${id}`);
  return result.data[0][0];
}

// Criar Cartão Fidelidade (Empreendedor)
export async function createLoyaltyCard(payload) {
  if (payload.status === "disponivel") {
    await api.get(`/loyaltyCard/entrepreneur/desativar/`).then(async () => {
      return await api.post(`/loyaltyCard/entrepreneur/`, payload);
    });
    return false;
  }
  const result = await api.post(`/loyaltyCard/entrepreneur/`, payload);
  return result.data;
}
export async function verificaCartaoCliente(id) {
  const result = await api.post("/loyaltyCardClient/verifica", {
    id: id
  });
  return result.data;
}

// Atualizar Cartão Fidelidade (Empreendedor)
export async function updateLoyaltyCard(payload) {
  if (payload.status === "disponivel") {
    await api.get(`/loyaltyCard/entrepreneur/desativar/`).then(async () => {
      return await api.post(
        `/loyaltyCard/entrepreneur/update/${payload.id}`,
        payload
      );
    });
    return false;
  }
  const result = await api.post(
    `/loyaltyCard/entrepreneur/update/${payload.id}`,
    payload
  );
  return result.data;
}

// Deletar Cartão Fidelidade (Empreendedor)
export async function deleteLoyaltyCard(id) {
  const result = await api.delete(`/loyaltyCard/entrepreneur/delete/${id}`);
  return result.data;
}

// Desativar todos os Cartões Fidelidades (Empreendedor)
export async function disableAllLoyaltyCards() {
  const result = await api.get(`/loyaltyCard/entrepreneur/desativar/`);
  return result.data;
}

// Ativar um Cartão Fidelidade (Empreendedor)
export async function activateLoyaltyCard(id) {
  await api.get(`/loyaltyCard/entrepreneur/desativar/`).then(async () => {
    return await api.get(`/loyaltyCard/entrepreneur/ativar/${id}`).data;
  });
  return false;
}

// Verificação da disponibilidade de uso do CPF
export async function availableCpf(cpfUser) {
  const result = await api.post(`/userCpfAvailable`, {
    cpf: cpfUser,
  });
  return result.data;
}

export async function availableCpfCnpj(cpfCnpj) {
  const result = await api.post(`/entrepreneurCpfCnpjAvailable`, {
    cpf_cnpj: cpfCnpj,
  });
  return result.data;
}
export async function availablePhone(telephone) {
  const result = await api.post(`/entrepreneurPhoneAvailable`, {
    telephone: telephone,
  });
  return result.data;
}

// Verificação da disponibilidade de uso do E-mail
export async function availableEmail(email) {
  const result = await api.get(`/userEmailAvailable`, {
    params: {
      email,
    },
  });
  return result.data;
}

export async function availableEmailEntrepreneur(email) {
  const result = await api.post(`/entrepreneurEmailAvailable`, {
    email: email,
  });
  return result.data;
}

// Solicita a recuperação de senha
export async function forgotPassword(email) {
  const result = await api.post("/forgot", {
    email: email,
  });
  return result.data;
}

// Atualiza a senha
export async function newPassword(data) {
  const result = await api.post("/newPassword", data);
  return result.data;
}

// Minhas compras
export async function myPurchases() {
  const result = await api.get("/myShoppingClient");
  return result.data;
}

// Meus Cartões Fidelidade (Cliente)
export async function getMyLoyaltyCardsClient() {
  const result = await api.get(`/loyaltyCardClient/user`);
  return result.data;
}

export async function getCheckoutEntrpreneurInfo(id) {
  const result = await api.get(`/checkout/entrepreneur/${id}`);
  return result.data;
}

// Meus Pedidos (Empreendedor)
export async function myOrders() {
  const result = await api.get(`/orders`);
  return result.data;
}
