import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { InfoModalContext } from "../contexts/InfoModal";
import { useEffect } from "react";

export default function InfoModalProvider({ children }) {
  const style = {
    color: "red",
    fontSize: 13,
  };
  const [message, setMessage] = useState(null);

  useEffect(() => {
    console.log('Init InfoModalProvider')
  }, []);

  return (
    <InfoModalContext.Provider value={{ setMessage }}>
      {children}
      {message && (
        <Modal centered show={message}>
        <Modal.Header>
          <Modal.Title><h4>Seja bem-vindo!</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Informações do seu acesso:</h6>
          <p>
            <strong>Login:</strong> CPF ou CNPJ.
          </p>
          <p>
            <strong>Senha:</strong> Os 8 primeiros digito do CPF ou CNPJ.
          </p>
          <p style={style}>Faça a alteração no primeiro acesso!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setMessage(null)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </InfoModalContext.Provider>
  );
}
