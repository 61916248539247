import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { useState } from "react";
import Footer from "../../../components/Footer";
import LoadingIndicator from "../../../components/LoadingIndicator";
import useNotification from "../../../contexts/notification";
import { NewPasswordSchema } from "../../../utils/validation_schemas";
import { newPassword } from "../../../services/api";
import { useHistory } from "react-router-dom";
export default function NewPassword(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { setMessage } = useNotification();
  const { history } = useHistory();
  
  const formik = useFormik({
    initialValues: {
      password: "",
      conf_password: "",
      show_password: false
    },
    
    validationSchema: NewPasswordSchema,
    onSubmit: async (values) => {
      const newPass = {
        token: props.match.params.token,
        password: values.password,
        password_confirm: values.conf_password
      }
      setIsLoading(true);
      await newPassword(newPass)
        .then((res) => {
          setMessage({
            error: false,
            content: res.message,
          });
          history.push("/entrar");
        }, (err) => {
          setMessage({
            error: true,
            content: err,
          });
        })
      
      setIsLoading(false);
     
    },
  
  }
  );

  return (
    <>
      <Helmet title="Mercadim: Nova Senha" />
      <Container fluid>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <Row className="justify-content-center">
            <Col xs={10} md={8} lg={6}>
              <h3 className="text-center text-primary my-5">
                Nova Senha
              </h3>
              <p>
                Por favor, informe sua nova senha e confirme logo abaixo.
              </p>

              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-4" controlId="formPassword">
                  <Form.Label>Senha *</Form.Label>
                  <Form.Control
                    required
                    className="my-1"
                    type={formik.values.show_password ? "text" : "password"}
                    name="password"
                    placeholder="Senha"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isValid={formik.touched.password && !formik.errors.password}
                    isInvalid={formik.touched.password && formik.errors.password ? true : false}
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group className="mb-4" controlId="formConfPassword">
                  <Form.Label>Confirmação Senha *</Form.Label>
                  <Form.Control
                    required
                    className="my-1"
                    type={formik.values.show_password ? "text" : "password"}
                    name="conf_password"
                    placeholder="Senha"
                    value={formik.values.conf_password}
                    onChange={formik.handleChange}
                    isValid={formik.touched.conf_password && !formik.errors.conf_password}
                    isInvalid={formik.touched.conf_password && formik.errors.conf_password ? true : false}
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    {formik.errors.conf_password}
                  </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group className="mb-4" controlId="formShowPassword">
                  <Form.Label>Mostrar senha</Form.Label>
                  <Form.Check
                    name="show_password"
                    type="switch"
                    defaultChecked={false}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Button
                  className="my-5"
                  variant="primary"
                  type="submit"
                  block
                >
                  Enviar
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Container>
      <Footer />
    </>
  )
}
