import {
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  Image,
  InputGroup,
} from "react-bootstrap";
import SignupEntrepreneurForm from "../../../../components/SignupEntrepreneurForm";
import UpdateEntrepreneurForm from "../../../../components/UpdateEntrepreneurForm";
import { useState } from "react";
import PaginationComponent from "../../../../components/Pagination";
import useIBGE from "../../../../hooks/useIBGE";
import {
  getAllEnterpreneurs,
  deleteEntrepreneur,
  AddEntrepreneur,
  searchEntrepreneur,
  getImage,
} from "../../../../services/api";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import { useEffect } from "react";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import usePaymentsMethod from "../../../../hooks/usePaymentsMethod";
import useNotification from "../../../../contexts/notification";

export default function EntrepreneursTab() {
  const [query, setQuery] = useState("");
  const [data, setData] = useState(null);
  const [showAddEntrepreneurModal, setShowAddEntrepreneurModal] =
    useState(false);
  const [selectedEntrepreneur, setSelectedEntrepreneur] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [state, setState, states, city, setCity, cities] = useIBGE();
  const [showUpdateEntrepreneurModal, setShowUpdateEntrepreneurModal] =
    useState(false);
  const paymentsMethod = usePaymentsMethod();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { setMessage } = useNotification();

  const refresh = () => {
    const error = {
      error: true,
      content: "Erro ao recarregar. Tente novamente mais tarde",
    };

    if (query) {
      searchEntrepreneur(query, page).then(
        (data) => {
          setData(data);
        },
        () => setMessage(error)
      );
    } else {
      getAllEnterpreneurs(page).then(
        (data) => {
          setData(data);
        },
        () => setMessage(error)
      );
    }
  };

  function handleQueryEntrepreneur(e) {
    e.preventDefault();
    searchEntrepreneur(query, page).then((data) => {
      // Workaround from API
      if (data.data) {
        setData(data);
      } else {
        setData({ data: [] });
      }
    });
  }

  useEffect(() => {
    if (query.length === 0) {
      getAllEnterpreneurs(page).then(
        (data) => {
          setData(data);
          setLoading(false);
        },
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    }
  }, [query, page, setMessage]);

  useEffect(() => {
    if (selectedEntrepreneur) {
      setShowUpdateEntrepreneurModal(true);
    }
  }, [selectedEntrepreneur]);

  function AddEntrepreneurModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adicionar empreendedor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignupEntrepreneurForm
            onSubmit={async (data) => {
              await AddEntrepreneur(data);
              setShowAddEntrepreneurModal(false);
              refresh();
              setMessage({
                error: false,
                content: "Empreendedor cadastrado com sucesso.",
              })
            }}
          />
        </Modal.Body>
      </Modal>
    );
  }

  function UpdateEntrepreneurModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Atualizar empreendedor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateEntrepreneurForm
            entrepreneurId={selectedEntrepreneur}
            states={states}
            paymentsMethod={paymentsMethod}
            onDone={props.onHide}
          />
        </Modal.Body>
      </Modal>
    );
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={12} md={9} lg={10}>
          <Form >
            <Form.Group className="my-3 d-flex" controlId="">
              <InputGroup>
                <Form.Control
                  type="text"
                  value={query}
                  required
                  placeholder="Buscar empreendedores"
                  onKeyUp={handleQueryEntrepreneur}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Col>

        <Col xs={12} md={3} lg={2}>
          <div>
            <div>
              <Button
                className="btn btn-primary d-flex align-items-center text-white mr-3"
                onClick={() => setShowAddEntrepreneurModal(true)}
              >
                <span className="mr-2">ADICIONAR</span>
                <IoIosAddCircle style={{ fontSize: "1.6em" }} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row >
        <Col xs={12} md={12} lg={12}>
          <Table striped bordered responsive size="sm">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Logo</th>
                <th>Telefone</th>
                <th>Email</th>
                <th>Método de pagamento</th>
                <th>Método de entrega</th>
                <th>Quantidade de produtos cadastrados</th>
                <th>Ações</th>
              </tr>
            </thead>
            {data.data.length === 0 && (
              <h5 className="text-muted text-center my-5">
                Sem resultados
              </h5>
            )}
            <tbody>
              {data.data.map((emp) => (
                <tr key={emp.id}>
                  <td>{emp.name_establishment}</td>
                  <td>
                    <Image
                      src={getImage(emp.logo_store)}
                      style={{ width: "100px" }}
                    />
                  </td>
                  <td>{emp.telephone}</td>
                  <td>{emp.email}</td>
                  <td>
                    {emp.payment_method.map((name) =>
                      name.name ? name.name + " " : name.name
                    )}
                  </td>
                  <td>{emp.delivery_method[0].name}</td>
                  <td>{emp.products_count}</td>
                  <td>
                    <div className="d-flex">
                      <Button
                        className="btn btn-warning text-white d-flex align-items-center mr-2"
                        size="sm"
                        title="Atualizar"
                        onClick={() => {
                          setSelectedEntrepreneur(emp.id);
                        }}
                      >
                        <MdEdit
                          style={{
                            fontSize: "1.4em",
                          }}
                        />
                      </Button>

                      <Button
                        onClick={() => {
                          let conf = window.confirm("Tem certeza que deseja deletar este item?");
                          if(conf) {
                            deleteEntrepreneur(emp).then(
                              () => setMessage({
                                error: false,
                                content: "Empreendedor deletado com sucesso.",
                              }), () => setMessage({
                                error: true,
                                content: "Ocorreu um erro, tente novamente mais tarde."
                              })
                            )
                            refresh()
                          }
                        }}
                        size="sm"
                        title="Remover"
                        className="btn btn-danger text-white d-flex align-items-center"
                      >
                        <MdDelete
                          style={{
                            fontSize: "1.4em",
                          }}
                        />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        {data.links && (
          <PaginationComponent pagination={data} onClickPage={setPage} />
        )}
      </div>
      <AddEntrepreneurModal
        show={showAddEntrepreneurModal}
        onHide={() => {
          setShowAddEntrepreneurModal(false);
          refresh();
        }}
      />
      <UpdateEntrepreneurModal
        show={showUpdateEntrepreneurModal}
        onHide={() => {
          setShowUpdateEntrepreneurModal(false);
          setSelectedEntrepreneur(null);
          refresh();
        }}
      />
    </>
  );
}
