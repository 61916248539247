import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { useState } from "react";
import Footer from "../../../components/Footer";

import LoadingIndicator from "../../../components/LoadingIndicator";
import useNotification from "../../../contexts/notification";
import { ForgotPasswordSchema } from "../../../utils/validation_schemas";
import { forgotPassword } from "../../../services/api";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const { setMessage } = useNotification();

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      await forgotPassword(values.email).then(
        (res) => {
          if (res.message) {
            setIsLoading(false);
            setMessage({
              error: false,
              content: "Enviando e-mail.",
            });
          }
        },
        (err) => {
          setIsLoading(false);
          setMessage({
            error: true,
            content: "E-mail não cadastrado.",
          });
        })
    },
  });


  return (
    <>
      <Helmet title="Mercadim: Recuperar Senha" />
      <Container fluid>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <Row className="justify-content-center">
            <Col xs={10} md={8} lg={6}>
              <h3 className="text-center text-primary my-5">
                Recuperar Senha
              </h3>
              <p>
                Por favor, informe o e-mail vinculado a sua conta. Enviaremos um e-mail com um link para a recuperação da sua senha.
              </p>

              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-4" controlId="formForgotPassword">
                  <Form.Label>E-mail *</Form.Label>
                  <Form.Control
                    required
                    className="my-1"
                    type="email"
                    name="email"
                    placeholder="Seu email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isValid={formik.touched.email && !formik.errors.email && formik.values.email !== ""}
                    isInvalid={
                      formik.errors.email && formik.touched.email ? true : false
                    }
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  className="my-5"
                  variant="primary"
                  type="submit"
                  block
                >
                  Enviar
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Container>
      <Footer />
    </>
  )
}
