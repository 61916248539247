import RedirectContainer from "../../../components/RedirectContainer";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  InputGroup,
} from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import { BsFillGridFill } from "react-icons/bs";
import { Formik, useFormik, Form as FormFormik, Field } from "formik";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { TiInputChecked } from "react-icons/ti";
import {
  getAllCategories,
  category,
  deleteCategory,
  getCategory,
  updateCategory,
  searchCategory,
} from "../../../services/api";
import useNotification from "../../../contexts/notification";

function UpdateCategoryForm({ categoryId, onDone }) {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
  });
  const { setMessage } = useNotification();

  useEffect(() => {
    getCategory(categoryId).then(
      (data) => {
        const { categories } = data;
        setInitialValues({
          name: categories[0].name,
          id: categories[0].id,
        });
        setLoading(false);
      },
      () =>
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        })
    );
  }, [categoryId, setMessage]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          const category = { ...values };
          await updateCategory(category);
          onDone?.();
          setMessage({
            error: false,
            content: "Categoria atualizado com sucesso",
          });
        } catch (err) {
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          });
        }
      }}
    >
      {({ errors, touched }) => (
        <Form as={FormFormik}>
          <Row className="mb-3">
            <Col lg={12}>
              <Form.Control
                as={Field}
                autoFocus
                className="my-1"
                type="text"
                name="name"
                placeholder="Nome do Pagamento"
                required="Campo necessário"
              />
            </Col>
          </Row>
          <Button className="d-flex align-items-center" type="submit">
            ATUALIZAR CATEGORIA
            <TiInputChecked style={{ fontSize: "1.8em" }} />
          </Button>
        </Form>
      )}
    </Formik>
  );
}
function AddCategoryModal(props) {
  const [loading, setLoading] = useState(false);
  const { setMessage } = useNotification();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const categories = { ...values };
        await category(categories);
        setLoading(false);
        setMessage({
          error: false,
          content: "Categoria cadastrado com sucesso",
        });
        props.onHide();
      } catch (err) {
        setLoading(false);
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        });
      }
    },
  });

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-primary">Adicionar Categoria</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Row className="mb-3">
            <Col lg={12}>
              <Form.Control
                autoFocus
                className="py-1"
                type="text"
                name="name"
                placeholder="Nome da categoria"
                required="Campo necessário"
                onChange={formik.handleChange}
               
              />
            </Col>
          </Row>
          <Button
            className="d-flex align-items-center"
            type="submit"
            disabled={loading}
          >
            SALVAR CATEGORIA
            <TiInputChecked style={{ fontSize: "1.8em" }} />
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function Categories() {
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  
  const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { setMessage } = useNotification();

  const refresh = () => {
    const error = {
      error: true,
      content: "Erro ao recarregar. Tente novamente mais tarde",
    };

    if (query) {
      searchCategory(query).then(
        (data) => {
          setData(data.categories);
        },
        () => setMessage(error)
      );
    } else {
      getAllCategories().then(
        (data) => {
          setData(data);
        },
        () => setMessage(error)
      );
    }
  };

  function handleQueryCategory(e) {
    e.preventDefault();
    searchCategory(query).then((data) => {
      setData(data.categories || []);
    });
  }

  useEffect(() => {
    if (selectedCategory) {
      setShowUpdateCategoryModal(true);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (query.length === 0) {
      getAllCategories().then(
        (response) => {
          setData(response);
          setIsLoading(false);
        },
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    }
  }, [query, setMessage]);

  function UpdateCategoryModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-primary">Adicionar Pagamento</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateCategoryForm
            categoryId={selectedCategory}
            onDone={props.onHide}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <RedirectContainer role="admin">
        <Container fluid>
          <div>
            <SidebarPanelNavigation isAdmin />
            <div className="content-panel">
              {isLoading && <LoadingIndicator />}
              {!isLoading && (
                <>
                  <div className="py-3">
                    <h5 className="text-primary mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
                      <BsFillGridFill className="mr-2" />
                      Categorias
                    </h5>
                  </div>

                  <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-between">
                    <div>
                      <Form >
                        <Form.Group className="d-flex" controlId="">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              placeholder="Buscar categoria"
                              onKeyUp={handleQueryCategory}      
                              required
                              onChange={(e) => setQuery(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Form>
                    </div>

                    <div>
                      <Button
                        variant="primary"
                        onClick={() => setShowAddCategoryModal(true)}
                        className=" mr-md-3 mr-lg-3 mr-0 mr-sm-0 mb-2 mb-sm-2 mb-md-0 mb-lg-0"
                      >
                        <span className="mr-2">ADICIONAR CATEGORIA</span>
                        <IoIosAddCircle style={{ fontSize: "1.6em" }} />
                      </Button>

                      <AddCategoryModal
                        show={showAddCategoryModal}
                        onHide={() => {
                          setShowAddCategoryModal(false);
                          refresh();
                        }}
                      />
                      <UpdateCategoryModal
                        show={showUpdateCategoryModal}
                        onHide={() => {
                          setShowUpdateCategoryModal(false);
                          setSelectedCategory(null);
                          refresh();
                        }}
                      />
                    </div>
                  </div>

                  <Row>
                    <Col lg={12}>
                                         
                      <Table striped bordered hover responsive size="sm">
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th className="w-25">Ações</th>
                          </tr>
                                              </thead>
                                              {data.length === 0 && (
                                                  <h5 className="text-muted text-center my-5">
                                                      Sem resultados
                                                  </h5>
                                              )}
                        <tbody>
                          {data.map((categories) => (
                            <tr key={categories.id} className="w-25">
                              <td>{categories.name}</td>
                              <td className="d-flex">
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="text-white d-flex align-items-center mr-3"
                                  onClick={() => {
                                    setSelectedCategory(categories.id);
                                  }}
                                >
                                  <MdEdit style={{ fontSize: "1.4em" }} />
                                </Button>

                                <Button
                                  size="sm"
                                  onClick={() => {
                                    let conf = window.confirm("Esta ação deletará o cartão fidelidade.");
                                    if(conf) {
                                      deleteCategory(categories).then(
                                        () => setMessage({
                                          error: false,
                                          content: "Categoria deletada com sucesso."
                                        }), () => setMessage({
                                          error: true,
                                          content: "Ocorreu um erro, tente novamente mais tarde."
                                        })
                                      )
                                      refresh();
                                    }
                                  }
                                }
                                  variant="danger"
                                  className="text-white d-flex align-items-center"
                                >
                                  <MdDelete style={{ fontSize: "1.4em" }} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {/* <div className="d-flex justify-content-end">
                        <Pagination>
                          <Pagination.First />
                          <Pagination.Prev />
                          <Pagination.Item active>{1}</Pagination.Item>
                          <Pagination.Item>{2}</Pagination.Item>
                          <Pagination.Item>{3}</Pagination.Item>
                          <Pagination.Next />
                          <Pagination.Last />
                        </Pagination>
                      </div> */}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>
      </RedirectContainer>
    </>
  );
}
