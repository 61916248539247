import { Col, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useState } from "react";
import useNotification from "../../contexts/notification";
import LoadingIndicator from "../LoadingIndicator";
import { LoyaltyCardSchema } from "../../utils/validation_schemas";
import { createLoyaltyCard } from "../../services/api";

export default function AddLoyaltyCardForm({ onSubmit }) {
  const [isLoading, setIsLoading] = useState(false);
  const { setMessage } = useNotification();

  const formik = useFormik({
    initialValues: {
      description: "",
      status: false,
      deadline_expire: 7,
      points: 5
    },
    validationSchema: LoyaltyCardSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      values = {
        ...values,
        description: String(values.description),
        status: String(values.status ? "disponivel" : "indisponivel"),
        deadline_expire: Number(values.deadline_expire),
        points: Number(values.points)
      }
      await createLoyaltyCard(values)
        .then((res) => {
          setMessage({
            error: false,
            content: "Cartão fidelidade adicionado com sucesso."
          })
        }, (err) => {
          setMessage({
            error: true,
            content: "Ocorreu um erro, por favor tente novamente mais tarde."
          })
        });
      onSubmit?.();
    },
  });

  return (
    <>
    {isLoading && <LoadingIndicator />}
    {!isLoading && (
      <Form onSubmit={formik.handleSubmit} className="mt-3">
        <h6 className="mb-3 text-primary">Dados do Cartão Fidelidade</h6>
        <Form.Group controlId="cardDescription">
          <Form.Label>Descrição</Form.Label>
          <Form.Control
            required
            name="description"
            placeholder="Ex: 15% Off na tigela de Açai"
            value={formik.values.description}
            onChange={formik.handleChange}
            isValid={formik.touched.description && !formik.errors.description}
            isInvalid={formik.errors.description}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.description}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="cardDeadlineExpire">
            <Form.Label>Prazo de Expiração - <small>(em dias)</small></Form.Label>
            <Form.Control 
              required
              type="number"
              name="deadline_expire"
              min="5"
              value={formik.values.deadline_expire}
              onChange={formik.handleChange}
              isValid={formik.touched.deadline_expire && !formik.errors.deadline_expire}
              isInvalid={formik.errors.deadline_expire}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.deadline_expire}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="cardPoints">
            <Form.Label>Pontos</Form.Label>
            <Form.Control
              as="select"
              name="points"
              onChange={formik.handleChange}
              value={formik.values.points}
              isValid={formik.touched.points && !formik.errors.points}
              isInvalid={formik.errors.points}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.points}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row className="align-items-center">
          <Form.Group as={Col} controlId="cardStatus">
            <Form.Check
              custom
              type="checkbox"
              label="Ativar Cartão Fidelidade"
              name="status"
              onChange={formik.handleChange}
              checked={formik.values.status}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="submitButton">
            <Button variant="primary" type="submit">
              Adicionar
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    )}
  </>
  );
}
