import { Container, Row, Col, Spinner, Alert  } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../../contexts/auth";
import { Redirect } from "react-router";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../../components/Footer";
import SignupClientWizard from "../../../components/SignupClientWizard";
import useInfoModal from "../../../contexts/InfoModal";

export default function Signup() {
  const [status, setStatus] = useState({ status: "start" });
  const { user, signup } = useAuth();
  const { setMessage } = useInfoModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet title="Mercadim: Cadastro" />
      {user && <Redirect to="/" />}
      {!user && (
        <Container fluid="md" className="py-4">
          <Row className="justify-content-center">
            <Col sm={10} lg={8} className="align-self-center">
              <h3 className="text-center text-primary pt-4 mb-2">Criar conta</h3>
              <p className="text-muted text-center mb-5">
                Já possui conta? <Link to="/entrar">Entre</Link>
              </p>
              {status.status === "loading" && (
                <div className="d-flex justify-content-center">
                  <Spinner variant="primary" animation="border" size="sm" />
                </div>
              )}
              {status.status === "error" && (
                <Alert
                  variant="danger"
                  dismissible
                  onClose={() => setStatus({ status: "start" })}
                >
                  {Object.values(status.errors)[0] || "Um erro ocorreu"}
                </Alert>
              )}
                <SignupClientWizard
                  onSubmit={async (user) => {
                    setStatus({ status: "loading" });
                    const status = await signup(user, "client");
                    if (status.signed) {
                      setStatus({ status: "success" });
                      setMessage({ content: "" });
                    } else {
                      setStatus({ status: "error", errors: status.errors });
                    }
                  }}
                />
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}
