import "./styles.scss";
import { currency } from "../../utils/mappers";
import { Card, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FiGrid } from "react-icons/fi";
import "./styles.scss";
import { getImage } from "../../services/api";

export default function GiftVoucherCard({ giftvoucher }) {
  const history = useHistory();




  return (
    <Card
      className="my-1 card-product"
      title={`Ver detalhes de ${giftvoucher.name}`}
      onClick={() => {
        return history.push(`/giftvoucher/${giftvoucher.id}`);
      }}
    >
      <Image src={getImage(giftvoucher.image)} className="product-card-image" />
      <Card.Body>
        <Card.Title className="text-primary">{giftvoucher.name}</Card.Title>
        <Card.Subtitle className="mb-2 font-weight-normal display-truncate">
          <p className="description">{giftvoucher.description}</p>
        </Card.Subtitle>

        <p className="text-primary price h3">{currency(giftvoucher.price)}</p>
        {giftvoucher.categoryName && (
          <footer className="card__footer">
            <div className="d-flex justify-content-center align-items-center">
              <FiGrid className="text-muted" size={16} />
              <span className="text-muted ml-1">
                {giftvoucher.categoryName}
              </span>
            </div>
          </footer>
        )}
      </Card.Body>
    </Card>
  );
}
