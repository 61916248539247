import { Link } from "react-router-dom";
import Logo from "./../../assets/img/logo-horizontal.svg";
import useAuth from "../../contexts/auth";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { FaUserCircle } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import "./style.scss";

export default function NavBar() {
  const { user, logout } = useAuth();
  const { width } = useWindowSize();
  const [toggled, setToggled] = useState(false);
  const closeSidebar = () => setToggled(false);

  const renderUnlogged = () => {
    return (
      <Nav className="ml-auto">
        <Nav.Link as={Link} to="/cadastro">
          Cadastre-se
        </Nav.Link>
        <Nav.Link as={Link} to="/entrar">
          Entrar
        </Nav.Link>
      </Nav>
    );
  };

  const renderEntrepreneurSidebar = () => {
    return (
      <SubMenu title="Painel da Loja" defaultOpen>
        <MenuItem>
          <Link to="/empreendedor/dashboard" onClick={closeSidebar}>
            Dashboard
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/empreendedor/pedidos" onClick={closeSidebar}>
            Pedidos da Loja
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/empreendedor/produtos" onClick={closeSidebar}>
            Meus Produtos
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/empreendedor/giftvoucher" onClick={closeSidebar}>
            Meus Vale Presentes
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/empreendedor/fidelidade" onClick={closeSidebar}>
            Configurar Cartão Fidelidade
          </Link>
        </MenuItem>
      </SubMenu>
    );
  };

  const renderAdminSidebar = () => {
    return (
      <SubMenu title="Painel do Admin" defaultOpen>
        <MenuItem>
          <Link to="/admin/dashboard" onClick={closeSidebar}>
            Dashboard
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/admin/usuarios" onClick={closeSidebar}>
            Usuários
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/admin/pagamentos" onClick={closeSidebar}>
            Formas de pagamento
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/admin/localidades" onClick={closeSidebar}>
            Localidades
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/admin/categorias" onClick={closeSidebar}>
            Categorias
          </Link>
        </MenuItem>
      </SubMenu>
    );
  };

  const renderUser = () => {
    return (
      <NavDropdown
        id="basic-nav-dropdown"
        title={
          <span className="align-self-center">
            <FaUserCircle className="text-secondary" size={"22"} />
            {width > 450 && <span className="ml-2">{user.name}</span>}
          </span>
        }
        alignRight
      >
        <NavDropdown.Item to="/conta" as={Link} onClick={closeSidebar}>
          Minha Conta
        </NavDropdown.Item>
        {user.type !== "admin" && (
          <>
            <NavDropdown.Item to="/compras" as={Link} onClick={closeSidebar}>
              Minhas Compras
            </NavDropdown.Item>
            <NavDropdown.Item to="/fidelidade" as={Link} onClick={closeSidebar}>
              Meus Cartões de Fidelidade
            </NavDropdown.Item>
          </>
        )}
        <NavDropdown.Item onClick={logout}>Sair</NavDropdown.Item>
      </NavDropdown>
    );
  };

  return (
    <div>
      <Navbar fixed="top" bg="primary" variant="dark">
        <Navbar.Brand as={Link} to="/sobre" onClick={closeSidebar}>
          <img
            src={Logo}
            alt="Logo"
            width="128px"
            className="mx-2 d-flex align-self-center"
          />
        </Navbar.Brand>
        {width > 992 && (
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/" onClick={closeSidebar}>
              Produtos
            </Nav.Link>
            <Nav.Link as={Link} to="/giftvoucher" onClick={closeSidebar}>
              Vale Presente
            </Nav.Link>
            {user && user.type !== "client" && (
              <Nav.Link
                as={Link}
                to={user.type === "admin" ? "/admin" : "/empreendedor"}
              >
                Painel
              </Nav.Link>
            )}
          </Nav>
        )}
        {width < 992 && (
          <Button
            size="sm"
            className="ml-auto"
            onClick={() => setToggled((state) => !state)}
          >
            <GiHamburgerMenu />
          </Button>
        )}
        {user && <Nav xs="auto">{renderUser(user.type, width)}</Nav>}
        {!user && width > 992 && <Nav xs="auto">{renderUnlogged()}</Nav>}
        {/* <Nav xs="auto" className="ml-auto">
          {user && renderUser(user.type, width)}
          {!user && width > 992 && renderUnlogged()}
        </Nav> */}
      </Navbar>
      {width < 992 && (
        <ProSidebar
          breakPoint="lg"
          toggled={toggled}
          onToggle={(value) => {
            setToggled(value);
          }}
        >
          <SidebarContent>
            <Menu>
              <MenuItem>
                <Link to="/" onClick={closeSidebar}>
                  Produtos
                </Link>
              </MenuItem>

              <MenuItem>
                <Link to="/giftvoucher" onClick={closeSidebar}>
                  Vale Presente
                </Link>
              </MenuItem>
              {/* {user && user.type !== "admin" && (
                <MenuItem>
                    <Link to="/compras">
                      Compras
                    </Link>
                </MenuItem>
              )} */}
              {user &&
                user.type === "entrepreneur" &&
                renderEntrepreneurSidebar()}
              {user && user.type === "admin" && renderAdminSidebar()}
              {!user && (
                <MenuItem>
                  <Link to="/cadastro" onClick={closeSidebar}>
                    Cadastre-se
                  </Link>
                </MenuItem>
              )}
              {!user && (
                <MenuItem>
                  <Link to="/entrar" onClick={closeSidebar}>
                    Entrar
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      )}
    </div>
  );
}
