import { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import useAuth from "../../contexts/auth";
import useCart from "../../contexts/cart";
import { getImage } from "../../services/api";
import { calculateTotal, currency, discountIsValid } from "../../utils/mappers";
import "./styles.scss";

function FloatingCartDialog({ show, onClose }) {
  const {
    cartData,
    removeFromCart,
    addQuantityProduct,
    removeQuantityProduct,
    clearCart,
  } = useCart();

  const { user } = useAuth();

  const history = useHistory();

  const renderEnts = () => {
    const ents = [];

    for (let entId in cartData) {
      const ent = cartData[entId];

      ents.push(
        <Container fluid className="my-1" key={ent.id + ent.name_establishment}>
          <Card>
            <Card.Header>
              <Row className="justify-content-between">
                <Col className="display-truncate">
                  <span>{ent.name_establishment}</span>
                </Col>
                <Col xs="auto">
                  <span className="clean-btn" onClick={() => clearCart(ent.id)}>
                    Limpar
                  </span>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {renderEntItems(ent.id, ent.products)}
              <hr />
              <Row as="footer">
                <Col>
                  <h6>
                    Total:
                    <span className="text-primary">
                      {calculateTotal(ent.products)}
                    </span>
                  </h6>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    onClick={() => {
                      if (user) {
                        history.push(`/checkout?ent=${ent.id}`);
                      } else {
                        history.push(`/entrar?next=/checkout&ent=${ent.id}`);
                      }

                      onClose();
                    }}
                  >
                    Finalizar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      );
    }

    return ents;
  };

  const renderEntItems = (ent_id, products) => {
    const items = [];
    for (let item in products) {
      const p = products[item];
      const showDiscount = discountIsValid(p.discount, p.date_expiration);
      let price;

      if (
        p.wholesale_price !== null &&
        p.quantity >= p.min_quantity_wholesale
      ) {
        price = p.wholesale_price;
      } else if (showDiscount) {
        price = p.priceDiscount;
      } else {
        price = p.price;
      }

      items.push(
        <Row className="align-items-center py-1" key={p.id + p.name}>
          <Col xs="auto">
            <FaTrashAlt
              className="text-danger"
              onClick={() => removeFromCart(ent_id, p)}
            />
          </Col>
          <Col xs="auto">
            <Image
              src={getImage(p.image)}
              className="rounded"
              height="64"
              width="64"
              alt="Product"
            />
          </Col>
          <Col xs={12} sm={7} md={8}>
            <span className="text-primary font-weight-bold d-block">
              {p.name}
            </span>
            {showDiscount && (
              <>
                <span className="text-primary mr-2">
                  {currency(p.priceDiscount)}
                </span>
                <span
                  style={{ textDecoration: "line-through", fontSize: ".9rem" }}
                >
                  {currency(p.price)}
                </span>
              </>
            )}
            {p.wholesale_price !== null &&
              p.quantity >= p.min_quantity_wholesale && (
                <>
                  <span
                    className="mr-2"
                    style={{ textDecoration: "line-through" }}
                  >
                    {currency(p.price)}
                  </span>
                </>
              )}
            {!showDiscount &&
              (p.wholesale_price === null ||
                p.quantity < p.min_quantity_wholesale) && (
                <>
                  <span className="text-primary mr-2">{currency(p.price)}</span>
                </>
              )}
            {p.wholesale_price &&
              p.min_quantity_wholesale &&
              p.wholesale_price !== null &&
              p.min_quantity_wholesale !== null && (
                <span style={{ fontSize: ".9rem" }}>
                  {currency(p.wholesale_price)} a partir de{" "}
                  {p.min_quantity_wholesale} unds.
                </span>
              )}
            <span className="d-block">
              SUBTOTAL:{" "}
              <span className="text-primary">
                {currency(price * p.quantity)}
              </span>
            </span>
          </Col>
          <Col className="py-1">
            <Button
              size="sm"
              className="p-1"
              onClick={() => removeQuantityProduct(ent_id, p)}
            >
              <FaMinus size="8" />
            </Button>
            <span className="mx-1">{p.quantity}</span>
            <Button
              size="sm"
              className="p-1"
              onClick={() => addQuantityProduct(ent_id, p)}
            >
              <FaPlus size="8" />
            </Button>
          </Col>
        </Row>
      );
    }
    return items;
  };

  return (
    <Modal show={show} size="lg" onHide={onClose} className="border-0" centered>
      <Modal.Header
        closeButton
        className="bg-primary text-secondary border-0"
        closeLabel="Fechar"
      >
        <Modal.Title>Carrinho</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderEnts()}

        {Object.keys(cartData).length === 0 && (
          <p className="text-center text-muted">O carrinho está vazio.</p>
        )}
      </Modal.Body>
    </Modal>
  );
}

function FloatingCartButton({ show, onClick }) {
  const showClass = show ? "d-flex" : "d-none";
  const { cartData } = useCart();

  return (
    <Button
      className={`p-2 bg-success shadow-lg rounded align-items-center ${showClass}`}
      onClick={onClick}
    >
      <FiShoppingCart />
      <span className="badge badge-secondary ml-1">
        {cartData ? Object.keys(cartData).length : 0}
      </span>
    </Button>
  );
}

export default function FloatingCart() {
  const [showDialog, setShowDialog] = useState(false);

  const onClickButton = () => setShowDialog((state) => !state);
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const routesToShowFloatingCard = [
    "produto",
    "giftvoucher",
    "loja",
    "categoria",
  ];

  const showFloatingCart = routesToShowFloatingCard.includes(pathname);

  return (
    <div className={`floating-cart position-fixed ${showFloatingCart}`}>
      <FloatingCartButton show={!showDialog} onClick={onClickButton} />
      <FloatingCartDialog show={showDialog} onClose={onClickButton} />
    </div>
  );
}
