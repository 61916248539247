import {
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  InputGroup,
} from "react-bootstrap";
import SignupAdminForm from "../../../../components/SignupAdminForm";
import UpdateAdminForm from "../../../../components/UpdateAdminForm";
import { useState } from "react";
import PaginationComponent from "../../../../components/Pagination";
import useIBGE from "../../../../hooks/useIBGE";
import {
  getAllAdmins,
  deleteAdmin,
  AddAdmin,
  searchAdmin,
} from "../../../../services/api";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import { useEffect } from "react";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import useNotification from "../../../../contexts/notification";

export default function AdminsTab() {
  const [query, setQuery] = useState("");
  const [data, setData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [state, setState, states, city, setCity, cities] = useIBGE();
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [page, setPage] = useState(1);
  const [modalShowUpdateAdmin, setModalShowUpdateAdmin] = useState(false);
  const [modalShowAdmin, setModalShowAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setMessage } = useNotification();

  const refresh = () => {
    const error = {
      error: true,
      content: "Erro ao recarregar. Tente novamente mais tarde",
    };

    if (query) {
      searchAdmin(query, page).then(
        (data) => {
          setData(data);
        },
        () => setMessage(error)
      );
    } else {
      getAllAdmins(page).then(
        (data) => {
          setData(data);
        },
        () => setMessage(error)
      );
    }
  };

  function handleQueryAdmin(e) {
    e.preventDefault();
    searchAdmin(query, page).then(
      (data) => {
        setData(data);
      },
      
    );
  }

  useEffect(() => {
    if (query.length === 0) {
      getAllAdmins(page).then(
        (data) => {
          setData(data);
          setLoading(false);
        },
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    }
  }, [query, page, setMessage]);

  useEffect(() => {
    if (selectedAdmin) {
      setModalShowUpdateAdmin(true);
      
    }
  }, [selectedAdmin]);

  function UpdateAdminModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Atualizar admin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateAdminForm states={states} adminId={selectedAdmin} onDone={props.onHide} />
        </Modal.Body>
      </Modal>
    );
  }

  function AddAdminModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adicionar administrador
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignupAdminForm
            onSubmit={async (data) => {
              try {
                await AddAdmin(data);
                setModalShowAdmin(false);
                setMessage({
                  error: false,
                  content: "Administrador cadastrado com sucesso.",
                });
                refresh();
              } catch (err) {
                setMessage({
                  error: true,
                  content: "Um erro ocorreu. Tente novamente mais tarde",
                });
              }
            }}
          />
        </Modal.Body>
      </Modal>
    );
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={12} md={9} lg={10}>
          <Form onSubmit={handleQueryAdmin}>
            <Form.Group className="my-3 d-flex" controlId="">
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Buscar administradores"
                  required
                  value={query}
                  onKeyUp={handleQueryAdmin}
                  onChange={(e) => setQuery(e.target.value)}
                />
               
              </InputGroup>
            </Form.Group>
          </Form>
        </Col>

        <Col xs={12} md={3} lg={2}>
          <div>
            <div>
              <Button
                onClick={() => setModalShowAdmin(true)}
                className="btn btn-primary d-flex align-items-center text-white"
              >
                <span className="mr-2">ADICIONAR</span>
                <IoIosAddCircle style={{ fontSize: "1.6em" }} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Table striped bordered responsive size="sm">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Sobrenome</th>
                <th>Estado</th>
                <th>Cidade</th>
                <th>Email</th>
                <th>Ações</th>
              </tr>
            </thead>
            {data.data.length === 0 && (
              <h5 className="text-muted text-center my-5">
                Sem resultados
              </h5>
            )}
            <tbody>

              {data.data.map((admin) => (
                <tr key={admin.id}>
                  <td>{admin.name}</td>
                  <td>{admin.surname}</td>
                  <td>{admin.adress_id.state}</td>
                  <td>{admin.adress_id.city}</td>
                  <td>{admin.email}</td>
                  <td>
                    <div className="d-flex">
                      <Button
                        className="btn btn-warning text-white d-flex align-items-center mr-2"
                        size="sm"
                        title="Atualizar"
                        onClick={() => {
                          setSelectedAdmin(admin.id);
                        }}
                      >
                        <MdEdit
                          style={{
                            fontSize: "1.4em",
                          }}
                        />
                      </Button>

                      <Button
                        onClick={() => {
                          let conf = window.confirm("Tem certeza que deseja deletar este item?");
                          if(conf) {
                            deleteAdmin(admin).then(
                              () => setMessage({
                                error: false,
                                content: "Administrador deletado com sucesso."
                              }), () => setMessage({
                                error: true,
                                content: "Ocorreu um erro, tente novamente mais tarde."
                              })
                            )
                            refresh();
                          } 
                        }}
                        size="sm"
                        title="Remover"
                        className="btn btn-danger text-white d-flex align-items-center"
                      >
                        <MdDelete
                          style={{
                            fontSize: "1.4em",
                          }}
                        />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <PaginationComponent pagination={data} onClickPage={setPage} />
      </div>
      <AddAdminModal
        show={modalShowAdmin}
        onHide={() => {
          setModalShowAdmin(false);
          refresh();
        }}
      />
      <UpdateAdminModal
        show={modalShowUpdateAdmin}
        onHide={() => {
          setModalShowUpdateAdmin(false);
          setSelectedAdmin(null);
          refresh();
        }}
      />
    </>
  );
}
