import RedirectContainer from "../../../components/RedirectContainer";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  getDashboardAdmin,
  getDashboardAdminFilterByMonthYear,
} from "../../../services/api";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { currency } from "../../../utils/mappers";
import useNotification from "../../../contexts/notification";
import GraficoAdmin from '../../../components/GraficoAdmin';
export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [monthYear, setMonthYear] = useState("");
  const [dashboard, setDashboard] = useState(null);
  const { setMessage } = useNotification();

  useEffect(() => {
    getDashboardAdmin().then(
      (data) => {
        setDashboard(data);
        setLoading(false);
      },
      () =>
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        })
    );
   
  }, [setMessage]);

  /*useEffect(() => {
    getTotalClientsEntrepreneurs().then((data) => {
      setTotalCliEntre(data);
      setLoading(false);
    },
      () => setMessage({
        error: true,
        content: "Um erro ocorreu. Tente novamente mais tarde",
      })
    );
  },[setMessage])*/

  useEffect(() => {
    if (monthYear.length !== 0) {
      const [year, month] = monthYear.split("-");
      getDashboardAdminFilterByMonthYear(month, year).then(setDashboard, () =>
        setMessage({
          error: true,
          content: "Um erro ocorreu. Tente novamente mais tarde",
        })
      );
    }
  }, [monthYear, setMessage]);

  return (
    <>
      <RedirectContainer role="admin">
        <Container fluid>
          <div>
            <SidebarPanelNavigation isAdmin />

            <div className="content-panel">
              {loading && <LoadingIndicator />}
              {!loading && (
                <>
                  <Row>
                    <Col lg={4} className="mt-3">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Form.Control
                          type="month"
                          placeholder="Período"
                          value={monthYear}
                          onChange={(e) => setMonthYear(e.target.value)}
                        />
                      </Form>
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col lg={4} className="my-1">
                      <Card>
                        <Card.Body>
                          <h1 className="text-primary text-center display-4">
                            {currency(dashboard.grossBiling)}
                          </h1>
                          <span className="text-primary d-block text-center">
                            Faturamento bruto
                          </span>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col lg={4} className="my-1">
                      <Card>
                        <Card.Body>
                          <h1 className="text-primary text-center display-4">
                            {dashboard.unitsSold}
                          </h1>
                          <span className="text-primary d-block text-center">
                            Unidades vendidas
                          </span>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col lg={4} className="my-1">
                      <Card>
                        <Card.Body>
                          <h1 className="text-primary text-center display-4">
                            {dashboard.totalSalesAmount}
                          </h1>
                          <span className="text-primary d-block text-center">
                            Quantidade de vendas
                          </span>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4} className="my-1">
                      <Card>
                        <Card.Body>
                          <h1 className="text-primary text-center display-4">
                            {dashboard.totalClients}
                          </h1>
                          <span className="text-primary d-block text-center">
                            Total de clientes
                          </span>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4} className="my-1">
                      <Card>
                        <Card.Body>
                          <h1 className="text-primary text-center display-4">
                            {dashboard.totalStores}
                          </h1>
                          <span className="text-primary d-block text-center">
                           Total de lojas cadastradas
                          </span>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <Row className="pb-3">
                  <Col lg={12}>
                    <GraficoAdmin />
                  </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>
      </RedirectContainer>
    </>
  );
}
