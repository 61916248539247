import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import { getEntrepreneur, updateEntrepreneur,availableCpfCnpj } from "../../services/api";
import { UpdateEntrepreneurSchema } from "../../utils/validation_schemas";
import LoadingIndicator from "../LoadingIndicator";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import handleChangeCep from '../../utils/validate_cep'
import useNotification from "../../contexts/notification";
import { cpfCnpj } from "../../utils/formatters";
import { createSignupEntrepreneurFormData } from "../../utils/payloads";
export default function UpdateEntrepreneurForm({ paymentsMethod, entrepreneurId, onLoaded, onDone }) {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ status: "start" });
  const clearStatus = () => setStatus({ status: "start" });
  const { setMessage } = useNotification();
  
  const formik = useFormik({
    initialValues: {
      type: "entrepreneur",
      name_establishment: "",
      description: "",
      cpf_cnpj: "",
      telephone: "",
      email: "",
      logo_store: undefined,
      delivery_method: "1",
      payment_method: [],
      zip_code: "",
      neighborhood: "",
      street: "",
      complement: "",
      number: "",
      city: "",
      password: "",
      show_password: false,
    },
    validationSchema: UpdateEntrepreneurSchema,
    onSubmit: async (values) => {
      const cleanedCpfCnpj = values.cpf_cnpj.replace(/\D/g, "");
      const entrepreneur = {
        ...values,
        cpf_cnpj: cleanedCpfCnpj,
        type: "entrepreneur",
      };

      if (entrepreneur.password.length === 0) {
        delete entrepreneur.password;
      }

      if (entrepreneur.logo_store === undefined) {
        delete entrepreneur.logo_store;
      }
      
      
     
      console.log(entrepreneur);
      const updated = await updateEntrepreneur(entrepreneur);
      onDone?.();

      setStatus(updated ? { status: "success" } : { status: "error" });
    },
  });

  useEffect(() => {
    getEntrepreneur(entrepreneurId).then(
      (data) => {
        const [entrepreneur, adresses] = data;
        formik.setValues({
          id: entrepreneur.id,
          payment_method: entrepreneur.payment_method.map((p) => String(p.id)),
          delivery_method: String(entrepreneur.delivery_method[0].id),
          type: "entrepreneur",
          name_establishment: entrepreneur.name_establishment,
          cpf_cnpj:  cpfCnpj(entrepreneur.cpf_cnpj),
          description: entrepreneur.description,
          telephone: entrepreneur.telephone,
          email: entrepreneur.email,
          logo_store: undefined,
          zip_code: adresses.zip_code,
          neighborhood: adresses.neighborhood,
          street: adresses.street,
          complement: adresses.complement,
          number: adresses.number,
          city: adresses.city,
          state: adresses.state,
          password: "",
          show_password: false,
        });

        setLoading(false);
        if (onLoaded) {
          onLoaded();
        }
      },
      () =>
        setMessage({
          error: true,
          content: "Ocorreu um erro. Tente novamente mais tarde",
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entrepreneurId]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="pt-2">
      <h6 className="mb-3 text-primary">Dados do negócio</h6>
      <Form.Group as={Row}>
        <Col lg={8}>
          <Form.Label>Nome do estabelecimento *</Form.Label>
          <Form.Control
            type="text"
            name="name_establishment"
            placeholder="Seu estabelecimento"
            onChange={formik.handleChange}
            value={formik.values.name_establishment}
            isValid={
              formik.touched.name_establishment &&
              !formik.errors.name_establishment
            }
            isInvalid={formik.errors.name_establishment}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.name_establishment}
          </Form.Control.Feedback>
        </Col>
        <Col xl={6} md={6} sm={12}>
            <Form.Label>CPF/CNPJ *</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="cpf_cnpj"
              placeholder="CPF ou CPNJ sem pontuação"
              onChange={(e) =>
                formik.setFieldValue("cpf_cnpj", cpfCnpj(e.target.value))
              }
              value={formik.values.cpf_cnpj}
              isValid={formik.touched.cpf_cnpj && !formik.errors.cpf_cnpj}
              isInvalid={
                formik.errors.cpf_cnpj && formik.touched.cpf_cnpj ? true : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.cpf_cnpj}
            </Form.Control.Feedback>
          </Col>
        <Col lg={4}>
          <Form.Label>Logo da loja*</Form.Label>
          <Form.File
            id="exampleFormControlFile2"
            accept="image/png, image/jpeg"
            name="logo_store"
            lang="pt"
            label={
              formik.values.logo_store
                ? formik.values.logo_store.name
                : "Escolher arquivo"
            }
            onChange={(e) =>
              formik.setFieldValue("logo_store", e.currentTarget.files[0])
            }
            custom
          />
        </Col>
        <Col lg={12}>
          <Form.Label>Descrição do estabelecimento*</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            required
            name="description"
            placeholder="Descrição do estabelecimento"
            onChange={formik.handleChange}
            value={formik.values.description}
            isValid={formik.touched.description && !formik.errors.description}
            isInvalid={formik.errors.description}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.description}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Telefone *</Form.Label>
          <Form.Control
            type="text"
            name="telephone"
            placeholder="Telefone"
            as={InputMask}
            mask="(99)99999-9999"
            onChange={formik.handleChange}
            value={formik.values.telephone}
            isValid={formik.touched.telephone && !formik.errors.telephone}
            isInvalid={formik.errors.telephone}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.telephone}
          </Form.Control.Feedback>
        </Col>
        <Col xl={8}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Seu email"
            onChange={formik.handleChange}
            value={formik.values.email}
            isValid={formik.touched.email && !formik.errors.email}
            isInvalid={formik.errors.email}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <h6 className="mb-3 text-primary">Métodos de entrega</h6>
      <Form.Group as={Row}>
        <Col xs="auto">
          <Form.Check
            custom
            id="custom-checkbox-presencial"
            name="delivery_method"
            type="radio"
            checked={formik.values.delivery_method === "1"}
            value="1"
            label="Presencial"
            onChange={formik.handleChange}
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            custom
            id="custom-checkbox-delivery"
            name="delivery_method"
            type="radio"
            value="2"
            checked={formik.values.delivery_method === "2"}
            label="Delivery"
            onChange={formik.handleChange}
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            custom
            id="custom-checkbox-delivery-presencial"
            name="delivery_method"
            type="radio"
            checked={formik.values.delivery_method === "3"}
            label="Presencial e Delivery"
            value="3"
            onChange={formik.handleChange}
          />
        </Col>
      </Form.Group>
      <h6 className="mb-3 text-primary">Métodos de pagamento</h6>
      <Form.Group as={Row}>
        {paymentsMethod.map((f) => {
          return (
            <Col xs="auto" key={f.id}>
              <Form.Check
                custom
                id={`custom-checkbox-${f.id}`}
                name="payment_method"
                type="checkbox"
                isInvalid={formik.errors.payment_method}
                value={f.id}
                label={f.name}
                required={formik.values.payment_method.length === 0}
                checked={formik.values.payment_method.includes(
                  String(f.id)
                )}
                onChange={formik.handleChange}
              />
            </Col>
          );
        })}
      </Form.Group>
      <h6 className="mb-3 text-primary">Endereço</h6>
      <Form.Group as={Row}>
        <Col lg={4}>
          <Form.Label>CEP *</Form.Label>
          <Form.Control
            as={InputMask}
            mask="99999-999"
            type="text"
            name="zip_code"
            placeholder="Seu CEP"
            onChange={formik.handleChange}
            onBlur={() => handleChangeCep(formik)}
            value={formik.values.zip_code}
            isValid={formik.touched.zip_code && !formik.errors.zip_code}
            isInvalid={
              formik.errors.zip_code && formik.touched.zip_code
                ? true
                : false
            }
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.zip_code}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Estado</Form.Label>
          <Form.Control
            type="text"
            name="state"
            disabled
            onChange={formik.handleChange}
            value={formik.values.state}
            isValid={formik.touched.state && !formik.errors.state}
            isInvalid={formik.errors.state}
          ></Form.Control>
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.state}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Cidade *</Form.Label>
          <Form.Control
            type="text"
            name="city"
            disabled
            placeholder="Sua cidade"
            value={formik.values.city}
            onChange={formik.handleChange}
            isValid={formik.touched.city && !formik.errors.city}
            isInvalid={formik.errors.city}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.city}
          </Form.Control.Feedback>
        </Col>
        <Col lg={10}>
          <Form.Label>Rua</Form.Label>
          <Form.Control
            type="text"
            name="street"
            placeholder="Rua"
            value={formik.values.street}
            onChange={formik.handleChange}
            isValid={formik.touched.street && !formik.errors.street}
            isInvalid={formik.errors.street}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.street}
          </Form.Control.Feedback>
        </Col>
        <Col lg={2}>
          <Form.Label>Número</Form.Label>
          <Form.Control
            type="text"
            name="number"
            placeholder="Número"
            value={formik.values.number}
            onChange={formik.handleChange}
            isValid={formik.touched.number && !formik.errors.number}
            isInvalid={formik.errors.number}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.number}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Bairro</Form.Label>
          <Form.Control
            type="text"
            name="neighborhood"
            placeholder="Bairro"
            value={formik.values.neighborhood}
            onChange={formik.handleChange}
            isValid={
              formik.touched.neighborhood && !formik.errors.neighborhood
            }
            isInvalid={formik.errors.neighborhood}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.neighborhood}
          </Form.Control.Feedback>
        </Col>
        <Col lg={8}>
          <Form.Label>Complemento</Form.Label>
          <Form.Control
            type="text"
            name="complement"
            placeholder="Complemento"
            value={formik.values.complement}
            onChange={formik.handleChange}
            isValid={formik.touched.complement && !formik.errors.complement}
            isInvalid={formik.errors.complement}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.complement}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <h6 className="mb-3 text-primary">Senha</h6>
      <Form.Group as={Row}>
        <Col lg={6}>
          <Form.Label>Nova senha</Form.Label>
          <Form.Control
            type={formik.values.show_password ? "text" : "password"}
            name="password"
            placeholder="Nova senha"
            onChange={formik.handleChange}
            value={formik.values.password}
            isValid={formik.touched.password && !formik.errors.password}
            isInvalid={formik.errors.password}
          />
          <Form.Text className="text-muted">
            A senha deve ter no mínimo 8 caracteres entre números e letras.
          </Form.Text>
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
          <Form.Check
            id="show-password-3"
            name="show_password"
            className="mb-2"
            type="switch"
            label="Mostrar senha"
            onChange={formik.handleChange}
            checked={formik.values.show_password}
            custom
          />
        </Col>
      </Form.Group>
      {status.status === "success" && (
        <SuccessAlert msg="Perfil atualizado com sucesso." onClose={clearStatus} />
      )}
      {status.status === "error" && (
        <ErrorAlert msg="Ocorreu um erro, por favor tente novamente mais tarde." onClose={clearStatus} />
      )}
      <Form.Group as={Row}>
        <Col xs="auto">
          <Button variant="primary" type="submit">
            Atualizar
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
}
