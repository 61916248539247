import { currency, discountIsValid } from "../../utils/mappers";
import { Card, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FiGrid } from "react-icons/fi";
import { RatingStars } from "../RatingStars";
import "./styles.scss";
import { getImage } from "../../services/api";

export default function ProductCard ({ product }) {
  const history = useHistory();
  const showDiscountInfo = discountIsValid(
    product.discount,
    product.date_expiration
  );

  return (
    <Card
      className="my-1 card-product"
      title={`Ver detalhes de ${product.name}`}
      onClick={() => {
        return history.push(`/produto/${product.id}`);
      }}
    >
      <Image src={getImage(product.image)} className="product-card-image" />
      <Card.Body>
        <Card.Title className="text-primary">
          <div className="display-truncate">
            <div className="display-truncate__text">
              {product.name}
            </div>
          </div>
          <div className="d-flex">
            <RatingStars size="sm" value={Number(product.review_avg_note)} />
            <h6 className="text-primary ml-2">{product.review_count} avaliações</h6>
          </div>
        </Card.Title>
        <Card.Subtitle className="font-weight-normal display-truncate">
          <p className="description">{product.description}</p>
        </Card.Subtitle>
        {!!product.confidential_price && (
          <div className="text-primary price h3">
            <span className="h6 bg-primary text-light p-1 rounded">Preço sob consulta</span>
          </div>
        )}
        {!product.confidential_price && (
          <>
            {showDiscountInfo && (
              <p className="old-price">{currency(product.price)}</p>
            )}
            <div className="text-primary price h3">
              {currency(
                showDiscountInfo ? product.priceDiscount : product.price
              )}
              
              {showDiscountInfo && (
                <span className="h6 bg-primary text-light p-1 rounded ml-2">{`${Number(
                  product.discount
                )}% OFF`}</span>
              )}
            </div>
            <div className="text-primary" style={{textAlign: "center", marginTop: "-.5rem", height: "3.5rem"}}>
              {product.wholesale_price != null && 
                `${currency(product.wholesale_price)} a partir de ${product.min_quantity_wholesale} unds.`
              }
            </div>
          </>
        )}
        {product.category && (
          <footer className="card__footer">
            <div className="d-flex justify-content-center align-items-center">
              <FiGrid className="text-muted" size={16} />
              <span className="ml-1 text-muted">{product.category.name}</span>
            </div>
          </footer>
        )}
        {product.categoryName && (
          <footer className="card__footer">
            <div className="d-flex justify-content-center align-items-center">
              <FiGrid className="text-muted" size={16} />
              <span className="ml-1 text-muted">{product.categoryName}</span>
            </div>
          </footer>
        )}
      </Card.Body>
    </Card>
  );
}
