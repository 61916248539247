import { Col, Form, Row, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { AddGiftVoucher, getAllCategories } from "../../services/api";
import { AddGiftVoucherchema } from "../../utils/validation_schemas";
import { createGiftVoucherFormData } from "../../utils/payloads";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import useNotification from "../../contexts/notification";

export default function AddGiftVoucherForm({ onSubmit }) {
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState("start");
  const clearStatus = () => setStatus({ status: "start" });
  const { setMessage } = useNotification();
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    getAllCategories().then((data) => {
      setCategories(data);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: "",
      image: undefined,
      amount: "",
      category_id: undefined,
    },
    validationSchema: AddGiftVoucherchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      values = {
        ...values,
      };
      await AddGiftVoucher(createGiftVoucherFormData(values));
      setMessage({
        error: false,
        content: "Vale presente  adicionado com sucesso",
      });
      setSubmitting(false);
      onSubmit?.();
    },
  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="mt-3">
      <h6 className="mb-3 text-primary">Dados do vale presente</h6>
      <Form.Group className="mt-1 mb-4" controlId="formProductData">
        <Row>
          <Col xl={8} md={8}>
            <Form.Label>Nome do vale presente</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="name"
              placeholder="Nome do produto"
              required
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={formik.errors.name && formik.touched.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>

          <Col xl={4} md={4} sm={5}>
            <Form.Label>Preço *</Form.Label>
            <Form.Control
              className="my-1"
              type="number"
              name="price"
              min="0"
              placeholder="Preço"
              onChange={formik.handleChange}
              value={formik.values.price}
              isValid={formik.touched.price && !formik.errors.price}
              isInvalid={formik.errors.price && formik.touched.price}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.price}
            </Form.Control.Feedback>
          </Col>

          <Col xl={12} md={12} sm={12}>
            <Form.Label>Descrição *</Form.Label>
            <Form.Control
              className="my-1"
              as="textarea"
              rows={3}
              name="description"
              placeholder="Descrição do produto"
              onChange={formik.handleChange}
              value={formik.values.description}
              isValid={formik.touched.description && !formik.errors.description}
              isInvalid={
                formik.errors.description && formik.touched.description
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </Col>

          <Col xl={6} md={6} sm={6} className="mt-1">
            <Form.Label>Imagem do vale presente*</Form.Label>
            <Form.File
              id=""
              accept="image/png, image/jpeg"
              name="image"
              lang="pt"
              label={
                formik.values.image
                  ? formik.values.image.name
                  : "Escolher arquivo"
              }
              onChange={(e) =>
                formik.setFieldValue("image", e.currentTarget.files[0])
              }
              isInvalid={formik.errors.image && formik.touched.image}
              feedback={formik.errors.image}
              custom
            />
          </Col>

          <Col xl={6} md={6} sm={6}>
            <Form.Label>Quantidade *</Form.Label>
            <Form.Control
              className="my-1"
              type="number"
              name="amount"
              min="0"
              placeholder="Quantidade"
              onChange={formik.handleChange}
              value={formik.values.amount}
              isValid={formik.touched.amount && !formik.errors.amount}
              isInvalid={formik.errors.amount && formik.touched.amount}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.amount}
            </Form.Control.Feedback>
          </Col>

          <Col xl={6} md={6} sm={6} className="mt-1">
            <Form.Label>Categoria *</Form.Label>
            <Form.Control
              as="select"
              name="category_id"
              isValid={formik.touched.category_id && !formik.errors.category_id}
              isInvalid={
                formik.errors.category_id && formik.touched.category_id
              }
              onChange={formik.handleChange}
              value={formik.values.category_id}
              autoComplete="off"
              // disabled={formik.values.isPromotion === "1"}
              custom
              defaultValue=""
            >
              <option disabled value="">
                Selecione uma categoria
              </option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.category_id}
            </Form.Control.Feedback>
          </Col>

        </Row>
        {status === "success" && (
          <SuccessAlert msg="Vale presente adicionado" onClose={clearStatus} />
        )}

        {status === "error" && (
          <ErrorAlert msg="Um erro ocorreu." onClose={clearStatus} />
        )}
        <Button
          className="my-2"
          variant="primary"
          type="submit"
          disabled={submitting}
        >
          Adicionar
        </Button>
      </Form.Group>
    </Form>
  );
}
