import { useEffect } from "react";
import { CartContext } from "../contexts/cart";
import useLocalStorage from "../hooks/useLocalStorage";

export default function CartProvider({ children }) {
  const [cartData, setCartData] = useLocalStorage("@app_mercadim:cart", {});

  const addToCart = (product) => {
  
    const [entrepreneur] = product.entrepreneur;
    const { product: productData } = product;
    // const [delivery_method] = entrepreneur.delivery_method;
    const ent = cartData[entrepreneur.id];
    
    if (!ent) {
      if (productData.amount < 1) {
        console.log('Produto fora de estoque!');
        return;
      }
      productData.quantity = 1;
      
      const proData = {
        type: product.type,
        id: productData.id,
        quantity: productData.quantity,
        name: productData.name,
        image: productData.image,
        price: productData.price,
        discount: productData.discount,
        date_expiration: productData.date_expiration,
        priceDiscount: productData.priceDiscount,
        wholesale_price: productData.wholesale_price,
        min_quantity_wholesale: productData.min_quantity_wholesale,
        amount: productData.amount,
        confidential_price:productData.confidential_price 
      }
      
      const data = {
        id: entrepreneur.id,
        name_establishment: entrepreneur.name_establishment,
        products: { [`${productData.id}-${product.type}`]: proData},
      };
      
      setCartData({ ...cartData, [entrepreneur.id]: data });
    } else {
      const prod = ent.products[`${productData.id}-${product.type}`];

      if (prod && prod.type === product.type) {
        if (prod.quantity >= prod.amount) {
          console.log('Você atingiu a quantidade máxima deste produto disponível em estoque!');
          return;
        }
        prod.quantity++;
        setCartData({ ...cartData });
      } else {
        if (productData.amount < 1) {
          console.log('Produto fora de estoque!');
          return;
        }
        productData.quantity = 1;
        ent.products = { ...ent.products, [`${productData.id}-${product.type}`]: {...productData, type: product.type } };
        setCartData({ ...cartData });
      }
    }
  };

  const clearCart = (id) => {
    delete cartData[id];

    setCartData({ ...cartData });
  };

  const addQuantityProduct = (ent_id, product) => {
    const ent = cartData[ent_id];
    const prod = ent.products[`${product.id}-${product.type}`];

    if (prod && prod.type === product.type ) {
      if (prod.quantity >= prod.amount) {
        console.log('Você atingiu a quantidade máxima deste produto disponível em estoque!');
        return;
      }
      prod.quantity++;
      setCartData({ ...cartData });
    }
  };

  const indexOfProduct = (entId, product) => {
    if (cartData[entId]) {
      return cartData[entId].products[`${product.id}-${product.type}`] !== undefined;
    }

    return -1;
  };

  const productExists = (entId, p) => {
    const ent = cartData[entId];
    if (ent) {
      const res = ent.products[`${p.product.id}-${p.type}`];
      if(res && res.type === p.type) {
        return res;
      }
    }
    return false;
  };

  const removeQuantityProduct = (ent_id, product) => {
    const ent = cartData[ent_id];
    const prod = ent.products[`${product.id}-${product.type}`];

    if (prod) {
      if (prod.quantity > 1) {
        prod.quantity--;
        setCartData({ ...cartData });
      }
    }
  };

  const removeFromCart = (ent_id, product) => {
    const ent = cartData[ent_id];
    if (Object.keys(ent.products).length > 1) {
      delete ent.products[`${product.id}-${product.type}`];
      setCartData({ ...cartData });
    } else {
      delete cartData[ent_id];
      setCartData({ ...cartData });
    }
  };

  useEffect(() => {
    console.log('Init CartProvider')
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartData,
        addToCart,
        removeFromCart,
        addQuantityProduct,
        removeQuantityProduct,
        clearCart,
        indexOfProduct,
        productExists,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
