import {
  Nav,
  NavDropdown,
  Carousel,
  Container,
  Form,
  Button,
  Modal,
  Col,
  Row,
  ListGroup,
  Image,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useLocationUser from "../../contexts/location";
import {
  getHighlightedProducts,
  getImage,
  searchLocation,
  searchProductInCityCategory,
} from "../../services/api";
import "./styles.scss";
import ProductCard from "../../components/ProductCard";
import { GoChevronDown } from "react-icons/go";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import LoadingIndicator from "../../components/LoadingIndicator";
import Footer from "../../components/Footer";
import debounce from "lodash.debounce";
import useCategories from "../../hooks/useCategories";
import PaginationComponent from "../../components/Pagination";
import { Helmet } from "react-helmet";
import { textEllipsis } from "../../utils/mappers";
import qs from "qs";

export default function Marketplace({ match, location }) {
  // const history = useHistory();
  const categories = useCategories();
  const [categorySelected, setCategorySelected] = useState("Todas as categorias");
  const [query, setQuery] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [page, setPage] = useState();
  const [results, setResults] = useState(null);
  const [status, setStatus] = useState("loading");
  const [showModalLocation, setShowModalLocation] = useState(false);
  const { location: locationUser } = useLocationUser();
  const [lastLocation, setLastLocation] = useState(null); // [city, uf
  const { idCategory } = useParams();
  const [lastCategory, setLastCategory] = useState(null);
  const [ascOrDesc, setAscOrDesc] = useState("ASC");
  const [highlights, setHighlights] = useState(null);
  const [loadingHighlights, setLoadingHighlights] = useState(true);

  const q = qs.parse(location.search, { ignoreQueryPrefix: true }).q;

  useEffect(() => {
    const p = sessionStorage.getItem("@app_mercadim:p");
    if (p) {
      setPage(Number(p));
    }
  }, []);

  useEffect(() => {
    if (lastLocation?.name !== locationUser?.name) {
      console.log('setLastLocation');
      setLastLocation(locationUser);
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationUser]);

  useEffect(() => {
    if (lastCategory !== idCategory) {
      setLastCategory(idCategory);
      setPage(1);
    }
  }, [lastCategory, idCategory]);

  useEffect(() => {
    sessionStorage.setItem("@app_mercadim:q", query);
  }, [query]);

  useEffect(() => {
    if (!q) {
      sessionStorage.setItem("@app_mercadim:p", page);
    }
  }, [page, q]);

  useEffect(() => {
    getHighlightedProducts().then((data) => {
      setHighlights(data);
      setLoadingHighlights(false);
    });
    window.scrollTo(0, 0);
  }, []);

  // Verifica lastLocation
  useEffect(() => {
    console.log('lastLocation', lastLocation);
  }, [lastLocation]);
  
  // Verifica locationUser
  useEffect(() => {
    console.log('locationUser', locationUser);
  }, [locationUser]);

  useEffect(() => {
    if (lastCategory) {
      const payload = {
        category_id: lastCategory,
        ...(!showAll && lastLocation && { city: lastLocation.name }),
      };

      setCategorySelected(categories.find((c) => c.id === Number(lastCategory))?.name);

      searchProductInCityCategory(payload, page, ascOrDesc).then((data) => {
        setResults(data);
        setStatus("result");
      });
    } else {
      setCategorySelected("Todas as categorias");

      if (q && query.length === 0) {
        const payload = {
          nameProduto: q,
          ...(!showAll && lastLocation && { city: lastLocation.name }),
        };
        searchProductInCityCategory(payload, page, ascOrDesc).then((data) => {
          setResults(data);
          setStatus("result");
        });
      } else {
        const dados = {
          nameProduto: query,
          ...(!showAll && lastLocation && { city: lastLocation.name }),
        };

        searchProductInCityCategory(dados, page, ascOrDesc).then((data) => {
          setResults(data);
          setStatus("result");
        });
      }
    }
  }, [lastCategory, categories, lastLocation, page, query, ascOrDesc, showAll, q]);

  function renderResults() {
    if (status !== "result") {
      return null;
    }

    if (results.data.length === 0) {
      return (
        <>
          <Container fluid>
            <Row>
              <Col className="my-5">
                <h5 className="text-muted text-center my-5">Sem resultados</h5>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

    return (
      <>
        <Container fluid>
          <Row className="align-items-stretch">
            {results.data.map((product) => (
              <Col key={product.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                <ProductCard product={product} />
              </Col>
            ))}
          </Row>
        </Container>
        <div className="my-4">
          <PaginationComponent
            pagination={results}
            onClickPage={(page) => {
              setPage(page);
              if (query.length > 0) {
                const dados = {
                  nameProduto: query,
                  ...(!showAll && lastLocation && { city: lastLocation.name }),
                };
                searchProductInCityCategory(dados, page, ascOrDesc).then(
                  (data) => {
                    setResults(data);
                  }
                );
              }
            }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet title="Mercadim: Marketplace" />
      <Nav className="justify-content-between bg-secondary py-1" as="ul">
        <div id="nav-dropdown-categorias">
          <NavDropdown title={categorySelected} id="nav-dropdown">
            <NavDropdown.Item
              as={Link}
              to="/"
              active={lastCategory === undefined}
            >
              Todas as categorias
            </NavDropdown.Item>
            {categories.map((category) => {
              return (
                <NavDropdown.Item
                  key={category.name}
                  as={Link}
                  to={`/categoria/${category.id}`}
                  active={category.id === Number(lastCategory)}
                >
                  {textEllipsis(category.name, 4)}
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>
        </div>
        <Nav.Item>
          <Button variant="link" onClick={() => setShowModalLocation(true)}>
            {lastLocation && `${lastLocation.name}, ${lastLocation.uf}`}
            {!lastLocation && `Selecione o local`}
            <GoChevronDown className="pl-1" />
          </Button>
        </Nav.Item>
      </Nav>
      <div className="marketplace-main-wrapper">
        {!loadingHighlights && (
          <Carousel controls={false}>
            {highlights.map((h) => (
              <Carousel.Item
                as={Link}
                to={`/produto/${h.id}`}
                key={h.id}
              >
                <Image
                  src={getImage(h.image)}
                  height="220px"
                  width="100%"
                  className="d-block  highlight-image center "
                />
                <Carousel.Caption>
                  <h3 className="text-center">{h.name}</h3>
                  <p className="text-center">
                    {textEllipsis(h.description, 8)}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        )}

        <Container fluid>
          <Form>
            <h5 className="text-primary my-3">
              Busque seus produtos favoritos
            </h5>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="q"
                  placeholder="O que está procurando?"
                  // onKeyUp={handleQuery}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Col>
              <Col md={"auto"}>
                <Form.Check
                  custom
                  className="mt-2"
                  label="Exiba produtos de todas as cidades"
                  id="check"
                  checked={showAll}
                  onChange={(e) => {
                    setPage(1);
                    setShowAll(e.target.checked);
                  }}
                />
              </Col>
              <Col md={"auto"}>
                <Button
                  variant="link"
                  size="md"
                  title="Ordenar por preço"
                  onClick={() => {
                    if (ascOrDesc === "ASC") {
                      setAscOrDesc("DESC");
                    } else {
                      setAscOrDesc("ASC");
                    }

                    if (query.length > 0) {
                      const dados = {
                        nameProduto: query,
                        ...(!showAll &&
                          lastLocation && { city: lastLocation.name }),
                      };
                      searchProductInCityCategory(dados, page, ascOrDesc).then(
                        (data) => {
                          setResults(data);
                        }
                      );
                    }
                  }}
                >
                  {ascOrDesc === "ASC" ? <BiSortUp /> : <BiSortDown />}{" "}
                  <span>Ordenação</span>
                </Button>
              </Col>
            </Row>
          </Form>

          <hr />
          {renderResults()}
          {status === "loading" && <LoadingIndicator />}
        </Container>
      </div>

      <LocationModal
        show={showModalLocation}
        onHide={() => setShowModalLocation(false)}
        onSubmit={() => setShowModalLocation(false)}
      />

      <Footer />
    </>
  );
}

const fetchData = async (query, cb) => {
  const res = await searchLocation(query);
  cb(res);
};

const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 500);

function LocationModal({ show, onHide, onSubmit }) {
  const [data, setData] = useState([]);
  const { setLocation } = useLocationUser();
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (selectedLocation) {
      debouncedFetchData(selectedLocation, (res) => {
        setData(res.data);
      });
    }
  }, [selectedLocation]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Selecionar local</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control
            autoComplete="off"
            name="location"
            minLength="3"
            placeholder="Onde você está?"
            onChange={(e) => setSelectedLocation(e.target.value)}
          />
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              className="my-2"
              variant="outline-primary"
              onClick={() => {
                setLocation(null);
                onSubmit();
              }}
            >
              Limpar localidade
            </Button>
          </div>
        </Form.Group>
        <ListGroup className="pb-2" variant="flush">
          {data.map((city) => (
            <ListGroup.Item
              key={city.id}
              action
              onClick={() => {
                setLocation(city);
                onSubmit();
              }}
            >
              {city.name}, {city.uf}
            </ListGroup.Item>
          ))}
        </ListGroup>

        <Button onClick={onHide}>Fechar</Button>
      </Modal.Body>
    </Modal>
  );
}
