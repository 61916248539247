import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getUser, updateUser } from "../../services/api";
import { defaultDateToISO, isoDateToDefault } from "../../utils/formatters";
import { UpdateClientSchema } from "../../utils/validation_schemas";
import LoadingIndicator from "../LoadingIndicator";
import { Col, Form, Row, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import useNotification from "../../contexts/notification";
import handleChangeCep from '../../utils/validate_cep'

export default function UpdateClientForm({ clientId, onLoaded, onDone }) {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ status: "start" });
  const clearStatus = () => setStatus({ status: "start" });
  const { setMessage } = useNotification();

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      birthDate: "",
      telephone: "",
      email: "",
      complement: "",
      number: "",
      neighborhood: "",
      zip_code: "",
      street: "",
      password: "",
      city: "",
      state: "",
      show_password: false,
    },
    validationSchema: UpdateClientSchema,
    onSubmit: async (values) => {
      const client = {
        ...values,
        birthDate: defaultDateToISO(values.birthDate),
        type: "client",
      };

      const updated = await updateUser(client);
      onDone?.();

      setStatus(updated ? { status: "success" } : { status: "error" });
    },
  });

  useEffect(() => {
    getUser(clientId).then(
      (data) => {
        const [client] = data;
        formik.setValues({
          id: client.id,
          name: client.name,
          surname: client.surname,
          birthDate: isoDateToDefault(client.birthDate),
          telephone: client.telephone,
          email: client.email,
          complement: client.adress_id.complement,
          number: client.adress_id.number,
          neighborhood: client.adress_id.neighborhood,
          zip_code: client.adress_id.zip_code,
          street: client.adress_id.street,
          city: client.adress_id.city,
          state: client.adress_id.state,
          password: "",
          conf_password: "",
          show_password: false,
        });

        setLoading(false);
        if (onLoaded) {
          onLoaded();
        }
      },
      () =>
        setMessage({
          error: true,
          content: "Ocorreu um erro. Tente novamente mais tarde",
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, setMessage]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="pt-2">
      <h6 className="mb-3 text-primary">Meus Dados</h6>
      <Form.Group as={Row}>
        <Col lg={4}>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="Seu nome"
            value={formik.values.name}
            onChange={formik.handleChange}
            isValid={formik.touched.name && !formik.errors.name}
            isInvalid={formik.errors.name}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.name}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Sobrenome</Form.Label>
          <Form.Control
            type="text"
            name="surname"
            placeholder="Seu sobrenome"
            value={formik.values.surname}
            onChange={formik.handleChange}
            isValid={formik.touched.surname && !formik.errors.surname}
            isInvalid={formik.errors.surname}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.surname}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Data de nascimento</Form.Label>
          <Form.Control
            type="text"
            name="birthDate"
            as={InputMask}
            mask="99/99/9999"
            placeholder="Sua data de nascimento"
            onChange={formik.handleChange}
            value={formik.values.birthDate}
            isValid={formik.touched.birthDate && !formik.errors.birthDate}
            isInvalid={formik.errors.birthDate}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.birthDate}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
        <Form.Label>Telefone *</Form.Label>
          <Form.Control
            type="text"
            name="telephone"
            placeholder="Telefone"
            as={InputMask}
            mask="(99)99999-9999"
            onChange={formik.handleChange}
            value={formik.values.telephone}
            isValid={formik.touched.telephone && !formik.errors.telephone}
            isInvalid={formik.errors.telephone}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.telephone}
          </Form.Control.Feedback>
        </Col>
        <Col lg={8}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Seu email"
            value={formik.values.email}
            onChange={formik.handleChange}
            isValid={formik.touched.email && !formik.errors.email}
            isInvalid={formik.errors.email}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <h6 className="mt-3 mb-3 text-primary">Endereço</h6>
      <Form.Group as={Row}>
        <Col lg={4}>
          <Form.Label>CEP</Form.Label>
          <Form.Control
            as={InputMask}
            mask="99999-999"
            type="text"
            name="zip_code"
            placeholder="CEP"
            onChange={formik.handleChange}
            onBlur={() => handleChangeCep(formik)}
            value={formik.values.zip_code}
            isValid={formik.touched.zip_code && !formik.errors.zip_code}
            isInvalid={
              formik.errors.zip_code && formik.touched.zip_code
                ? true
                : false
            }
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.zip_code && formik.touched.zip_code ? true : false}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Estado</Form.Label>
          <Form.Control
            type="text"
            name="state"
            disabled
            onChange={formik.handleChange}
            value={formik.values.state}
            isValid={formik.touched.state && !formik.errors.state}
            isInvalid={formik.errors.state}
          ></Form.Control>
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.state}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Cidade *</Form.Label>
          <Form.Control
            type="text"
            name="city"
            disabled
            placeholder="Sua cidade"
            value={formik.values.city}
            onChange={formik.handleChange}
            isValid={formik.touched.city && !formik.errors.city}
            isInvalid={formik.errors.city}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.city}
          </Form.Control.Feedback>
        </Col>
        <Col lg={10}>
          <Form.Label>Rua</Form.Label>
          <Form.Control
            type="text"
            name="street"
            placeholder="Rua"
            value={formik.values.street}
            onChange={formik.handleChange}
            isValid={formik.touched.street && !formik.errors.street}
            isInvalid={formik.errors.street}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.street}
          </Form.Control.Feedback>
        </Col>
        <Col lg={2}>
          <Form.Label>Número</Form.Label>
          <Form.Control
            type="text"
            name="number"
            placeholder="Número"
            value={formik.values.number}
            onChange={formik.handleChange}
            isValid={formik.touched.number && !formik.errors.number}
            isInvalid={formik.errors.number}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.number}
          </Form.Control.Feedback>
        </Col>
        <Col lg={4}>
          <Form.Label>Bairro</Form.Label>
          <Form.Control
            type="text"
            name="neighborhood"
            placeholder="Bairro"
            value={formik.values.neighborhood}
            onChange={formik.handleChange}
            isValid={
              formik.touched.neighborhood && !formik.errors.neighborhood
            }
            isInvalid={formik.errors.neighborhood}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.neighborhood}
          </Form.Control.Feedback>
        </Col>
        <Col lg={8}>
          <Form.Label>Complemento</Form.Label>
          <Form.Control
            type="text"
            name="complement"
            placeholder="Complemento"
            value={formik.values.complement}
            onChange={formik.handleChange}
            isValid={formik.touched.complement && !formik.errors.complement}
            isInvalid={formik.errors.complement}
          />
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.complement}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <h6 className="mb-3 text-primary">Senha</h6>
      <Form.Group as={Row}>
        <Col lg={6}>
          <Form.Label>Nova senha</Form.Label>
          <Form.Control
            type={formik.values.show_password ? "text" : "password"}
            name="password"
            placeholder="Nova senha"
            value={formik.values.password}
            onChange={formik.handleChange}
            isValid={formik.touched.password && !formik.errors.password}
            isInvalid={formik.errors.password}
          />
          <Form.Text className="text-muted">
            A senha deve ter no mínimo 8 caracteres entre números e letras.
          </Form.Text>
          <Form.Control.Feedback tooltip type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
          <Form.Check
            id="show-password-2"
            name="show_password"
            className="mb-2"
            type="switch"
            label="Mostrar senha"
            onChange={formik.handleChange}
            checked={formik.values.show_password}
            custom
          />
        </Col>

        {/*
        // TODO : Confirmação de Senha
        <Col lg={6}>
          <Form.Label>Confirmar senha</Form.Label>
          <Form.Control
            type={formik.values.show_password ? "text" : "password"}
            name="conf_password"
            placeholder="Confirmar senha"
            value={formik.values.conf_password}
            onChange={formik.handleChange}
          />
        </Col> */}
      </Form.Group>
      {status.status === "success" && (
        <SuccessAlert msg="Perfil atualizado com sucesso." onClose={clearStatus} />
      )}
      {status.status === "error" && (
        <ErrorAlert msg="Ocorreu um erro, por favor tente novamente mais tarde." onClose={clearStatus} />
      )}
      <Form.Group as={Row}>
        <Col xs="auto">
          <Button variant="primary" type="submit">
            Atualizar
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
}
