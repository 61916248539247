import { createContext, useContext } from "react";

export const LocationContext = createContext({});

export default function useLocation() {
  const context = useContext(LocationContext);

  if (!context) {
    throw new Error("useLocation must be used within an LocationProvider.");
  }

  return context;
}
