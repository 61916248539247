import { FaStar } from "react-icons/fa";


export function RatingStars({ value, size }) {
    const activeColor = "yellow";
    const inactiveColor = "gray";
    const starSize = size === "sm" ? 16 : 32;
  
    const Star = ({ rating }) => {
      return (
        <FaStar
          size={starSize}
          color={value >= rating ? activeColor : inactiveColor}
        />
      );
    };
  
    return (
      <>
        {[1, 2, 3, 4, 5].map((n) => (
          <Star key={n} rating={n} />
        ))}
      </>
    );
  }
  