import { Redirect } from "react-router-dom";
import useAuth from "../../contexts/auth";

export default function Entrepreneur() {
  const { user } = useAuth();

  if (user && user.type !== "entrepreneur") {
    return <Redirect to="/" />;
  } else {
    return <Redirect to="/empreendedor/dashboard" />;
  }
}
