import RedirectContainer from "../../../components/RedirectContainer";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  InputGroup,
} from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoIosAddCircle } from "react-icons/io";
import { BsFillGridFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { getAllGifts, deleteGift, searchGift } from "../../../services/api";
import AddGiftVoucherForm from "../../../components/AddGiftVoucherForm";
import UpdateGiftVoucherForm from "../../../components/UpdateGiftVoucherForm";
import PaginationComponent from "../../../components/Pagination";
import useNotification from "../../../contexts/notification";
import { currency } from "../../../utils/mappers";

export default function Giftvoucher() {
  const [dataGifts, setDataGifts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [selectedGiftVoucher, setSelectedGiftVoucher] = useState(null);
  const [page, setPage] = useState(1);
  const [queryGift, setQueryGift] = useState("");
  const { setMessage } = useNotification();

  const refresh = () => {
    setIsLoading(true);
    const error = {
      error: true,
      content: "Erro ao recarregar. Tente novamente mais tarde",
    };

    if (queryGift) {
      searchGift(queryGift, page).then(
        (data) => {
          setDataGifts(data[0]);
          setIsLoading(false);
        },
        () => setMessage(error)
      );
    } else {
      getAllGifts(page).then(
        (data) => {
          setDataGifts(data.data);
          setIsLoading(false);
        },
        () => setMessage(error)
      );
    }
  };

  useEffect(() => {
    if (queryGift.length === 0) {
      getAllGifts(page).then(
        (data) => {
          setDataGifts(data.data);
          setIsLoading(false);
        },
        () =>
          setMessage({
            error: true,
            content: "Um erro ocorreu. Tente novamente mais tarde",
          })
      );
    } else {
      // setPage(1);
      searchGift(queryGift, page).then(
        (data) => {
          setDataGifts(data[0]);
        },
        () =>
          setMessage({
            error: true,
            content: `Nenhum resultado encontrado para ${queryGift}`,
          })
      );
    }
  }, [page, queryGift, setMessage]);

  useEffect(() => {
    if (selectedGiftVoucher) {
      setModalShowEdit(true);
    }
  }, [selectedGiftVoucher]);

  // function handleQueryGift(e) {
  //   e.preventDefault();
  //   if(queryGift !== "") {
  //     searchGift(queryGift, page).then(
  //       (data) => {
  //         setDataGifts(data[0]);
  //       },
  //       () =>
  //         setMessage({
  //           error: true,
  //           content: `Nenhum resultado encontrado para ${queryGift}`
  //         })
  //     );
  //   }
  // }

  function ModalAddGiftVoucher(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adicionar Vale Presente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddGiftVoucherForm onSubmit={props.onHide} />
        </Modal.Body>
      </Modal>
    );
  }

  function ModalEditGiftVoucher(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Editar Vale Presente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateGiftVoucherForm
            giftId={selectedGiftVoucher}
            onSubmit={props.onHide}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <RedirectContainer role="entrepreneur">
        <Container fluid>
          <div>
            <SidebarPanelNavigation isAdmin={false} />

            <div className="content-panel">
              {isLoading && <LoadingIndicator />}
              {!isLoading && (
                <>
                  <div className="py-3">
                    <h5 className="text-primary mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
                      <BsFillGridFill className="mr-2" />
                      Vale Presente
                    </h5>
                  </div>

                  <Row className="d-flex justify-content-between">
                    <Col lg={4}>
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="my-3 d-flex" controlId="">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              placeholder="Buscar vale-presentes"
                              required
                              onChange={(e) => setQueryGift(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Form>
                    </Col>

                    <Col lg={3}>
                      <div className="d-flex justify-content-end align-items-center mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0">
                        <div>
                          <Button
                            onClick={() => setModalShowAdd(true)}
                            className="btn btn-primary d-flex align-items-center text-white mr-3"
                          >
                            <span className="mr-2">ADICIONAR</span>
                            <IoIosAddCircle style={{ fontSize: "1.6em" }} />
                          </Button>

                          <ModalAddGiftVoucher
                            show={modalShowAdd}
                            onHide={() => {
                              setModalShowAdd(false);
                              refresh();
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <Table striped bordered responsive size="sm">
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>Preço</th>
                            <th>Quantidade</th>
                            <th style={{width: "200px"}}>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGifts.data.map((gift) => (
                            <tr key={gift.id}>
                              <td>
                                {gift.name.length <= 30
                                  ? gift.name
                                  : gift.name.substr(0, 18) + " ..."}
                              </td>
                              <td> {currency(gift.price)}</td>
                              <td>{gift.amount}</td>
                              <td>
                                <Row noGutters className="justify-content-between">
                                  <Col xs={"auto"}>
                                    <Button
                                      onClick={() => {
                                        setSelectedGiftVoucher(gift.id);
                                      }}
                                      size="sm"
                                      variant="warning"
                                      className="text-white d-flex align-items-center mr-3"
                                    >
                                      <MdEdit style={{ fontSize: "1.4em" }} />
                                      Editar
                                    </Button>
                                  </Col>
                                  <Col xs={"auto"}>
                                    <Button
                                      onClick={async () => {
                                        let conf = window.confirm(
                                          "Tem certeza que deseja deletar este vale presente ?"
                                        );

                                        if(conf) {
                                          await deleteGift(
                                            gift.id
                                          ).then(
                                            () => {
                                              setMessage({
                                                error: false,
                                                content:
                                                  "Vale presente deletado com sucesso.",
                                                // eslint-disable-next-line no-mixed-operators
                                              })
                                              refresh();
                                            },
                                            () => {
                                              setMessage({
                                                error: true,
                                                content:
                                                  "Ocorreu um erro, tente novamente mais tarde.",
                                              });
                                            }
                                          )
                                        }
                                      }}
                                      size="sm"
                                      variant="danger"
                                      className="text-white d-flex align-items-center"
                                    >
                                      <MdDelete style={{ fontSize: "1.4em" }} />
                                      Excluir
                                    </Button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {dataGifts.data.length === 0 && (
                          <tfoot>
                            <tr>
                              <td colSpan={5}>
                                <h5 className="text-muted text-center my-5">
                                  Sem resultados
                                </h5>
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </Table>
                      <div className="d-flex justify-content-end">
                        <PaginationComponent
                          pagination={dataGifts}
                          onClickPage={(page) => {
                            setPage(page);
                            if (queryGift.length > 0) {
                              searchGift(queryGift, page).then(
                                (data) => {
                                  setDataGifts(data[0]);
                                },
                                () =>
                                  setMessage({
                                    error: true,
                                    content:
                                      "Um erro ocorreu. Tente novamente mais tarde",
                                  })
                              );
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>
        <ModalEditGiftVoucher
          show={modalShowEdit}
          onHide={() => {
            setModalShowEdit(false);
            setSelectedGiftVoucher(null);
            refresh();
          }}
        />
      </RedirectContainer>
    </>
  );
}
