import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import {
  getAllCategories,
  getProduct,
  updateProduct,
} from "../../services/api";
import LoadingIndicator from "../LoadingIndicator";
import SuccessAlert from "../SuccessAlert";
import ErrorAlert from "../ErrorAlert";
import { UpdateProductSchema } from "../../utils/validation_schemas";
import { formatDate } from "../../utils/formatters";
import useNotification from "../../contexts/notification";

export default function UpdateProductForm({ productId, onSubmit }) {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("start");
  const [categories, setCategories] = useState([]);
  const [promotionCategory, setPromotionCategory] = useState([]);
  const clearStatus = () => setStatus({ status: "start" });
  const [submitting, setSubmitting] = useState(false);
  const { setMessage } = useNotification();

  useEffect(() => {
    getAllCategories().then((data) => {
      let promo = data.filter(cat => cat.name === "Promoções").map(cat => cat)[0];
      setPromotionCategory(promo);
      let cate = data.filter((cate) => (cate.name !== "Promoções" && cate.name !== "Vale Presentes") ? true : false).map(cate => cate);
      setCategories(cate);
    });
  }, []);

  useEffect(() => {
    getProduct(productId).then((data) => {
      const { product } = data;
      formik.setValues({
        id: product.id,
        name: product.name,
        description: product.description,
        confidential_price: product.confidential_price ? "1" : "0",
        image: undefined,
        price: product.price,
        amount: product.amount,
        isHighlighted: String(product.isHighlighted),
        isPromotion: String(product.isPromotion),
        isWholesale: product.wholesale_price ? "1" : "0",
        wholesale_price: product.wholesale_price || "",
        min_quantity_wholesale: product.min_quantity_wholesale || "",
        category_id: product.category_id,
        discount: product.discount || "",
        date_expiration: product.date_expiration || "",
      });
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      image: undefined,
      confidential_price: "0",
      price: "",
      amount: "",
      isHighlighted: "0",
      isPromotion: "0",
      isWholesale: "0",
      min_quantity_wholesale: "",
      category_id: undefined,
      discount: undefined,
      date_expiration: "",
      wholesale_price: ""
    },
    validationSchema: UpdateProductSchema,
    onSubmit: async (values) => {
      if (values.image === undefined) {
        delete values.image;
      }
      if (values.isWholesale === "0") {
        delete values.wholesale_price;
      }
      setSubmitting(true);
      const updated = await updateProduct(values);
      setMessage({
        error: false,
        content: "Produto atualizado com sucesso",
      });
      setSubmitting(false);
      onSubmit?.();
      setStatus(updated ? "success" : "error");
    },
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="mt-3">
      <h6 className="mb-3 text-primary">Dados do produto</h6>
      <Form.Group className="mt-1 mb-4" controlId="formProductData">
        <Row>
          <Col xl={8} md={8}>
            <Form.Label>Nome do produto</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="name"
              placeholder="Nome do produto"
              required
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>

          <Col xl={4} md={4} sm={5}>
            <Form.Label>Preço *</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="price"
              min="0"
              placeholder="Preço"
              onChange={formik.handleChange}
              value={formik.values.price}
              isValid={formik.touched.price && !formik.errors.price}
              isInvalid={formik.errors.price}
              disabled={formik.values.confidential_price === "1"}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.price}
            </Form.Control.Feedback>
            <Form.Check
              className="my-1"
              type="checkbox"
              name="confidential_price"
              label="Preço através de consulta"
              onChange={(e) => formik.setFieldValue("confidential_price", e.target.checked ? "1" : "0")}
              checked={formik.values.confidential_price === "1"}
            />
          </Col>


          <Col xl={12} md={12} sm={12}>
            <Form.Label>Descrição *</Form.Label>
            <Form.Control
              className="my-1"
              as="textarea"
              rows={3}
              name="description"
              placeholder="Descrição do produto"
              onChange={formik.handleChange}
              value={formik.values.description}
              isValid={formik.touched.description && !formik.errors.description}
              isInvalid={formik.errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </Col>

          <Col xl={6} md={6} sm={6} className="mt-1">
            <Form.Label>Imagem do produto*</Form.Label>
            <Form.File
              id=""
              accept="image/png, image/jpeg"
              name="image"
              lang="pt"
              label={
                formik.values.image
                  ? formik.values.image.name
                  : "Escolher arquivo"
              }
              onChange={(e) =>
                formik.setFieldValue("image", e.currentTarget.files[0])
              }
              isInvalid={formik.errors.image}
              feedback={formik.errors.image}
              custom
            />
          </Col>

          <Col xl={6} md={6} sm={6}>
            <Form.Label>Quantidade *</Form.Label>
            <Form.Control
              className="my-1"
              type="number"
              name="amount"
              min="0"
              placeholder="Quantidade"
              onChange={formik.handleChange}
              value={formik.values.amount}
              isValid={formik.touched.amount && !formik.errors.amount}
              isInvalid={formik.errors.amount}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.amount}
            </Form.Control.Feedback>
          </Col>
          <Col xl={6} md={6} sm={6}>
            <Form.Label>Categoria *</Form.Label>
            <Form.Control
              as="select"
              name="category_id"
              isValid={formik.touched.category_id && !formik.errors.category_id}
              isInvalid={formik.errors.category_id}
              onChange={formik.handleChange}
              value={formik.values.category_id}
              autoComplete="off"
              disabled={formik.values.isPromotion === "1"}
              custom
            >
              {formik.values.isPromotion === "1" && (
                <option>Promoções</option>
              )}

              <option>Selecione uma categoria</option>

              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Col>

          <Col xs={6} lg={6} mg={6}>
            <Form.Label className="mr-3">Promoção? *</Form.Label>
            <div className="d-flex mt-2">
              <Form.Check
                custom
                id="promotion-check-yes"
                name="isPromotion"
                type="radio"
                checked={formik.values.isPromotion === "1"}
                value="1"
                label="Sim"
                onChange={(e) => {
                  formik.setFieldValue("isPromotion", e.target.value);
                  formik.setFieldValue("category_id", promotionCategory.id);
                }}
              />

              <Form.Check
                custom
                className="ml-2"
                id="promotion-check-no"
                name="isPromotion"
                type="radio"
                checked={formik.values.isPromotion === "0"}
                value="0"
                label="Não"
                onChange={(e) => {
                  formik.setFieldValue("isPromotion", e.target.value);
                  formik.setFieldValue("category_id", undefined);
                }}
              />
            </div>
          </Col>


          <Col xs={6} lg={6} mg={6}>
            <Form.Label className="mr-3">Atacado? *</Form.Label>
            <div className="d-flex mt-2">
              <Form.Check
                custom
                id="wholesale-check-yes"
                name="isWholesale"
                type="radio"
                checked={formik.values.isWholesale === "1"}
                value="1"
                label="Sim"
                onChange={formik.handleChange}
              />

              <Form.Check
                custom
                className="ml-2"
                id="wholesale-check-no"
                name="isWholesale"
                type="radio"
                checked={formik.values.isWholesale === "0"}
                value="0"
                label="Não"
                onChange={formik.handleChange}
              />
            </div>
          </Col>
          {formik.values.isPromotion === "1" && (
            <Col xl={6} md={6} sm={6}>
              <Form.Label>Desconto (%) *</Form.Label>
              <Form.Control
                className="my-1"
                type="number"
                name="discount"
                placeholder="Valor em porcentagem(%)"
                onChange={formik.handleChange}
                value={formik.values.discount}
                isValid={formik.touched.discount && !formik.errors.discount}
                isInvalid={formik.errors.discount}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.discount}
              </Form.Control.Feedback>
            </Col>
          )}
          {formik.values.isPromotion === "1" && (
            <Col xl={6} md={6} sm={6}>
              <Form.Label>Data de expiração *</Form.Label>
              <Form.Control
                className="my-1"
                type="date"
                name="date_expiration"
                min={formatDate(new Date())}
                placeholder="Data de expiração"
                onChange={formik.handleChange}
                value={formik.values.date_expiration}
                isValid={
                  formik.touched.date_expiration &&
                  !formik.errors.date_expiration
                }
                isInvalid={formik.errors.date_expiration}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.date_expiration}
              </Form.Control.Feedback>
            </Col>
          )}
          {formik.values.isWholesale === "1" && (
            <Col xl={6} md={6} sm={6}>
              <Form.Label>Preço de atacado *</Form.Label>
              <Form.Control
                className="my-1"
                type="number"
                name="wholesale_price"
                placeholder="Preço de atacado"
                onChange={formik.handleChange}
                value={formik.values.wholesale_price}
                isValid={
                  formik.touched.wholesale_price &&
                  !formik.errors.wholesale_price
                }
                isInvalid={formik.errors.wholesale_price}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.wholesale_price}
              </Form.Control.Feedback>
            </Col>
          )}
          {formik.values.isWholesale === "1" && (
            <Col xl={6} md={6} sm={6}>
              <Form.Label>Quantidade mínima *</Form.Label>
              <Form.Control
                className="my-1"
                type="number"
                name="min_quantity_wholesale"
                placeholder="Quantidade mínima"
                onChange={formik.handleChange}
                value={formik.values.min_quantity_wholesale}
                isValid={
                  formik.touched.min_quantity_wholesale &&
                  !formik.errors.min_quantity_wholesale
                }
                isInvalid={formik.errors.min_quantity_wholesale}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.min_quantity_wholesale}
              </Form.Control.Feedback>
            </Col>
          )}
        </Row>
        {status === "success" && (
          <SuccessAlert msg="Produto atualizado" onClose={clearStatus} />
        )}

        {status === "error" && (
          <ErrorAlert msg="Um erro ocorreu." onClose={clearStatus} />
        )}
        <Button
          className="my-2"
          variant="primary"
          type="submit"
          disabled={submitting}
        >
          Atualizar
        </Button>
      </Form.Group>
    </Form>
  );
}
