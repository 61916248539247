// role: client, entrepreneur, admin

import { Redirect } from "react-router-dom";
import useAuth from "../../contexts/auth";

export default function RedirectContainer(props) {
  const { user } = useAuth();
  if (user && user.type === props.role) {
    return <>{props.children}</>;
  } else {
    return <Redirect to={props.to} />;
  }
}

RedirectContainer.defaultProps = {
  to: "/",
};
