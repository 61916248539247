import { Modal, Button } from "react-bootstrap";

export default function ConfirmDeleteAccountModal(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header>
        <Modal.Title>Deseja apagar sua conta?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Não
        </Button>
        <Button variant="primary" onClick={props.onDelete}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
