import {
  clearObject,
  defaultDateToISO,
  unformat,
} from "./formatters";

function formData(payload) {
  const data = new FormData();
  Object.keys(payload).forEach((key) => data.append(key, payload[key]));
  return data;
}

export function createSignupClientFormData(client) {
  const birthDate = defaultDateToISO(client.birthDate);
  const cpf = unformat(client.cpf);
  const payload = { ...client, birthDate, cpf };
  return formData(payload);
}

export function createSignupAdminFormData(admin) {
  const cpf = unformat(admin.cpf);

  const payload = { ...admin, cpf };

  return formData(payload);
}

export function createSignupEntrepreneurFormData(entrepreneur) {
  const cpf_cnpj = unformat(entrepreneur.cpf_cnpj);
  const payment_method = entrepreneur.payment_method.join(" ");
  const payload = { ...entrepreneur, cpf_cnpj, payment_method };

  return formData(payload);
}

export function createLoginFormData(loginData) {
  const identifier = unformat(loginData.cpf_cnpj);
  const payload = { ...loginData, cpf: identifier, cpf_cnpj: identifier };

  return formData(payload);
}

export function createUpdateEntrepreneurFormData(updateData) {
  const payment_method = updateData.payment_method.join(" ");
  const payload = { ...updateData, payment_method };

  return formData(payload);
}

export function createProductFormData(productData) {
  if (productData.isPromotion === "0") {
    clearObject(productData, "date_expiration", "discount");
  }

  if (productData.isWholesale === "0") {
    clearObject(productData, "wholesale_price", "min_quantity_wholesale");
  }

  const payload = {
    ...productData,
    category_id: Number(productData.category_id),
    isHighlighted: Number(productData.isHighlighted),
    confidential_price: (productData.confidential_price),
    isPromotion: Number(productData.isPromotion),
  };

  return formData(payload);
}

export function createGiftVoucherFormData(giftVoucherData) {

  const payload = {
    ...giftVoucherData,
    name: String(giftVoucherData.name),
    price: Number(giftVoucherData.price),
    description: String(giftVoucherData.description),

    amount: Number(giftVoucherData.amount),
    category_id: Number(giftVoucherData.category_id),
  };
  return formData(payload);
}
