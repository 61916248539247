import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Overlay,
  Tooltip,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { Redirect } from "react-router";
import { FaCopy, FaPlus, FaMinus, FaTrashAlt } from "react-icons/fa";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import useAuth from "../../contexts/auth";
import useCart from "../../contexts/cart";
import qs from "qs";
import { useFormik } from "formik";
import { calculateTotal, currency, discountIsValid } from "../../utils/mappers";
import {
  CheckoutLoggedSchema,
  CheckoutSchema,
} from "../../utils/validation_schemas";

import LoadingIndicator from "../../components/LoadingIndicator";

import {
  createOrder,
  getCheckoutEntrpreneurInfo,
  getEntrepreneur,
  getImage,
  getUser,
} from "../../services/api";
import handleChangeCep from "../../utils/validate_cep";
import useNotification from "../../contexts/notification";

export default function Checkout({ location }) {
  const { user } = useAuth();
  const { clearCart, cartData } = useCart();
  const ent = qs.parse(location.search, { ignoreQueryPrefix: true }).ent;
  const entrepreneur = cartData[ent];
  const [dataEntrepreneur, setDataEntrepreneur] = useState({});
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const router = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { setMessage } = useNotification();

  const { removeFromCart, addQuantityProduct, removeQuantityProduct } =
    useCart();

  useEffect(() => {
    if (entrepreneur) {
      getCheckoutEntrpreneurInfo(entrepreneur.id).then((res) => {
        setDataEntrepreneur(res[0]);
        setIsLoading(false);
      });
    }
  }, [entrepreneur]);

  const formik = useFormik({
    initialValues: {
      nome: "",
      delivery_method: undefined,
      other_adress: false,
      rua: "",
      bairro: "",
      numero: "",
      pontoReferencia: "",
      payment_method: undefined,
      cep: "",
      telephone: "",
      city: "",
      state: "",
    },
    validationSchema: !user ? CheckoutSchema : CheckoutLoggedSchema,
    onSubmit: async (values) => {
      setModalLoading(true);
      if (user) {
        if (user.type === "client") {
          const [data] = await getUser(user.id);
          if (!values.other_adress) {
            values = {
              ...values,
              id: user.id,
              telephone: data.telephone,
              nome: `${data.name} ${data.surname}`,
              rua: data.adress_id.street,
              bairro: data.adress_id.neighborhood,
              numero: data.adress_id.number,
              cidade: data.adress_id.city,
              estado: data.adress_id.state,
              cep: data.adress_id.zip_code,
            };
          } else {
            values = {
              ...values,
              id: user.id,
              telephone: data.telephone,
              nome: `${data.name} ${data.surname}`,
              cidade: values.city,
              estado: values.state,
            };
          }
        } else if (user.type === "entrepreneur") {
          const [ent, address] = await getEntrepreneur(user.id);
          if (!values.other_adress) {
            values = {
              ...values,
              id: user.id,
              telephone: ent.telephone,
              nome: ent.name_establishment,
              rua: address.street,
              bairro: address.neighborhood,
              numero: address.number,
              cidade: address.city,
              estado: address.state,
              cep: address.zip_code,
            };
          } else {
            values = {
              ...values,
              id: user.id,
              telephone: ent.telephone,
              nome: ent.name_establishment,
              cidade: values.city,
              estado: values.state,
            };
          }
        }
      } else {
        values = { ...values, cidade: values.city, estado: values.state };
      }

      const payload = {
        pedido: {
          user: {
            id: user?.id || "",
            name: values.nome,
            telephone: values.telephone,
            endereco: `${values.rua}, ${values.numero}, ${values.cidade}, ${values.estado}, ${values.cep}`,
            pontoReferencia: values.pontoReferencia,
          },
          lojas: {
            idLoja: entrepreneur.id,
            whatsappLoja: "55" + dataEntrepreneur.telephone,
            formaEntrega: values.delivery_method,
            formaPagamento: values.payment_method,
            produtosPedido: Object.keys(entrepreneur.products).map((key) => ({
              idProduto: entrepreneur.products[key].id,
              quant: entrepreneur.products[key].quantity,
              type: entrepreneur.products[key].type,
            })),
          },
        },
      };
        await createOrder(payload)
      .then((link) => {
        setMessage({
          error: false,
          content: "Pedido realizado com sucesso.",
        });
        clearCart(entrepreneur.id);
        window.open(link[0], "_blank");
        router.push("/");
      })
      .catch((err) => {
        setMessage({
          error: true,
          content: err.response.data.msg,
        });
      })
      .finally(() => {
        setModalLoading(false);
      });
    },
  });

  if (
    cartData.length === 0 ||
    !ent ||
    !entrepreneur ||
    user?.type === "admin"
  ) {
    return <Redirect to="/" />;
  }

  function renderQuantity(entrepreneurID, product) {
    return (
      <td>
        <Row className="row-cols-md-4" noGutters>
          <Col xs={6} className="order-md-2 d-flex justify-content-center align-items-center">
            {product.quantity}
          </Col>
          <Col xs={6} className="order-md-4 d-flex justify-content-center">
            <Button
              variant="danger"
              size="sm"
              onClick={() =>
                removeFromCart(
                  entrepreneurID,
                  product
                )
              }
            >
              <FaTrashAlt size="16" />
            </Button>
          </Col>
          <Col xs={6}  className="order-md-1 d-flex justify-content-center">
            <Button
              size="sm"
              onClick={() =>
                removeQuantityProduct(
                  entrepreneurID,
                  product
                )
              }
            >
              <FaMinus size="8" />
            </Button>
          </Col>
          <Col xs={6} className="order-md-3 d-flex justify-content-center">
            <Button
              size="sm"
              onClick={() =>
                addQuantityProduct(
                  entrepreneurID,
                  product
                )
              }
            >
              <FaPlus size="8" />
            </Button>
          </Col>
        </Row>
      </td>
    )
  }

  function renderProducts() {
    const items = [];

    for (let item in entrepreneur.products) {
      const product = entrepreneur.products[item];
      let price, priceTag;
      if (
        product.min_quantity_wholesale !== null &&
        product.wholesale_price !== null &&
        product.quantity >= product.min_quantity_wholesale
      ) {
        price = product.wholesale_price;
        priceTag = "Atacado";
      } else {
        const hasDiscount = discountIsValid(product.discount, product.date_expiration);
        price = hasDiscount ? product.priceDiscount : product.price;
        priceTag = hasDiscount ? "Promoção" : "";
      }

      items.push(
        <tr key={item}>
          <td>
            {product.name}
            {priceTag.length > 0 && (
              <small className="ml-2">({priceTag})</small>
            )}
          </td>
          {renderQuantity(entrepreneur.id, product)}  
          <td>
            <span className="text-primary">
              {currency(price * product.quantity)}
            </span>
          </td>
        </tr>
      );
    }

    return items;
  }

  return (
    <Container className="py-5">
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <h4 className="text-primary">Finalizando compra:</h4>
          <Row className="row-cols-1 row-cols-md-2">
            <Col xs="auto">
              <img
                src={getImage(dataEntrepreneur.logo_store)}
                alt="Logo store"
                height="128px"
              />
            </Col>
            <Col xs="auto">
              <h5>{dataEntrepreneur.name_establishment}</h5>
              <span className="text-muted">
                {dataEntrepreneur.address.city},{" "}
                {dataEntrepreneur.address.state} <br />
                {dataEntrepreneur.address.neighborhood}
                <br />
                {dataEntrepreneur.address.street},{" "}
                {dataEntrepreneur.address.number}
                <br />
                {dataEntrepreneur.address.zip_code},{" "}
                {dataEntrepreneur.address.complement}
                <Button
                  ref={target}
                  size="sm"
                  className="p-1 mx-1"
                  variant="outline-primary"
                  title="Copiar endereço"
                  onClick={() => {
                    setShow((state) => !state);
                    setTimeout(() => setShow(false), 3000);
                    navigator.clipboard.writeText(
                      `${dataEntrepreneur.name_establishment}\n${dataEntrepreneur.address.city}, ${dataEntrepreneur.address.state}\n${dataEntrepreneur.address.neighborhood}\n${dataEntrepreneur.address.street}, ${dataEntrepreneur.address.number}\n${dataEntrepreneur.address.zip_code}, ${dataEntrepreneur.address.complement}`
                    );
                  }}
                >
                  <FaCopy />
                </Button>
                <Overlay target={target.current} show={show} placement="right">
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      Copiado!
                    </Tooltip>
                  )}
                </Overlay>
              </span>
            </Col>
          </Row>

          <Table striped bordered responsive size="sm" className="mt-5">
            <thead>
              <tr>
                <th>Produto</th>
                <th>Quantidade</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>{renderProducts()}</tbody>
            <tfoot>
              <tr style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                <td colSpan="2">Total:</td>
                <td>
                  <span className="text-primary">
                    {calculateTotal(entrepreneur.products)}
                  </span>
                </td>
              </tr>
            </tfoot>
          </Table>

          <hr />

          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row className="row-cols-1 row-cols-md-2">
              <Col>
                <h6 className="pb-3 text-primary">Método de entrega</h6>
                <Form.Group>
                  <Form.Check
                    id="custom-checkbox-entrega"
                    custom
                    name="delivery_method"
                    type="radio"
                    value="Delivery"
                    label="Quero entrega"
                    onChange={formik.handleChange}
                    disabled={
                      !dataEntrepreneur.delivery_method.includes("Delivery")
                    }
                    checked={formik.values.delivery_method === "Delivery"}
                    isValid={
                      formik.touched.delivery_method &&
                      !formik.errors.delivery_method
                    }
                    isInvalid={
                      formik.errors.delivery_method &&
                        formik.touched.delivery_method
                        ? true
                        : false
                    }
                  />

                  {formik.values.delivery_method === "Delivery" && user && (
                    <>
                      <Form.Group controlId="other_adress">
                        <Form.Check
                          custom
                          type="checkbox"
                          label="Entregar em outro endereço"
                          name="other_adress"
                          onChange={formik.handleChange}
                          checked={formik.values.other_adress}
                        />
                      </Form.Group>

                      {formik.values.other_adress && (
                        <>
                          <Form.Group>
                            <Form.Control
                              as={InputMask}
                              mask="99999-999"
                              className="my-1"
                              type="text"
                              name="cep"
                              placeholder="Seu CEP"
                              onChange={formik.handleChange}
                              onBlur={() => handleChangeCep(formik)}
                              value={formik.values.cep}
                              isValid={formik.touched.cep && !formik.errors.cep}
                              isInvalid={
                                formik.errors.cep && formik.touched.cep
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.cep}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="state"
                              disabled
                              onChange={formik.handleChange}
                              value={formik.values.state}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="city"
                              disabled
                              onChange={formik.handleChange}
                              value={formik.values.city}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="bairro"
                              placeholder="Seu bairro"
                              required
                              value={formik.values.bairro}
                              onChange={formik.handleChange}
                              isValid={
                                formik.touched.bairro && !formik.errors.bairro
                              }
                              isInvalid={
                                formik.errors.bairro && formik.touched.bairro
                                  ? true
                                  : false
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.bairro}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="rua"
                              placeholder="Sua rua"
                              required
                              value={formik.values.rua}
                              onChange={formik.handleChange}
                              isValid={formik.touched.rua && !formik.errors.rua}
                              isInvalid={
                                formik.errors.rua && formik.touched.rua
                                  ? true
                                  : false
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.rua}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="numero"
                              placeholder="Número da casa"
                              required
                              value={formik.values.numero}
                              onChange={formik.handleChange}
                              isInvalid={
                                formik.errors.numero && formik.touched.numero
                                  ? true
                                  : false
                              }
                              isValid={
                                formik.touched.numero && !formik.errors.numero
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.numero}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="pontoReferencia"
                              placeholder="Ponto de referência (opcional)"
                              value={formik.values.pontoReferencia}
                              onChange={formik.handleChange}
                              isInvalid={
                                formik.errors.pontoReferencia &&
                                  formik.touched.pontoReferencia
                                  ? true
                                  : false
                              }
                              isValid={
                                formik.touched.pontoReferencia &&
                                !formik.errors.pontoReferencia
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.pontoReferencia}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </>
                      )}

                      {!formik.values.other_adress && (
                        <>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="pontoReferencia"
                              placeholder="Ponto de referência (opcional)"
                              value={formik.values.pontoReferencia}
                              onChange={formik.handleChange}
                              isInvalid={
                                formik.errors.pontoReferencia &&
                                  formik.touched.pontoReferencia
                                  ? true
                                  : false
                              }
                              isValid={
                                formik.touched.pontoReferencia &&
                                !formik.errors.pontoReferencia
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.pontoReferencia}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </>
                      )}
                    </>
                  )}

                  {formik.values.delivery_method === "Delivery" && !user && (
                    <>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="nome"
                          placeholder="Seu nome"
                          required="Campo necessário"
                          value={formik.values.nome}
                          onChange={formik.handleChange}
                          isValid={formik.touched.nome && !formik.errors.nome}
                          isInvalid={formik.errors.nome && formik.touched.nome}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors && formik.errors.nome}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          as={InputMask}
                          mask="(99)99999-9999"
                          className="my-1"
                          type="text"
                          name="telephone"
                          placeholder="Telefone"
                          onChange={formik.handleChange}
                          value={formik.values.telephone}
                          isValid={
                            formik.touched.telephone && !formik.errors.telephone
                          }
                          isInvalid={
                            formik.errors.telephone && formik.touched.telephone
                              ? true
                              : false
                          }
                        />
                        <Form.Control.Feedback tooltip type="invalid">
                          {formik.errors.telephone}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          as={InputMask}
                          mask="99999-999"
                          className="my-1"
                          type="text"
                          name="cep"
                          placeholder="Seu CEP"
                          onChange={formik.handleChange}
                          onBlur={() => handleChangeCep(formik)}
                          value={formik.values.cep}
                          isValid={formik.touched.cep && !formik.errors.cep}
                          isInvalid={formik.errors.cep && formik.touched.cep}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.cep}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="state"
                          disabled
                          onChange={formik.handleChange}
                          value={formik.values.state}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="city"
                          disabled
                          onChange={formik.handleChange}
                          value={formik.values.city}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="bairro"
                          placeholder="Seu bairro"
                          required
                          value={formik.values.bairro}
                          onChange={formik.handleChange}
                          isValid={
                            formik.touched.bairro && !formik.errors.bairro
                          }
                          isInvalid={
                            formik.errors.bairro && formik.touched.bairro
                              ? true
                              : false
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.bairro}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="rua"
                          placeholder="Sua rua"
                          required
                          value={formik.values.rua}
                          onChange={formik.handleChange}
                          isValid={formik.touched.rua && !formik.errors.rua}
                          isInvalid={
                            formik.errors.rua && formik.touched.rua
                              ? true
                              : false
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.rua}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="numero"
                          placeholder="Número da casa"
                          required
                          value={formik.values.numero}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.errors.numero && formik.touched.numero
                              ? true
                              : false
                          }
                          isValid={
                            formik.touched.numero && !formik.errors.numero
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.numero}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="pontoReferencia"
                          placeholder="Ponto de referência (opcional)"
                          value={formik.values.pontoReferencia}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.errors.pontoReferencia &&
                              formik.touched.pontoReferencia
                              ? true
                              : false
                          }
                          isValid={
                            formik.touched.pontoReferencia &&
                            !formik.errors.pontoReferencia
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.pontoReferencia}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}

                  <Form.Check
                    custom
                    id="custom-checkbox-buscar"
                    name="delivery_method"
                    type="radio"
                    value="Presencial"
                    label="Vou buscar"
                    onChange={formik.handleChange}
                    disabled={
                      !dataEntrepreneur.delivery_method.includes("Presencial")
                    }
                    checked={formik.values.delivery_method === "Presencial"}
                    isValid={
                      formik.touched.delivery_method &&
                      !formik.errors.delivery_method
                    }
                    isInvalid={
                      formik.errors.delivery_method &&
                        formik.touched.delivery_method
                        ? true
                        : false
                    }
                  />
                  {formik.values.delivery_method === "Presencial" && !user && (
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="nome"
                        placeholder="Seu nome"
                        required
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        isValid={formik.touched.nome && !formik.errors.nome}
                        isInvalid={
                          formik.errors.nome && formik.touched.nome
                            ? true
                            : false
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.nome}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <h6 className="mb-3 text-primary">Forma de pagamento</h6>
                <Form.Group>
                  <Row>
                    {dataEntrepreneur.payment_method.map((f) => {
                      return (
                        <Col xs="auto" key={f.id}>
                          <Form.Check
                            custom
                            id={`custom-payment-${f.id}`}
                            name="payment_method"
                            type="radio"
                            isInvalid={
                              formik.errors.payment_method &&
                                formik.touched.payment_method
                                ? true
                                : false
                            }
                            value={f.name}
                            label={f.name}
                            checked={
                              formik.values.payment_method === String(f.name)
                            }
                            onChange={formik.handleChange}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Form.Group>
                <Button type="submit">Finalizar</Button>
              </Col>
            </Row>
          </Form>

          <Modal show={modalLoading} centered size="sm" onHide={() => { }}>
            <Modal.Body className="text-center">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                size="lg"
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </Container>
  );
}
