import { useEffect, useState } from "react";
import { getStates, getCitiesFrom } from "../services/address";

const useIBGE = () => {
  const [city, setCity] = useState("Acrelândia");
  const [state, setState] = useState({nome: "Acre"});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    getStates().then(setStates);
    return () => {
      setCity("");
      setState("");
      setState([]);
      setCities([]);
    };
  }, []);

  useEffect(() => {
    if (states.length > 0) {
      getCitiesFrom(state).then(setCities);
    }
  }, [state, states]);

  useEffect(() => {
    if (cities.length > 0) {
      setCity(cities[0].nome);
    }
  }, [cities]);

  const changeState = (nome) => {
    setState(states.find((s) => s.nome === nome));
  };

  return [state, changeState, states, city, setCity, cities ];
};

export default useIBGE;
