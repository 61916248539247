import { Container, Button, Jumbotron, ListGroup, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import brand from "../../assets/img/logo-vertical-colored.svg";

import products from "./img/Products-bro.svg";
import baker from "./img/Baker-bro.svg";
import credit from "./img/Credit-bro.svg";
import barcode from "./img/Barcode-bro.svg";
import marketing from "./img/Marketing-bro.svg";
import paid from "./img/Paid-bro.svg";
import analysis from "./img/Analysis-bro.svg";
import people from "./img/People-bro.svg";
import fruitshop from "./img/FruitShop-bro.svg";

import "./styles.scss";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet title="Mercadim: Home" />
      <Jumbotron className="text-light rounded-0 banner d-flex justify-content-between align-items-center">
        <Container fluid className="m-2">
          <Row className="my-4 align-items-center">
            <Col xl={5} lg={6} md={7}>
              <h1 className="display-4">Seja bem-vindo à melhor feira</h1>
            </Col>

            <div className="w-100" />
            <Col xl={4} lg={5} md={6}>
              <p className="lead">
                Compre produtos que vem direto da sua terra! Não só compre, mas
                seja também um dos vendedores.
              </p>
            </Col>
            <div className="w-100" />
            <Col xs="auto justify-xs-center">
            <Link to="/" style={{ pointerEvents: "auto" }}>
              <Button className="lead my-4 ">
                FEIRINHA ONLINE
              </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container className="mb-5">
        <Row>
          <Col xs={12} className="text-center mt-5">
            <h2 className="mb-3">O que é o Mercadim?</h2>
            <p className="lead">
              O Mercadim é uma plataforma de marketplace voltada para o comércio local. Conectamos potenciais consumidores a empreendedores em cada localidade, fazemos isso para incentivar o pequeno comerciante e ajudar no crescimento da economia regional.
            </p>
            <Row className="my-3 align-items-center row-cols-1 row-cols-md-3" noGutters={true}>
              <Col className="px-4">
                <Image src={products} />
              </Col>
              <Col className="order-md-1">
                <p>Encontre o produto que você procura.</p>
              </Col>
              <Col className="px-4">
                <Image src={baker} />
              </Col>
              <Col className="order-md-2">
                <p>Ajude o comerciante local.</p>
              </Col>
              <Col className="px-4">
                <Image src={credit} />
              </Col>
              <Col className="order-md-3">
                <p>Faça tudo isso de modo simples.</p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="text-center mt-5">
            <h2 className="mb-3">Como funciona para o empreendedor?</h2>
            <Row className="my-3 align-items-center row-cols-2 row-cols-md-4" noGutters={true}>
              <Col className="px-4">
                <Image src={barcode} />
              </Col>
              <Col className="px-4">
                <Image src={marketing} />
              </Col>
              <Col className="order-md-1 py-2 d-flex align-items-center justify-content-center">
                <p>Realize seu cadastro gratuitamente.</p>
              </Col>
              <Col className="order-md-2 py-2 d-flex align-items-center justify-content-center">
                <p>Divulgue seus produtos na vitrine virtual.</p>
              </Col>
              <Col className="px-4">
                <Image src={paid} />
              </Col>
              <Col className="px-4">
                <Image src={analysis} />
              </Col>
              <Col className="order-md-3 py-2 d-flex align-items-center justify-content-center">
                <p>Realize vendas e faça conexões.</p>
              </Col>
              <Col className="order-md-4 py-2 d-flex align-items-center justify-content-center">
                <p>Acompanhe os resultados.</p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="text-center mt-5">
            <h2 className="mb-3">Benefícios para nosso Público-Alvo</h2>
            <Row className="my-3 align-items-start row-cols-md-2 row-cols-1">
              <Col className="px-4 py-4">
                <Image style={{ width: "80%" }} src={people} />
              </Col>
              <Col className="order-md-1">
                <ListGroup>
                  <ListGroup.Item>
                    <h4>Sociedade</h4>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Conecta consumidores e empreendedores locais.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Contribui para o crescimento da economia regional.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Valoriza o comércio local.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Não precisa de registro para realizar compras.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Uso de cartão fidelidade virtual.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Acesso a promoções e vale presentes.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Produtos com preços justos.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Produtos sustentáveis.
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col className="px-4">
                <Image style={{ width: "80%" }} src={fruitshop} />
              </Col>
              <Col className="order-md-2">
                <ListGroup>
                  <ListGroup.Item>
                    <h4>Produtores e Empreendedores locais</h4>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Vitrine tecnológica para divulgação de produtos e serviços.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Realizar compras de fornecedores e produtores locais.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Realização de compras e vendas online por atacado.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Gestão de compras e vendas no WhatsApp.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Criação de estratégias de marketing digital.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Acesso a cartão fidelidade virtual.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Acesso a promoções e vale presentes.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Acesso a produtos com preços justos.
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="mt-5">
            <Row className="align-items-center row-cols-1 row-cols-md-2">
              <Col className="d-flex align-items-center justify-content-center">
                <Image style={{ width: '100%' }} src={brand} />
              </Col>
              <Col className="mt-5 mt-md-0 text-justify">
                <h2 className="mb-3">Sobre o Mercadim</h2>
                <p>O <b>Mercadim</b> nasceu em 2021, com o propósito de conectar consumidores e empreendedores locais de cada município brasileiro. Somos uma plataforma que conecta consumidores, empreendedores locais, produtores da agricultura familiar e profissionais de serviços gerais. Atualmente atendemos todos os estados brasileiros com registro gratuito de consumidores e empreendedores.</p>
                <p>Esta plataforma é fruto dos programas de desenvolvimento tecnológico IF MAIS EMPREENDEDOR da Secretaria de Educação Profissional e Tecnológica (Setec) e ACREDITAR do Instituto Federal do Ceará (IFCE).</p>
                <p>A principal <b>missão</b> do <b>Mercadim</b> é conectar consumidores e empreendedores brasileiros de forma regionalizada, impulsionando a economia local.</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
