import { useFormik } from "formik";
import { useState } from "react";
import { SignupAdminSchema } from "../../utils/validation_schemas";
import { createSignupAdminFormData } from "../../utils/payloads";
import { Col, Form, Row, Button } from "react-bootstrap";

import InputMask from "react-input-mask";
import { availableCpf } from "../../services/api";
import Adresss from '../Adresss';

export default function SignupAdminForm({ onSubmit }) {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      telephone: "",
      neighborhood: "",
      cpf: "",
      street: "",
      complement: "",
      number: "",
      zip_code: "",
      city: "",
      state: "",
      email: "",
    },
    validationSchema: SignupAdminSchema,
    onSubmit: async (values) => {
      availableCpf(values.cpf.replace(/\D/g, "")).then(
        () => {
          setLoading(true);
          const admin = { ...values };
          onSubmit(createSignupAdminFormData(admin));
          setLoading(false);
        },
        () => {
          formik.setFieldError("cpf", "O cpf já existe ou inválido");
        }
      );
    },
  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="mt-3">
      <h6 className="mb-3 text-primary">Dados do Administrador</h6>
      <Form.Group className="mt-1 mb-4" controlId="formClientData">
        <Row>
          <Col xl={8} md={7} sm={12}>
            <Form.Label>Nome *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={formik.errors.name && formik.touched.name}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
          <Col xl={4} md={5} sm={12}>
            <Form.Label>Sobrenome *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Sobrenome"
              name="surname"
              onChange={formik.handleChange}
              value={formik.values.surname}
              isValid={formik.touched.surname && !formik.errors.surname}
              isInvalid={formik.errors.surname && formik.touched.surname}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.surname}
            </Form.Control.Feedback>
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Form.Label>CPF *</Form.Label>
            <Form.Control
              type="text"
              placeholder="CPF"
              as={InputMask}
              mask="999.999.999-99"
              name="cpf"
              onChange={formik.handleChange}
              value={formik.values.cpf}
              isValid={formik.touched.cpf && !formik.errors.cpf}
              isInvalid={formik.errors.cpf && formik.touched.cpf}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.cpf}
            </Form.Control.Feedback>
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Form.Label>Telefone *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telefone"
              name="telephone"
              as={InputMask}
              mask="(99)99999-9999"
              onChange={formik.handleChange}
              value={formik.values.telephone}
              isValid={formik.touched.telephone && !formik.errors.telephone}
              isInvalid={formik.errors.telephone && formik.touched.telephone}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.telephone}
            </Form.Control.Feedback>
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Form.Label>Email *</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={formik.errors.email && formik.touched.email}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
        <Adresss formik={formik}/>
      <Form.Group/>
      <Button
        className="my-5"
        variant="primary"
        type="submit"
        disabled={loading}
        >
        Criar conta
      </Button>
      <p style={{ color: "red" }}>*Obrigatório</p>
    </Form>
  );
}
