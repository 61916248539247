import { getCepData } from "../services/address";
import debounce from 'lodash.debounce';


async function handleChangeCep(formik, zipCode , setCep) {
  const result = await getCepData(zipCode).catch((err) => err.response.data)
  if (result) {
    setCep(result)
    formik.setFieldValue("city", result.localidade, true);
    formik.setFieldValue("state", result.name, true);
  } else {
    setCep({})
  }
}

export default debounce(handleChangeCep, 400)
