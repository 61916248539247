import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet title="Política de Privacidade" />
      <Container className="h-100 py-5 px-5">
        <h3 className="h3 text-primary text-center font-weight-bold">
          Política de Privacidade
        </h3>
        <p className="pt-4 text-justify">
          {" "}
          O <b>Mercadim</b> é um serviço de <i>e-commerce</i>, que visa{" "}
          <b>CONECTAR</b> a sociedade juntamente com os pequenos, médios e
          grandes empreendedores, visando fortalecer o desenvolvimento econômico
          e estimular a compra no comércio local dos municípios brasileiros (ou
          seja, <i>Compre da Nossa Gente</i>).{" "}
        </p>
        <p className="pt-2 text-justify">
          {" "}
          Ao realizar o cadastro e utilizar o{" "}
          <u>
            <i>Mercadim</i>
          </u>{" "}
          para fazer seus pedidos, você automaticamente estará concordando com a
          nossa política de privacidade. Para realizar pedidos no Mercadim, você
          não precisa de cadastro, porém coletamos seus dados para ofertar
          melhores serviços, tais como: cartão fidelidade, recomendações de
          produtos e serviços, cupons e participação em promoções.{" "}
        </p>
        <p className="pt-2 text-justify">
          {" "}
          A privacidade dos seus dados é muito importante para o{" "}
          <u>
            <i>Mercadim</i>
          </u>
          . Seus dados pessoais ("Dados") são bens valiosos e devem ser
          preservados. Para isso, você precisa saber exatamente como eles serão
          utilizados. Essa Declaração de Privacidade descreve como obtemos,
          armazenamos, utilizamos, compartilhamos e preservamos as suas
          informações.{" "}
        </p>
        <ol className="mx-3 pt-2">
          <li className="h5 text-primary py-1">
            {" "}
            A quem esta declaração se aplica?{" "}
          </li>
          <p className="py-2 text-justify">
            {" "}
            É aplicável àqueles que acessem ou se cadastrarem na Plataforma
            Mercadim para realizar compras e vendas.{" "}
          </p>
          <li className="h5 text-primary py-1">
            {" "}
            O que é o tratamento dos dados pessoais?{" "}
          </li>
          <p className="py-2 text-justify">
            {" "}
            O tratamento de dados pessoais significa qualquer operação realizada
            com esses dados, tais como: coleta, utilização, acesso,
            processamento, armazenamento, eliminação, comunicação e
            transferência.{" "}
          </p>
          <li className="h5 text-primary py-1">
            {" "}
            Quem controla o tratamento?{" "}
          </li>
          <p className="py-2 text-justify">
            {" "}
            Quando você acessa nossa Plataforma, o Mercadim realiza o papel de
            controla os seus dados pessoais com base na legislação aplicável{" "}
            <a
              href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
              target="blank"
            >
              (Lei Geral de Proteção de Dados Pessoais, Lei nº 13.709/2018)
            </a>
            .{" "}
          </p>
          <li className="h5 text-primary py-1"> Quais dados são coletados? </li>
          <ul className="py-2 mx-3">
            <li className="pt-1 text-justify"> Nome completo </li>
            <li className="pt-1 text-justify"> Data de Nascimento </li>
            <li className="pt-1 text-justify"> CPF ou CNPJ </li>
            <li className="pt-1 text-justify"> Telefone </li>
            <li className="pt-1 text-justify"> E-mail </li>
            <li className="pt-1 pb-3 text-justify"> Endereço completo </li>
          </ul>
          <li className="h5 text-primary py-1">
            {" "}
            Por que coletamos os seus dados?{" "}
          </li>
          <p className="py-2 text-justify">
            {" "}
            Coletamos os dados para melhorar os nossos serviços e ofertas
            promoções aos usuários registrados.{" "}
          </p>
          <ul className="mx-3">
            <li className="py-1"> Dados Pessoais </li>
            <p className="py-2 text-justify">
              {" "}
              Os dados pessoais são utilizados exclusivamente para identificar o
              usuário e o seu pedido. A loja não tem acesso ao CPF ou CNPJ do
              cliente.{" "}
            </p>
            <li className="py-1"> Dados do endereço </li>
            <p className="py-2 text-justify">
              {" "}
              Os dados do endereço são utilizados para enviar a loja o endereço
              de entrega do produto.{" "}
            </p>
            <li className="py-1"> Dados do contato </li>
            <p className="py-2 text-justify">
              {" "}
              Os dados de contato são enviados a loja para conexão entre o
              empreendedor e o cliente, dessa forma negociar forma de pagamento
              e entrega.{" "}
            </p>
          </ul>
          <li className="h5 text-primary py-1">
            {" "}
            Por que protegemos os seus dados?{" "}
          </li>
          <p className="pt-2 text-justify">
            {" "}
            Adotamos medidas de segurança, técnicas, e administrativas para
            proteger os dados pessoais de acessos não autorizados e de situações
            acidentais ou ilícitas de destruição, perda, alteração, comunicação
            ou qualquer forma de tratamento inadequado ou ilícito.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Utilizamos os princípios estipulados por lei, respeitando a sua
            privacidade e protegendo seus dados em nossos processos internos
            como um todo.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Só́ tratamos os seus dados mediante alto grau de segurança,
            implementando técnicas de criptografia, monitoramento e testes de
            segurança periódicos.{" "}
          </p>
          <li className="h5 text-primary py-1">
            {" "}
            Como os dados são armazenados?{" "}
          </li>
          <p className="pt-2 text-justify">
            {" "}
            Os dados coletados são armazenados em serviços de nuvem confiável,
            localizados e mantidos pelo Instituto Federal do Ceará (IFCE), que
            mantém toda infraestrutura lógica de dados e serviços.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Se você̂ solicitar a exclusão de sua conta, os seus dados pessoais
            fornecidos ao Mercadim durante a sua utilização dos nossos serviços
            serão excluídos, salvo para cumprir finalidades permitidas pela
            legislação de proteção de dados.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O usuário poderá excluir conta a qualquer momento.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O uso de cookies serve para observar hábitos de visitas, permitindo
            melhorar as preferências do usuário.{" "}
          </p>
          <li className="h5 text-primary py-1">
            {" "}
            Como os dados são compartilhados?{" "}
          </li>
          <p className="pt-2 text-justify">
            {" "}
            Os dados serão protegidos conforme lei. Não compartilhamos dados
            pessoais com parceiros, para isso precisamos alterar nossa política
            de privacidade. A ideia é gerar conexões fornecendo informações
            sobre pedidos, dados pessoais para negociação do pedido entre loja e
            cliente.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Caso necessário, o Mercadim poderá́ compartilhar suas informações com
            autoridades policiais ou judiciais e autoridades públicas
            competentes, visando responder as demandas judiciais entre lojas e
            clientes.{" "}
          </p>
          <li className="h5 text-primary py-1">
            {" "}
            O Mercadim transfere dados para outros países?{" "}
          </li>
          <p className="py-2 text-justify">
            {" "}
            Não. Atualmente não há transferência de dados para outros países.{" "}
          </p>
          <li className="h5 text-primary py-1"> Quais são seus direitos? </li>
          <p className="py-2 text-justify">
            {" "}
            Exclui, atualizar e confirmar dados. Ter dados não essenciais
            anonimizados.{" "}
          </p>
          <li className="h5 text-primary py-1"> Disposições finais </li>
          <p className="pt-2 text-justify">
            {" "}
            Caso seja identificado uma série de trotes por parte de usuários na
            realização de pedidos, o usuário será automaticamente excluído da
            plataforma.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Caso o usuário empreendedor cometa qualquer ato contrário ao direito
            jurídico ou contra o direito do consumidor, a loja terá seu perfil
            bloqueado e conta excluída.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim não irá cancelar pedidos, pois toda operação de
            negociação e pagamento ocorrerá via WhatsApp. Portanto, os
            vendedores e compradores devem ficar atentos aos pedidos ou usuários
            suspeitos.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim não garante ou assegura o cumprimento de qualquer
            promoção, ofertas ou anúncios realizados pelos empreendedores na
            plataforma.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim não é responsável por problemas ou danos aos clientes,
            seja devido à falta de pagamento ou na falta de entrega de produto.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim atua como uma plataforma de <b>CONEXÃO</b>, visando
            estabelecer contato entre clientes compradores e empreendedores,
            consequentemente, facilitando a busca por produtos.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            É obrigação do usuário fornecer informações cadastrais totalmente
            verídicas, responsabilizando-se integralmente de forma jurídica por
            toda informação cadastrada.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Disponibilizar por meio deste espaço virtual a permissão de efetuar
            pedidos de compra de diversos gêneros, comercializados pelos
            negócios regionais cadastrados.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim não realizar cobrança de pagamento de forma virtual e nem
            opera com taxas. Os usuários poderão utilizar o serviço
            gratuitamente para compras e vendas. Qualquer cobrança externa deve
            ser desconsiderada.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O pagamento entre cliente comprador e empreendedor ocorrerá fora da
            plataforma Mercadim, isto é, negociação no WhatsApp e pagamento em
            comum acordo entre os envolvidos.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim proporciona apenas a vitrine tecnológica de produtos,
            visando conexão entre os usuários com negócios regionais
            cadastrados.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim tem obrigação de manter apenas os serviços funcionando
            para cadastro de usuários, publicação de produtos e ofertas de
            promoções. Não nos responsabilizamos por problemas de pagamento,
            produtos não entregues ou defeituosos e negociação entre cliente e
            empreendedor.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Caso discorde da nossa política de privacidade, exclua cadastro e
            não realize compras.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim irá excluir sem aviso prévio os usuários consumidores ou
            empreendedores que publicarem imagens ou textos desagradáveis. A
            plataforma não permitirá conteúdo que foge nos interesses reais do
            marketplace..{" "}
          </p>

          <li className="h5 text-primary py-1">
            {" "}
            Como notificamos as mudanças feitas nesta declaração?{" "}
          </li>
          <p className="pt-2 text-justify">
            {" "}
            O Mercadim poderá atualizar sua Política de Privacidade
            periodicamente, sendo que a versão em vigor será sempre a mais
            recente.{" "}
          </p>
          <p className="pt-2 text-justify">
            {" "}
            Os usuários serão informador por e-mail, telefone ou pop-up na
            plataforma.{" "}
          </p>
        </ol>
        <p className="text-right"> Data de publicação: 05/08/2023 </p>
      </Container>
      <Footer />
    </>
  );
}
