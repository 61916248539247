import handleChangeCep from "../../utils/validate_cep";
import {Col, Form, Row} from "react-bootstrap";

import InputMask from "react-input-mask";
import getState, { statesList } from "../../utils/uf";
import { useState, useEffect } from "react";
import { getCitiesFrom } from "../../services/address";


export default function Adresss({formik, tab}) {

  const [cep, setCep] = useState({});
  const [cities, setCities] = useState([]);

  useEffect(() => {
    async function getCities() {
      const uf = getState(formik.values.state);
      const cities =  await getCitiesFrom(uf);
      setCities(cities)
    }

    if(formik.values.state) {
      getCities()
    }

  },[formik.values.state])

    return (
        <>
        <h6 className="mb-3 text-primary">
            {tab && 
              <span className="text-secondary bg-primary p-1 rounded mr-1">
                {tab}
              </span>
            }
            Endereço
        </h6>
        <Form.Group>
            <Row className="align-items-center">
              <Col sm={12} md={4} xl={4}>
                <Form.Label>CEP *</Form.Label>
                <Form.Control
                  id="zip_code"
                  as={InputMask}
                  mask="99999-999"
                  className="my-1"
                  type="text"
                  name="zip_code"
                  placeholder="Seu CEP"
                  onChange={(event) => {
                    formik.handleChange(event);
                    handleChangeCep(formik, event.target.value, setCep)
                  }}
                  value={formik.values.zip_code}
                  isInvalid={formik.errors.zip_code}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.zip_code}
                </Form.Control.Feedback>
              </Col>
              <Col sm={12} md={4} xl={4}>
              <Form.Label>Estado *</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="state"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  autoComplete="off"
                  disabled={cep.name}
                  custom
                  isInvalid={ formik.errors.state && formik.touched.state }
                  defaultValue=""
                >
                  <option disabled value=""> Selecione </option>
                  {statesList.map((state) => (
                    <option key={state.id} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.state}
                </Form.Control.Feedback>
                </Col>
              <Col sm={12} md={4} xl={4}>
                <Form.Label>Cidade *</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  autoComplete="off"
                  disabled={cep.localidade}
                  custom
                  isInvalid={ formik.errors.city && formik.touched.city }
                  defaultValue=""
                >
                  <option disabled  value=""> Selecione </option>
                  {cities.map((c) => (
                    <option key={c.id} value={c.nome}>
                      {c.nome}
                    </option>
                  ))}
                </Form.Control>
                 <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.city}
                </Form.Control.Feedback>
              </Col>

              <Col sm={5} md={3} xl={3}>
                <Form.Label>Bairro *</Form.Label>
                <Form.Control
                  className="my-1"
                  type="text"
                  name="neighborhood"
                  placeholder="Seu bairro"
                  value={formik.values.neighborhood}
                  onChange={formik.handleChange}
                  isValid={
                    formik.touched.neighborhood && !formik.errors.neighborhood
                  }
                  isInvalid={
                    formik.errors.neighborhood && formik.touched.neighborhood
                      ? true
                      : false
                  }
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.neighborhood}
                </Form.Control.Feedback>
              </Col>
              <Col sm={7} md={4} xl={4}>
                <Form.Label>Rua *</Form.Label>
                <Form.Control
                  className="my-1"
                  type="text"
                  name="street"
                  placeholder="Sua rua"
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  isValid={formik.touched.street && !formik.errors.street}
                  isInvalid={
                    formik.errors.street && formik.touched.street ? true : false
                  }
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.street}
                </Form.Control.Feedback>
              </Col>
              <Col sm={8} md={3} xl={3}>
                <Form.Label>Complemento *</Form.Label>
                <Form.Control
                  className="my-1"
                  type="text"
                  name="complement"
                  placeholder="Complemento do endereço"
                  value={formik.values.complement}
                  onChange={formik.handleChange}
                  isValid={
                    formik.touched.complement && !formik.errors.complement
                  }
                  isInvalid={
                    formik.errors.complement && formik.touched.complement
                      ? true
                      : false
                  }
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.complement}
                </Form.Control.Feedback>
              </Col>
              <Col sm={4} md={2} xl={2}>
                <Form.Label>Nº *</Form.Label>
                <Form.Control
                  className="my-1"
                  type="text"
                  name="number"
                  placeholder="Nº"
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  isValid={formik.touched.number && !formik.errors.number}
                  isInvalid={
                    formik.errors.number && formik.touched.number ? true : false
                  }
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {formik.errors.number}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
        </>
    )
}

