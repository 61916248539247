import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import SidebarNavigation from "../../components/SidebarNavigation";
import useAuth from "../../contexts/auth";
import AccountAdmin from "./Admin";
import AccountClient from "./Client";
import AccountEntrepreneur from "./Entrepreneur";

export default function Account() {
  const { user } = useAuth();

  return (
    <>
      <Helmet title="Mercadim: Minha Conta" />
      {!user && <Redirect to="/entrar" />}
      <Container fluid>
        <div>
          <SidebarNavigation isAdmin={user && user.type === "admin"}/>
          <div className="content-panel">
            <h4 className="text-primary pt-3">Minha Conta</h4>
            {user && user.type === "client" && <AccountClient />}
            {user && user.type === "entrepreneur" && <AccountEntrepreneur />}
            {user && user.type === "admin" && <AccountAdmin />}
          </div>
        </div>
      </Container>
    </>
  );
}
