import "./assets/styles/global.scss";
import Routes from "./Routes";
import AuthProvider from "./providers/AuthProvider";
import CartProvider from "./providers/CartProvider";
import LocationProvider from "./providers/LocationProvider";
import NotificationProvider from "./providers/NotificationProvider";
import InfoModalProvider from "./providers/InfoModalProvider";
import { useEffect } from "react";

// Inicializar o Google Analytics 4

import ReactGA from 'react-ga4';

ReactGA.initialize('G-CK9RG9DXFY');

export default function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Mercadim" });
  }, []);

  return (
    <NotificationProvider>
      <InfoModalProvider>
        <AuthProvider>
          <CartProvider>
            <LocationProvider>
              <Routes />
            </LocationProvider>
          </CartProvider>
        </AuthProvider>
      </InfoModalProvider>
    </NotificationProvider>
  );
}
