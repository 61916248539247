import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/NavBar";
import Home from "./containers/Home";
import Marketplace from "./containers/Marketplace";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import SignupClient from "./containers/Signup/Client";
import SignupEntrepreneur from "./containers/Signup/Entrepreneur";
import Product from "./containers/Marketplace/Product";
import Terms from "./containers/Terms";
import NotFound from "./containers/NotFound";
import Store from "./containers/Marketplace/Store";
import FloatingCart from "./components/FloatingCart";
import NewPassword from "./containers/Password/NewPassword";
import ForgotPassword from "./containers/Password/ForgotPassword";
import Purchases from "./containers/Purchases";
import Account from "./containers/Account";
import Entrepreneur from "./containers/Entrepreneur";
import EntDashboard from "./containers/Entrepreneur/Dashboard";
import EntOrders from "./containers/Entrepreneur/Orders";
import EntProducts from "./containers/Entrepreneur/Products";
import EntGiftvoucher from "./containers/Entrepreneur/Giftvoucher";
import Admin from "./containers/Admin";
import AdminDashboard from "./containers/Admin/Dashboard";
import AdminUsers from "./containers/Admin/Users";
import AdminPayments from "./containers/Admin/Payments";
import AdminLocations from "./containers/Admin/Locations";
import AdminCategories from "./containers/Admin/Categories";
import Contact from "./containers/Contact";
import ConfirmTerms from "./components/ConfirmTerms";
import Checkout from "./containers/Checkout";
import EntConfPayment from "./containers/Entrepreneur/ConfirmPayment";
import EntLoyaltyCards from "./containers/Entrepreneur/LoyaltyCards";
import LoyaltyCards from "./containers/LoyaltyCards";
import Giftvoucher from "./containers/Giftvoucher";
import Voucher from "./containers/Marketplace/Voucher";

export default function Routes() {
  return (
    <BrowserRouter>
      <Navbar />
      {/* TODO: Page Tracking GA */}
      
      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/" component={Marketplace} />
        <Route path="/sobre" component={Home} />
        <Route path="/entrar" component={Login} />
        <Route path="/cadastro/cliente" component={SignupClient} />
        <Route path="/cadastro/empreendedor" component={SignupEntrepreneur} />
        <Route path="/cadastro" component={Signup} />
        <Route path="/recuperar" component={ForgotPassword} />
        <Route path="/redefinir/:token" component={NewPassword} />
        <Route path="/conta" component={Account} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/produto/:id" component={Product} />
        <Route path="/giftvoucher/:id" component={Voucher} />
        <Route path="/loja/:id" component={Store} />
        <Route path="/categoria/:idCategory" component={Marketplace} />
        {/* <Route path="/marketplace/" component={Marketplace} /> */}
        <Route path="/giftvoucher/" component={Giftvoucher} />
        <Route path="/compras" component={Purchases} />
        <Route path="/fidelidade" component={LoyaltyCards} />
        <Route path="/termos" component={Terms} />
        <Route path="/contato" component={Contact} />
        <Route path="/empreendedor/fidelidade" component={EntLoyaltyCards} />
        <Route path="/empreendedor/dashboard" component={EntDashboard} />
        <Route path="/empreendedor/pedidos" component={EntOrders} />
        <Route path="/empreendedor/produtos" component={EntProducts} />
        <Route path="/empreendedor/giftvoucher" component={EntGiftvoucher} />
        <Route path="/empreendedor/pedido/:id" component={EntConfPayment} />
        <Route path="/empreendedor" component={Entrepreneur} />
        <Route path="/admin/dashboard" component={AdminDashboard} />
        <Route path="/admin/usuarios" component={AdminUsers} />
        <Route path="/admin/pagamentos" component={AdminPayments} />
        <Route path="/admin/localidades" component={AdminLocations} />
        <Route path="/admin/categorias" component={AdminCategories} />
        <Route path="/admin" component={Admin} />
        <Route component={NotFound} />
      </Switch>
      <ConfirmTerms />
      <FloatingCart />
    </BrowserRouter>
  );
}
