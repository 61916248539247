import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.scss";
import { FaInstagram } from "react-icons/fa";
import Brand from "../../assets/img/logo-vertical.svg";

export default function Footer() {

  return (
    <>
      <div className="main-footer bg-primary">
        <Container fluid>
          <Row className="pt-4 justify-content-between">
            <Col xs={6} md="auto" className="d-flex justify-content-center justify-content-md-start">
              <img src={Brand} alt="logo" height="96px" />
            </Col>
            <Col xs={6} md="auto">
              <h5 className="text-white">
                Menu
              </h5>
              <div><Link to="/entrar" className="text-md-left">Entrar</Link></div>
              <div>
                <Link to="/sobre">
                  Feirinha Online
                </Link>
              </div>
              <div><Link to="/contato">Contato</Link></div>
            </Col>
            <Col xs={6} md="auto" className="order-md-2">
              <Row className="justify-content-center justify-content-md-end">
                <Col xs="auto">
                  <a
                    href="https://www.instagram.com/mercadimoficial/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram className="p-2 social-links" size="48" />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col xs={6} md="auto" className="mt-md-2 order-md-1">
              <h5 className="text-white">Suporte</h5>
              <div>
                <a href="mailto:@lapislab@ifce.edu.br">lapislab@ifce.edu.br</a>
              </div>
            </Col>
          </Row>

          <hr className="bg-white" />

          <Row className="justify-content-between">
            <Col xs="auto" className="text-white">
              {`© Mercadim ${new Date().getFullYear()}. Todos os direitos reservados.`}
            </Col>
            <Col xs="auto">
              <Link to="/termos">
                Termos e condições
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
