import { Link } from "react-router-dom";
import "./styles.scss";
import { HiChartSquareBar } from "react-icons/hi";
import {
  FaStore,
  FaShoppingBag,
  FaLightbulb,
  FaGratipay,
  FaMapMarked,
} from "react-icons/fa";
import { FiGrid } from "react-icons/fi";

export default function SidebarPanelNavigation({ isAdmin }) {
  const renderNavigation = () => {
    return (
      <nav className="text-secondary">
        {isAdmin && (
          <ul>
            <li>
              <HiChartSquareBar size={20} />
              <Link className="ml-2 text-secondary" to="/admin/dashboard">
                Dashboard
              </Link>
            </li>
            <li className="my-2">
              <FaStore size={20} />
              <Link className="ml-2 text-secondary" to="/admin/usuarios">
                Usuários
              </Link>
            </li>
            <li>
              <FaShoppingBag size={20} />
              <Link className="ml-2 text-secondary" to="/admin/pagamentos">
                Pagamentos
              </Link>
            </li>
            <li className="my-2">
              <FaMapMarked size={20} />
              <Link className="ml-2 text-secondary" to="/admin/localidades">
                Localidades
              </Link>
            </li>
            <li>
              <FiGrid size={20} />
              <Link className="ml-2 text-secondary" to="/admin/categorias">
                Categorias
              </Link>
            </li>
          </ul>
        )}
        {!isAdmin && (
          <ul>
            <li className="my-2">
              <HiChartSquareBar size={22} />
              <Link
                className="ml-2 text-secondary"
                to="/empreendedor/dashboard"
              >
                Dashboard
              </Link>
            </li>
            <li className="my-2">
              <FaShoppingBag size={20} />
              <Link className="ml-2 text-secondary" to="/empreendedor/pedidos">
                Pedidos da Loja
              </Link>
            </li>
            <li className="my-2">
              <FaStore size={20} />
              <Link className="ml-2 text-secondary" to="/empreendedor/produtos">
                Meus Produtos
              </Link>
            </li>
            <li className="my-2">
              <FaLightbulb size={20} />
              <Link
                className="ml-2 text-secondary"
                to="/empreendedor/giftvoucher"
              >
                Meus Vale Presentes
              </Link>
            </li>
            <li className="my-2">
              <FaGratipay size={20} />
              <Link
                className="ml-2 text-secondary"
                to="/empreendedor/fidelidade"
              >
                Configurar Cartão Fidelidade
              </Link>
            </li>
          </ul>
        )}
      </nav>
    );
  };

  return (
    <div className="sidebar-panel-nav d-none d-sm-nome d-md-none d-lg-block bg-success text-secondary">
      {isAdmin && <h5 className="mb-4">Painel de Controle</h5>}
      {!isAdmin && <h5 className="mb-4">Painel da Loja</h5>}
      {renderNavigation()}
    </div>
  );
}
