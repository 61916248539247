export const telephoneMask = /^\(\d{2}\)\d{5}-\d{4}$/;
export const cpfMask = /^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/;
export const cnpjMask = /^(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})$/;
const cpfGroups = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
const cnpjGroups = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;

export function cpfCnpj(digits) {
  const isCpfFormatted = cpfMask.test(digits);
  const isCnpjFormatted = cnpjMask.test(digits);
  const unformatted = !(isCpfFormatted || isCnpjFormatted);

  if (unformatted) {
    const isCpf = digits.length === 11;
    const mask = isCpf ? cpfGroups : cnpjGroups;

    const replaceText = isCpf ? "$1.$2.$3-$4" : "$1.$2.$3/$4-$5";
    return unformat(digits).replace(mask, replaceText);
  }

  return digits;
}

export function unformat(value) {
  return value.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "");
}

export function defaultDateToISO(date) {
  return date.split("/").join("-");
}
export function isoDateToDefault(date) {
  return date.split("-").reverse().join("/");
}

export function splitFullname(fullname) {
  const splittedFullname = fullname.split(" ");

  return [splittedFullname[0], splittedFullname.splice(1).join(" ")];
}

export function digitsOnly(value) {
  return /^\d+$/.test(value);
}

export function cpfOnly(value) {
  return cpfMask.test(value);
}

export function clearObject(obj, ...keys) {
  keys.forEach((key) => {
    delete obj[key];
  });
}

export function getPageFromUrl(url) {
  if (!url) {
    return null;
  }

  return url.split("?")[1].split("=")[1];
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
}
