import { Form as FormikForm, Field, Formik } from "formik";
import { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, Redirect, useHistory } from "react-router-dom";
import useAuth from "../../contexts/auth";
import { cpfCnpj } from "../../utils/formatters";
import { LoginSchema } from "../../utils/validation_schemas.js";
import ErrorAlert from "./../../components/ErrorAlert";
import Footer from "./../../components/Footer";
import qs from "qs";
import { FaArrowAltCircleRight } from "react-icons/fa";

export default function Login({ match, location }) {
  const { user, login } = useAuth();
  const [status, setStatus] = useState({ status: "start" });
  const history = useHistory();

  const next = qs.parse(location.search, { ignoreQueryPrefix: true }).next;
  const ent = qs.parse(location.search, { ignoreQueryPrefix: true }).ent;
  // const type = qs.parse(location.search, { ignoreQueryPrefix: true }).type;

  const clearStatus = () => setStatus({ status: "start" });

  useEffect(() => {
    return () => {
      setStatus({});
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderLoading = () => {
    if (status.status !== "loading") {
      return null;
    }

    return (
      <div className="d-flex justify-content-center">
        <Spinner variant="primary" animation="border" size="sm" />
      </div>
    );
  };

  if (user) {
    if (next) {
      return <Redirect to={`/checkout?ent=${ent}`} />;
    }
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet title="Mercadim: Entrar" />
      <Container fluid="md" className="py-4">
        <Formik
          enableReinitialize
          validationSchema={LoginSchema}
          initialValues={{
            cpf_cnpj: "",
            password: "",
           
            show_password: false,
          }}
          onSubmit={async (values) => {
            setStatus({ status: "loading" });
            
            const status = await login(values);
            setStatus(
              status.signed
                ? { status: "success" }
                : { status: "error", errors: status.errors }
            );
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form noValidate as={FormikForm}>
              <Row className="justify-content-center">
                <Col sm={8} lg={6} className="align-self-center">
                  <h3 className="text-center text-primary pt-4 mb-2">Entrar</h3>
                  <p className="text-muted text-center mb-5">
                    Não possui conta? <Link to="/cadastro">Cadastre-se</Link>
                  </p>
                  {renderLoading()}
                  {status.status === "error" && (
                    <ErrorAlert
                      msg={Object.values(status.errors)[0]}
                      onClose={clearStatus}
                    />
                  )}
               
                  <Form.Group className="mb-4" controlId="formCpfCnpj">
                    <Form.Label>CPF/CNPJ *</Form.Label>
                    <Form.Control
                      as={Field}
                      title="Digite o CPF ou CNPJ"
                      type="text"
                      name="cpf_cnpj"
                      size="lg"
                      pattern="\d+"
                      placeholder="CPF ou CNPJ sem pontuação"
                      required
                      value={values.cpf_cnpj}
                      onChange={(e) =>
                        setFieldValue("cpf_cnpj", cpfCnpj(e.target.value))
                      }
                      isValid={touched.cpf_cnpj && !errors.cpf_cnpj}
                      isInvalid={
                        errors.cpf_cnpj && touched.cpf_cnpj ? true : false
                      }
                      autoComplete="on"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.cpf_cnpj}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formSenha">
                    <Form.Label>Senha *</Form.Label>
                    <Form.Control
                      as={Field}
                      label="Senha*"
                      required="Campo necessário"
                      size="lg"
                      name="password"
                      type={values.show_password ? "text" : "password"}
                      isValid={touched.password && !errors.password}
                      isInvalid={
                        errors.password && touched.password ? true : false
                      }
                      placeholder="Sua senha"
                      autoComplete="new-password"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formShowPassword" as={Row}>
                    <Col>
                      <Link to="/recuperar">Esqueceu sua senha?</Link>
                    </Col>
                    <Col xs="auto">
                      <Form.Check
                        as={Field}
                        id="show-password-1"
                        name="show_password"
                        type="switch"
                        label="Mostrar senha"
                        checked={values.show_password}
                        custom
                      />
                    </Col>
                  </Form.Group>
                  <Button
                    className="my-1"
                    variant="primary"
                    type="submit"
                    block
                  >
                    Entrar
                  </Button>
                  {next && (
                    <>
                      <p className="text-muted text-center">ou</p>
                      <Button
                        variant="outline-primary"
                        block
                        onClick={() => history.push(`/checkout?ent=${ent}`)}
                      >
                        Continuar sem login <FaArrowAltCircleRight />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
      <Footer />
    </>
  );
}