import { useState } from "react";
import { useFormik } from "formik";
import { Col, Form, Row, Button } from "react-bootstrap";
import { SignupEntrepreneurSchema } from "../../utils/validation_schemas";
import { createSignupEntrepreneurFormData } from "../../utils/payloads";
import { availableCpfCnpj,availablePhone } from "../../services/api";
import { cpfCnpj } from "../../utils/formatters";

import usePaymentsMethod from "../../hooks/usePaymentsMethod";
import InputMask from "react-input-mask";
import Adresss from "../Adresss";

export default function SignupEntrepreneurForm({ onSubmit }) {
  const [loading, setLoading] = useState(false);
  const paymentsMethod = usePaymentsMethod();
  const formik = useFormik({
    initialValues: {
      type: "entrepreneur",
      name_establishment: "",
      cpf_cnpj: "",
      telephone: "",
      email: "",
      logo_store: undefined,
      delivery_method: "1",
      payment_method: [],
      zip_code: "",
      neighborhood: "",
      street: "",
      complement: "",
      number: "",
      city: "",
      state: "",
    },
    validationSchema: SignupEntrepreneurSchema,
    onSubmit: async (values) => {
      availableCpfCnpj(values.cpf_cnpj.replace(/\D/g, ""))
        .then((res) => {
          setLoading(true);
          const entrepreneur = { ...values };
          onSubmit(createSignupEntrepreneurFormData(entrepreneur)).then(() => {
            setLoading(false);
          });
        })
        .catch((err) => {
          formik.setFieldError("cpf_cnpj", "Esse CPF/CNPJ não está disponível");
        });
        availablePhone(values.telephone)
        .then((res) => {
          setLoading(true);
          const entrepreneur = { ...values };
          onSubmit(createSignupEntrepreneurFormData(entrepreneur)).then(() => {
            setLoading(false);
          });
        })
        .catch((err) => {
          formik.setFieldError("telephone", "Esse Telefone não está disponível");
        });
    },

  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="mt-3">
      <h6 className="mb-3 text-primary">Dados do negócio</h6>
      <Form.Group className="mt-1 mb-4" controlId="formEntData">
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Form.Label>Nome do estabelecimento *</Form.Label>
            <Form.Control
              className="my-1"
              type=""
              name="name_establishment"
              placeholder="Seu estabelecimento"
              onChange={formik.handleChange}
              value={formik.values.name_establishment}
              isValid={
                formik.touched.name_establishment &&
                !formik.errors.name_establishment
              }
              isInvalid={
                formik.errors.name_establishment &&
                formik.touched.name_establishment
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.name_establishment}
            </Form.Control.Feedback>
          </Col>

          <Col xl={6} md={6} sm={12}>
            <Form.Label>CPF/CNPJ *</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="cpf_cnpj"
              placeholder="CPF ou CPNJ sem pontuação"
              onChange={(e) =>
                formik.setFieldValue("cpf_cnpj", cpfCnpj(e.target.value))
              }
              value={formik.values.cpf_cnpj}
              isValid={formik.touched.cpf_cnpj && !formik.errors.cpf_cnpj}
              isInvalid={
                formik.errors.cpf_cnpj && formik.touched.cpf_cnpj ? true : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.cpf_cnpj}
            </Form.Control.Feedback>
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Form.Label>Telefone *</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="telephone"
              placeholder="Telefone"
              as={InputMask}
              mask="(99)99999-9999"
              onChange={formik.handleChange}
              value={formik.values.telephone}
              isValid={formik.touched.telephone && !formik.errors.telephone}
              isInvalid={
                formik.errors.telephone && formik.touched.telephone
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.telephone}
            </Form.Control.Feedback>
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Form.Label>Email *</Form.Label>
            <Form.Control
              className="my-1"
              type="email"
              name="email"
              placeholder="Seu email"
              onChange={formik.handleChange}
              value={formik.values.email}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={
                formik.errors.email && formik.touched.email ? true : false
              }
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Col>
          <Col xl={12} md={12} sm={12} className="mt-1">
            <Form.Label>Logo da loja *</Form.Label>
            <Form.File
              id="exampleFormControlFile1"
              accept="image/png, image/jpeg"
              name="logo_store"
              lang="pt"
              label={
                formik.values.logo_store
                  ? formik.values.logo_store.name
                  : "Escolher arquivo"
              }
              onChange={(e) =>
                formik.setFieldValue("logo_store", e.currentTarget.files[0])
              }
              isInvalid={
                formik.errors.logo_store && formik.touched.logo_store
                  ? true
                  : false
              }
              feedback={formik.errors.logo_store}
              feedbackTooltip
              custom
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <fieldset>
          <Row>
            <Col sm={12} md={4} lx={4}>
              <Form.Check
                custom
                id="custom-checkbox-presencial"
                name="delivery_method"
                type="radio"
                checked={formik.values.delivery_method === "1"}
                value="1"
                label="Presencial"
                onChange={formik.handleChange}
              />
            </Col>
            <Col sm={12} md={4} lx={4}>
              <Form.Check
                custom
                id="custom-checkbox-delivery"
                name="delivery_method"
                type="radio"
                value="2"
                checked={formik.values.delivery_method === "2"}
                label="Delivery"
                onChange={formik.handleChange}
              />
            </Col>
            <Col sm={12} md={4} lx={4}>
              <Form.Check
                custom
                id="custom-checkbox-delivery-presencial"
                name="delivery_method"
                type="radio"
                checked={formik.values.delivery_method === "3"}
                label="Presencial e Delivery"
                value="3"
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        </fieldset>
      </Form.Group>
      <Form.Group>
        <h6 className="mb-3 text-primary">Formas de pagamento</h6>
        <fieldset>
          <h6 className="text-danger">{formik.errors.payment_method}</h6>
          <Row>
            {paymentsMethod.map((f) => {
              return (
                <Col sm={12} md={6} lx={6} key={f.id}>
                  <Form.Check
                    custom
                    id={`custom-checkbox-${f.id}`}
                    name="payment_method"
                    type="checkbox"
                    isInvalid={
                      formik.errors.payment_method &&
                      formik.touched.payment_method
                        ? true
                        : false
                    }
                    value={f.id}
                    label={f.name}
                    required={formik.values.payment_method.length === 0}
                    checked={formik.values.payment_method.includes(
                      String(f.id)
                    )}
                    onChange={formik.handleChange}
                  />
                </Col>
              );
            })}
          </Row>
        </fieldset>
      </Form.Group>
      <Form.Group>
        <Adresss formik={formik} />
        <Button
          className="my-5"
          variant="primary"
          type="submit"
          disabled={loading}
        >
          Criar conta
        </Button>
        <p style={{ color: "red" }}>*Obrigatório</p>
      </Form.Group>
    </Form>
  );
}
