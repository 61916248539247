import { useFormik} from "formik";
import { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import { createSignupClientFormData } from "../../utils/payloads";
import { SignupClientSchema } from "../../utils/validation_schemas";
import { availableCpf } from "../../services/api";
import Adresss from "../Adresss";

export default function SignupClientForm({ onSubmit }) {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      type: "client",
      name: "",
      surname:"",
      birthDate: "",
      cpf: "",
      telephone: "",
      email: "",
      complement: "",
      number: "",
      neighborhood: "",
      zip_code: "",
      street: "",
      state: "",
      city: "",
    },
    validationSchema: SignupClientSchema,

    onSubmit: async (values) => { 
      await availableCpf(values.cpf.replace(/\D/g, "")).then(
        (res) => {
          setLoading(true);
          const client = { ...values };
          onSubmit(createSignupClientFormData(client));
          setLoading(false);
        },
        (err) => {
          formik.setFieldError("cpf", "O cpf já existe ou inválido");
        }
      );
    },
  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="mt-3">
      <h6 className="mb-3 text-primary">Dados do consumidor</h6>
      <Form.Group className="mt-1 mb-4" controlId="formClientData">
        <Row>
          <Col xl={8} md={7} sm={12}>
            <Form.Label>Nome completo *</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="name"
              placeholder="Seu nome completo"
              required="Campo necessário"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={formik.errors.name && formik.touched.name}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
          <Col xl={8} md={7} sm={12}>
            <Form.Label>Sobrenome*</Form.Label>
            <Form.Control
              className="my-1"
              type="text"
              name="surname"
              placeholder="Seu Sobrenome"
              required="Campo necessário"
              onChange={formik.handleChange}
              value={formik.values.surname}
              isValid={formik.touched.surname && !formik.errors.surname}
              isInvalid={formik.errors.surname && formik.touched.surname}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.surname}
            </Form.Control.Feedback>
          </Col>
        
          <Col xl={4} md={5} sm={12}>
            <Form.Label>Data de nascimento *</Form.Label>
            <Form.Control
              as={InputMask}
              mask="99/99/9999"
              className="my-1"
              type="text"
              name="birthDate"
              placeholder="DD/MM/AAAA"
              onChange={formik.handleChange}
              value={formik.values.birthDate}
              isValid={formik.touched.birthDate && !formik.errors.birthDate}
              isInvalid={formik.errors.birthDate && formik.touched.birthDate}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.birthDate}
            </Form.Control.Feedback>
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Form.Label>CPF *</Form.Label>
            <Form.Control
              as={InputMask}
              mask="999.999.999-99"
              className="my-1"
              type="text"
              name="cpf"
              placeholder="Seu CPF"
              onChange={formik.handleChange}
              value={formik.values.cpf}
              isValid={formik.touched.cpf && !formik.errors.cpf}
              isInvalid={formik.errors.cpf && formik.touched.cpf}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.cpf}
            </Form.Control.Feedback>
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Form.Label>Telefone *</Form.Label>
            <Form.Control
              as={InputMask}
              mask="(99)99999-9999"
              className="my-1"
              type="text"
              name="telephone"
              placeholder="Telefone"
              onChange={formik.handleChange}
              value={formik.values.telephone}
              isValid={formik.touched.telephone && !formik.errors.telephone}
              isInvalid={formik.errors.telephone && formik.touched.telephone}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.telephone}
            </Form.Control.Feedback>
          </Col>
          <Col xl={4} md={4} sm={12}>
            <Form.Label>Email *</Form.Label>
            <Form.Control
              className="my-1"
              type="email"
              name="email"
              placeholder="Seu email"
              onChange={formik.handleChange}
              value={formik.values.email}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={formik.errors.email && formik.touched.email}
            />
            <Form.Control.Feedback tooltip type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <Adresss formik={formik} />
        <Button
          className="my-5"
          variant="primary"
          type="submit"
          disabled={loading}
        >
          Criar conta
        </Button>
        <p style={{ color: "red" }}>*Obrigatório</p>
      </Form.Group>
    </Form>
  );
}
