import { useEffect } from "react";
import useAuth from "../contexts/auth";
import { LocationContext } from "../contexts/location";
import useLocalStorage from "../hooks/useLocalStorage";
import { getEntrepreneur, getUser } from "../services/api";

export default function LocationProvider({ children }) {
  const [local, setLocal] = useLocalStorage("@app_mercadim:location", null);
  const { user } = useAuth();

  useEffect(() => {
    console.log('Init LocationProvider')
  }, []);

  useEffect(() => {
    console.log('useEffect LocationProvider');

    if (user) {
      if(user.type === 'client') {
        getUser(user.id).then((data) => {
          const adress = data[0].adress_id;
          const city = adress.city;
          const state = adress.state;
          setLocal({ name: city, uf: state });
        })
      } else if(user.type === 'entrepreneur') {
        getEntrepreneur(user.id).then((data) => {
          const city = data[1].city;
          const state = data[1].state;
          setLocal({ name: city, uf: state });
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <LocationContext.Provider
      value={{ location: local, setLocation: setLocal }}
    >
      {children}
    </LocationContext.Provider>
  );
}
