import { Card, Container, Row, Col,  Image  } from "react-bootstrap";
import useAuth from "../../contexts/auth";
import { Redirect } from "react-router";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";

import client from "./Client/client.svg"
import entrepreneur from "./Entrepreneur/entrepreneur.svg"
import { Link, useHistory } from "react-router-dom";
import "./styles.scss";

export default function Signup() {
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet title="Mercadim: Cadastro" />
      {user && <Redirect to="/" />}
      {!user && (
        <Container className="pt-2 pb-4">
          <Row className="justify-content-center">
            <Col xs={8} className="text-center mt-5">
              <h2 className="mb-3">Cadastre-se no Mercadim.</h2>
              <p className="text-muted text-center mb-5">
                O Mercadim é uma plataforma de marketplace que atende dois tipos de perfil. Escolha o que melhor se encaixa com você. Ou <Link to="/entrar">entre</Link> se já possui uma conta.
              </p>
            </Col>
          </Row>
          <Row className="align-items-stretch">
            <Col className="d-flex">
              <Card className="card-id" onClick={() => history.push('/cadastro/cliente')}>
                <Card.Body>
                  <Card.Title className="text-center">Cliente</Card.Title>
                  <Card.Subtitle className="text-center text-muted">
                    Desejo apenas realizar compras no Mercadim.
                  </Card.Subtitle>
                  <Image style={{ width: "100%" }} src={client} />
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="card-id" onClick={() => history.push('/cadastro/empreendedor')}>
                <Card.Body>
                  <Card.Title className="text-center">Empreendedor</Card.Title>
                  <Card.Subtitle className="text-center text-muted">
                    Além de fazer compras, desejo divulgar minha loja no Mercadim.
                  </Card.Subtitle>
                  <Image style={{ width: "100%" }} src={entrepreneur} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}
