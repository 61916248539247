import RedirectContainer from "../../../components/RedirectContainer";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import SidebarPanelNavigation from "../../../components/SidebarPanelNavigation";
import ClientsTab from "./ClientsTab";
import EntrepreneursTab from "./EntrepreneursTab";
import AdminsTab from "./AdminsTab";

export default function Users() {
  return (
    <>
      <RedirectContainer role="admin">
        <Container fluid>
          <div>
            <SidebarPanelNavigation isAdmin />
            <div className="content-panel">
              <Row className="d-flex justify-content-between py-3">
                <Col lg={12}>
                  <Tabs
                    variant="pills"
                    defaultActiveKey="usuarios"
                    id="uncontrolled-tab-example"
                    className="pb-3"
                  >
                    <Tab eventKey="usuarios" title="Consumidor">
                      <ClientsTab />
                    </Tab>
                    <Tab eventKey="empreendedor" title="Empreendedor">
                      <EntrepreneursTab />
                    </Tab>
                    <Tab eventKey="administrador" title="Administrador">
                      <AdminsTab />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </RedirectContainer>
    </>
  );
}
